import React, { useEffect, useState } from "react";

import { CircularProgress, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import { LOAD_POLICY_LINKS } from "components/policyPrivacyConfirmDialog/constants";
import { LocalizationId } from "components/policyPrivacyConfirmDialog/localization";
import { LocalizedComponentName } from "localization/enums";
import { withLocalization } from "localization/withLocalization";
import { useRequestContext } from "shared/api/RequestProvider";
import { apiPutPrivacyPolicyAndTermsAndConditionsConfirmed } from "shared/api/swagger/spaceStone/privacyPolicyAndTermsAndConditions/apiPutPrivacyPolicyAndTermsAndConditionsConfirmed";
import { DIALOG_PRIMARY_BUTTON_ACTION } from "shared/constants/requestActionConstants";
import { useLocalize } from "shared/hooks/useLocalize";
import { wrapMatchedTextToComponent } from "shared/utils/wrapMatchedTextToComponent.utils";
import Link from "uiKit/Link";
import Dialog from "uiKit/container/dialog/Dialog";

import { useRootStore } from "app/useRootStore";

const PolicyPrivacyConfirmDialog = () => {
    const [showDialog, setShowDialog] = useState(false);
    const { isLoading, executeRequest, actionName } = useRequestContext();
    const localize = useLocalize();

    const paragraphText = localize(LocalizationId.ConfirmPoliciesText);
    const privacyPolicyLabel = localize(LocalizationId.PrivacyPolicyLabel);
    const termsAndConditionsLabel = localize(LocalizationId.TermsAndConditionsLabel);

    const {
        rootStore: {
            isAdmin,
            authStore: { isTermsAndConditionsConfirmed, refreshAccessToken },
        },
    } = useRootStore();

    const shouldShowDialog = !isTermsAndConditionsConfirmed && !isAdmin;

    useEffect(() => {
        setShowDialog(shouldShowDialog);
    }, [shouldShowDialog, setShowDialog]);

    const onConfirm = async () => {
        await executeRequest(apiPutPrivacyPolicyAndTermsAndConditionsConfirmed, DIALOG_PRIMARY_BUTTON_ACTION, []);

        await executeRequest(refreshAccessToken, DIALOG_PRIMARY_BUTTON_ACTION, []);
        setShowDialog(false);
    };

    const paragraph = wrapMatchedTextToComponent(paragraphText, {
        TermsAndConditions: (
            <Link
                key={1}
                isOpenAtNewPage
                link={window.SYSTEM_SETTINGS.footerLinks.terms}
                label={termsAndConditionsLabel}
                typographyVariant="body1"
            />
        ),
        PrivacyPolicy: (
            <Link
                key={2}
                isOpenAtNewPage
                link={window.SYSTEM_SETTINGS.footerLinks.privacy}
                label={privacyPolicyLabel}
                typographyVariant="body1"
            />
        ),
    });

    const content = (isLoading && actionName === LOAD_POLICY_LINKS && <CircularProgress />) || (
        <Typography variant="body1">{paragraph}</Typography>
    );

    return (
        <Dialog
            isOpened={showDialog}
            dialogTitle={localize(LocalizationId.Title)}
            primaryButtonText={localize(LocalizationId.ConfirmPoliciesButtonText)}
            handlePrimaryButton={onConfirm}
            showCloseIcon={false}
        >
            {content}
        </Dialog>
    );
};

export default withLocalization(
    observer(PolicyPrivacyConfirmDialog),
    LocalizedComponentName.PrivacyPolicyAndTermsAndConditionsConfirmDialog
);
