import { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
    children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, unknown> {
    componentDidCatch(error: Error): void {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    render(): ReactNode {
        const { children } = this.props;

        return children;
    }
}

export default ErrorBoundary;
