const AGENT_REGULAR_EXPRESSIONS = {
    IPHONE_AGENT_REGULAR_EXPRESSION: /iPhone/i,
    IPAD_AGENT_REGULAR_EXPRESSION: /iPad/i,
    ANDROID_AGENT_REGULAR_EXPRESSION: /Android/i,
};

export function isIosAgent(): boolean {
    return Boolean(
        window.navigator.userAgent.match(AGENT_REGULAR_EXPRESSIONS.IPHONE_AGENT_REGULAR_EXPRESSION) ||
            window.navigator.userAgent.match(AGENT_REGULAR_EXPRESSIONS.IPAD_AGENT_REGULAR_EXPRESSION)
    );
}

export function isAndroid(): boolean {
    return Boolean(window.navigator.userAgent.match(AGENT_REGULAR_EXPRESSIONS.ANDROID_AGENT_REGULAR_EXPRESSION));
}
