import React from "react";

import { RequestContext, useRequest } from "shared/api/RequestProvider";

export function withLocalRequestContext<Props extends object>(
    WrappedComponent: React.ComponentType<Props>
): React.FC<Props> {
    return (props: Props) => {
        const {
            isLoading,
            setUploadAbortControllers,
            abortUploads,
            executeRequest,
            executeRequests,
            actionName,
            isLoadingAction,
        } = useRequest();
        return (
            <RequestContext.Provider
                value={{
                    isLoading,
                    setUploadAbortControllers,
                    abortUploads,
                    executeRequest,
                    executeRequests,
                    actionName,
                    isLoadingAction,
                }}
            >
                <WrappedComponent {...props} />
            </RequestContext.Provider>
        );
    };
}
