/**
 * Summary: Delete draft release request by Id.
 *
 * Tags: ReleaseRequests
 */
import { AxiosRequestConfig } from "axios";

import { httpDelete } from "shared/api/apiHandler";

export const API_DELETE_RELEASE_REQUEST_URL = (releaseRequestId: number): string =>
    `api/v1/release-requests/${releaseRequestId}`;

export const apiDeleteReleaseRequest = async (
    releaseRequestId: number,
    config?: AxiosRequestConfig
): Promise<ApiDeleteReleaseRequestResponse> => {
    const result = await httpDelete<ApiDeleteReleaseRequestResponse>(
        window.APP_SETTINGS.timeStoneApiBaseUri,
        API_DELETE_RELEASE_REQUEST_URL(releaseRequestId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiDeleteReleaseRequestResponse = void;
