import React, { Fragment } from "react";

import { has } from "lodash";

export const wrapMatchedTextToComponent = (
    str: string,
    replacements: {
        [key: string]: React.ReactNode;
    }
): React.ReactNode[] => {
    const splitRegex = new RegExp(/{(\w*)}/g);
    const parts = str.split(splitRegex);

    return parts.map(part => {
        if (has(replacements, part)) {
            return <Fragment key={part}>{replacements[part]}</Fragment>;
        }

        return <Fragment key={part}>{part}</Fragment>;
    });
};
