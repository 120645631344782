import { scrollStyles } from "shared/styles";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useNotificationsListStyles = makeSharedStyles(theme => ({
    root: {
        outline: "none",
        width: "100%",
        paddingRight: theme.spacing(2),
        ...scrollStyles,
    },
    loader: {
        display: "block",
        width: "fit-content",
        margin: theme.spacing(2, "auto"),
    },
}));
