import { Theme } from "@material-ui/core";

import { Gradients, TxtColor, applicationPalettes } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useMenuItemStyles = makeSharedStyles<Theme>(theme => ({
    item: {
        padding: theme.spacing(0.5, 1, 0.5, 1.5),
        position: "relative",

        "&.MuiListItem-button:hover": {
            backgroundColor: applicationPalettes.primary[2],
        },
    },
    text: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "200px",
    },
    name: {
        ...theme.typography.body5,
    },
    info: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: theme.spacing(),
    },

    switch: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center !important",
        width: "100%",
        height: "100%",
    },
    account: {
        color: TxtColor.Txt2,
    },
    switchButtonContainer: {
        zIndex: theme.zIndex.tooltip,
        position: "absolute",
        background: Gradients.Gr3,

        width: "100%",
        height: "100%",
        paddingRight: theme.spacing(2.5),
    },
}));
