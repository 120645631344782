import { applicationPalettes } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useBadgeStyles = makeSharedStyles({
    root: {
        position: "absolute",
        right: 0,
        borderRadius: "4px",
        padding: "0 8px",
        background: applicationPalettes.primary[2],
        color: applicationPalettes.primary[500],
    },
});
