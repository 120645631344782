import React, { useCallback, useEffect, useState } from "react";

import { Redirect } from "react-router";
import { generatePath } from "react-router-dom";

import { FullscreenLoader } from "components/fullscreenLoader";
import { apiGetTenancyProperty } from "shared/api/swagger/realityStone/tenancies/apiGetTenancyProperty";
import { useRouteIdParam } from "shared/hooks/useRouteIdParam";
import routeNames from "shared/routes/constants/routeNames";

const Tenancy: React.FC = () => {
    const tenancyId = useRouteIdParam();

    const [propertyId, setPropertyId] = useState<number | undefined>();

    const getPropertyId = useCallback(async () => {
        const response = await apiGetTenancyProperty(tenancyId);
        setPropertyId(response.id);
    }, [tenancyId]);

    useEffect(() => {
        getPropertyId();
    }, [getPropertyId]);

    if (!propertyId) {
        return <FullscreenLoader />;
    }

    return <Redirect to={generatePath(routeNames.PROPERTY.TENANCY.ROOT, { id: propertyId, tenancyId })} />;
};

export default Tenancy;
