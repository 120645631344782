export default {
    HEADERS: {
        ERROR_CODE: "x-error-code",
        ERROR_ID: "x-error-id",
        PAYLOAD_ERROR: "x-error-payload",
        AUTHORIZATION: "Authorization",
    },
    BEARER: "Bearer",
    STATUS_CODES: {
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        NOT_FOUND: 404,
        BAD_REQUEST: 400,
        ENTITY_TOO_LARGE: 413,
        CONFLICT: 409,
        INTERNAL_ERROR: 500,
        SERVICE_UNAVAILABLE: 503,
    },
    RETRIES: {
        DEFAULT_COUNT: 5,
        DEFAULT_DELAY: 1000,
    },
};
