/**
 * Summary: Get current user profile accounts.
 *
 * Tags: Profile
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_PROFILE_ACCOUNTS_URL = "api/v1/profile/accounts";

export const apiGetProfileAccounts = async (config?: AxiosRequestConfig): Promise<ApiGetProfileAccountsResponse> => {
    const result = await httpGet<ApiGetProfileAccountsResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_PROFILE_ACCOUNTS_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetProfileAccountsResponse = GetProfileAccountsResponse;

interface LookUpModel {
    id: number;
    text: string;
}

interface FileInfoLightDto {
    fileId: number; // Id of a file. Example: 1
    downloadUrl: string; // SAS Uri to download a file. Example: https://storage.com/image_e1699e0c-017b-4541-a5af-71fd75dcd219.jpg?sas_query_parameters
}

interface UserProfileShortAccountDto {
    accountId: number; // Account id. Example: 23
    accountType: LookUpModel; // Account type.
    accountIndex: number; // Account index. Example: 2
    accountName: string; // Account name. Example: John Doe
    accountLogo?: FileInfoLightDto; // Account photo.
    movedToUserId?: number; // The user ID if the account has been moved to another user. Example: 42
    movedToUserFullName?: string; // The user full name if the account has been moved to another user. Example: John Doe
    accountUserStatus: LookUpModel; // Account user status.
    accountStatus: LookUpModel; // Account status.
    canBeActivated?: boolean; // Flag whether the user account can be activated. Example: true
    canBeDeactivated?: boolean; // Flag whether the user account can be deactivated. Example: true
    isLastCompanyAccountUser?: boolean; // Is last company account user flag.
}

interface GetProfileAccountsResponse {
    accounts?: UserProfileShortAccountDto[];
}
