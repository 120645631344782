export enum ExpiredLinkErrors {
    ChangeEmailRequestIsExpired = "ExpiredCodeValidator",
    ChangeEmailTokenIsInvalid = "ChangeEmailTokenIsInvalid",
}

export enum InvitationErrorCodes {
    InvitationTokenExpired = "InvitationTokenExpired",
    InvitationTokenUsed = "InvitationTokenUsed",
    InvitationTokenRevoked = "InvitationTokenRevoked",
    InvitedUserHasSignedUp = "InvitedUserHasSignedUp",
    InvitationTokenInvalid = "InvitationTokenInvalid",
}

export const PERMISSION_IS_REQUIRED_ERROR_CODE = "PermissionIsRequired";

export const INVITATION_CANT_BE_SENT = "InvitationCantBeSent";
export const INVITED_USER_IS_DEACTIVATED = "InvitedUserIsDeactivated";
