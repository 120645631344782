import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export function useUpdateEffect(fn: EffectCallback, deps: DependencyList): void {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }

        didMountRef.current = true;

        // We don't need to pass fn, otherwise it will work incorrect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
