/**
 * Summary: Get all offices count.
 *
 * Tags: Offices
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_ADMIN_OFFICES_COUNT_URL = "api/v1/admin/offices/count";

export const apiGetAdminOfficesCount = async (
    request?: ApiGetAdminOfficesCountRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetAdminOfficesCountResponse> => {
    const result = await httpGet<ApiGetAdminOfficesCountResponse, ApiGetAdminOfficesCountRequest>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_ADMIN_OFFICES_COUNT_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export interface ApiGetAdminOfficesCountRequest {
    accountId?: number; // Account ID. Example: 14
}

/**
 * Description: Success
 */
export type ApiGetAdminOfficesCountResponse = number;
