import { BankAccountType } from "components/bankDetails/form/interface";

export enum BankDetailsLocalizationId {
    BankLocation = "bank-detail.bank_location",
    BankLocationPlaceholder = "bank-detail.bank_location_placeholder",

    AccountNameLabel = "bank-detail.account_name_label",

    AccountNumberLabel = "bank-detail.account_number_label",
    AccountNumberIBANLabel = "bank-detail.account_number_iban_label",

    SortCodeLabel = "bank-detail.sort_code_label",
    SwiftBicLabel = "bank-detail.swift_bic_label",

    RoutingNumberLabel = "bank-detail.routing_number_label",

    SaveBankAsDefaultForCompanyUser = "bank-detail.save_bank_as_default_for_companies_text",
    SaveBankAsDefault = "bank-detail.save_bank_as_default_text",

    SavedBankAccountOption = "bank-detail.saved_bank_account_option",
    NewBankAccountOption = "bank-detail.new_bank_account_option",

    ActionDeleteBankAccountButton = "bank-detail.action_delete_bank_account_button",
    ActionEditBankAccountButton = "bank-detail.action_edit_bank_account_button",
    ActionSubmitFormButton = "bank-detail.action_submit_form_button",
    ActionCancelFormButton = "bank-detail.action_cancel_form_button",

    ShareBankDetailsToOfficesCheckboxLabel = "bank-detail.share_bank_details_to_offices_checkbox_label",
    OfficesToShareSelectLabel = "bank-detail.offices_to_share_select_label",
    OfficesToShareSelectPlaceholder = "bank-detail.offices_to_share_select_placeholder",
    SelectedOfficesNoteMessage = "bank-detail.selected_offices_note_message",

    DeleteDialogTitle = "bank-detail.delete_dialog_title",
    DeleteDialogPrimaryButton = "bank-detail.delete_dialog_primary_button",
    DeleteDialogAdditionalButton = "bank-detail.delete_dialog_additional_button",
    DeleteDialogBodyMessage = "bank-detail.delete_dialog_body_message",

    NoBankAccountMessage = "bank-detail.no_bank_account_message",

    SuccessAddBankAccountToastTitle = "bank-detail.success_add_bank_account_toast_title",
    SuccessDeleteBankAccountToastTitle = "bank-detail.success_delete_bank_account_toast_title",
    SuccessEditBankAccountToastTitle = "bank-detail.success_edit_bank_account_toast_title",
}

interface BankDetailsByPaymentTypeLocalizationId {
    AccountNameLabel: string;
    AccountNumberLabel: string;
    BankIdentifierCodeLabel: string;
    RoutingNumberLabel: string;
}
export const getBankDetailsByPaymentTypeLocalizationId = (
    bankAccountTypeId?: number
): BankDetailsByPaymentTypeLocalizationId => {
    const ukLocalizationId = {
        AccountNameLabel: BankDetailsLocalizationId.AccountNameLabel,
        AccountNumberLabel: BankDetailsLocalizationId.AccountNumberLabel,
        BankIdentifierCodeLabel: BankDetailsLocalizationId.SortCodeLabel,
        RoutingNumberLabel: BankDetailsLocalizationId.RoutingNumberLabel,
    };

    switch (bankAccountTypeId) {
        case BankAccountType.USA:
        case BankAccountType.Canada:
            return {
                ...ukLocalizationId,
                AccountNumberLabel: BankDetailsLocalizationId.AccountNumberIBANLabel,
                BankIdentifierCodeLabel: BankDetailsLocalizationId.SwiftBicLabel,
                RoutingNumberLabel: BankDetailsLocalizationId.RoutingNumberLabel,
            };

        case BankAccountType.Eurozone:
        case BankAccountType.UnitedArabEmirates:
        case BankAccountType.RestOfTheWorld:
            return {
                ...ukLocalizationId,
                AccountNumberLabel: BankDetailsLocalizationId.AccountNumberIBANLabel,
                BankIdentifierCodeLabel: BankDetailsLocalizationId.SwiftBicLabel,
            };

        case BankAccountType.UK:
        default:
            return ukLocalizationId;
    }
};
