import React from "react";

import { Avatar as MaterialAvatar, Typography } from "@material-ui/core";

import { formatUserNameForAvatar } from "shared/helpers/formatUserName";
import { AvatarProps } from "uiKit/avatar/interface";
import { useAvatarStyles } from "uiKit/avatar/styles";
import { getAvatarDimensionsBySize, getAvatarTypographyBySize } from "uiKit/avatar/utils";

const Avatar = ({
    primaryName,
    secondaryName,
    src,
    variant,
    size = "s",
    noMargin = false,
}: AvatarProps): JSX.Element => {
    const styles = useAvatarStyles({ avatarSize: getAvatarDimensionsBySize(size), noMargin });
    const typographyVariant = getAvatarTypographyBySize(size);

    return (
        <MaterialAvatar className={styles.avatar}>
            {src ? (
                <img src={src} alt="" />
            ) : (
                <Typography variant={variant || typographyVariant} className={styles.avatarText}>
                    {formatUserNameForAvatar(primaryName, secondaryName)}
                </Typography>
            )}
        </MaterialAvatar>
    );
};

export default Avatar;
