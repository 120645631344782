import React from "react";

import { Grid } from "@material-ui/core";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";

import BasketNotificationsButton from "components/basketNotifications/basketNotificationsButton";
import NotificationsButton from "components/notifications/notificationsButton";
import { LocalizedComponentName } from "localization/enums";
import { withLocalization } from "localization/withLocalization";
import IconButton from "uiKit/buttons/IconButton";
import CustomBadge from "uiKit/labels/badge/Badge";

import { useMobileBadgeStyles, useMobileHeaderStyles } from "app/layout/menu/mobileHeader/styles";
import MenuProfile from "app/layout/menu/profile";

interface MobileHeaderProps {
    toggleMenuHandler: () => void;
    isMobileToggled?: boolean;
    hasUnreadNotifications?: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
    toggleMenuHandler,
    isMobileToggled,
    hasUnreadNotifications = false,
}: MobileHeaderProps): JSX.Element => {
    const classes = useMobileHeaderStyles();
    const mobileBadgeStyles = useMobileBadgeStyles();

    return (
        <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <MenuProfile />
            </Grid>
            <Grid item className={classes.notifyIconContainer}>
                <BasketNotificationsButton />
                <NotificationsButton />
            </Grid>
            <Grid item className={classes.menuIconContainer}>
                <IconButton
                    id="menu-mobile-button"
                    onClick={toggleMenuHandler}
                    icon={isMobileToggled ? CloseIcon : MenuIcon}
                    noShadow
                />
                {!isMobileToggled && hasUnreadNotifications && (
                    <CustomBadge className={mobileBadgeStyles.notifyIconOnMenu} />
                )}
            </Grid>
        </Grid>
    );
};

export default withLocalization(MobileHeader, LocalizedComponentName.NavMenu);
