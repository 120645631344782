/**
 * Summary: Get all notifications for the authorized user.
 *
 * Tags: Notifications
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_NOTIFICATIONS_URL = "api/v1/notifications";

export const apiGetNotifications = async (
    request?: ApiGetNotificationsRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetNotificationsResponse> => {
    const result = await httpGet<ApiGetNotificationsResponse, ApiGetNotificationsRequest>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_GET_NOTIFICATIONS_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export interface ApiGetNotificationsRequest {
    limit?: number; // Notification limit. Default value: 100. Example: 10
    afterId?: number; // Ascending notification ID cursor position. Example: 123
    beforeId?: number; // Descending notification ID cursor position. Example: 456
}

/**
 * Description: Success
 */
export type ApiGetNotificationsResponse = GetUserNotificationsResponse;

interface UserNotification {
    appNotificationId: number; // In-app notification ID. Example: 23
    createdDate: Date; // Creation date. Example: 2021-02-22
    createdDateRelativeToNow?: string; // Creation date relative to now. Example: 3 days ago
    text?: string; // Notification message. Example: You were invited to property.
    isRead: boolean; // Flag whether message is read. Example: true
}

interface GetUserNotificationsResponse {
    totalCount: number; // Total count of notifications. Example: 123
    items?: UserNotification[]; // List of notifications.
}
