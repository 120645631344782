import React from "react";

import { Theme } from "@material-ui/core/styles";

export interface ApplicationThemeContextModel {
    theme?: Theme;
    childTheme?: Theme;
    setChildTheme?: (theme: Theme) => void;
}

export const ApplicationThemeContext = React.createContext<ApplicationThemeContextModel>({});

export const ApplicationThemeContextProvider = ApplicationThemeContext.Provider;

export const useApplicationThemeContext = (): ApplicationThemeContextModel => React.useContext(ApplicationThemeContext);
