import React from "react";

import { observer } from "mobx-react-lite";

import { IntercomChatLocalizationId } from "components/bootstrapIntercom/localization";
import { IntercomStore } from "components/bootstrapIntercom/store";
import { useIntercomStyles } from "components/bootstrapIntercom/styles";
import { LocalizedComponentName } from "localization/enums";
import { withLocalization } from "localization/withLocalization";
import { useLocalize } from "shared/hooks/useLocalize";
import { useStore } from "shared/hooks/useStore";
import Tooltip from "uiKit/container/tooltip";
import ChatDownIcon from "uiKit/icons/ChatDownIcon";
import ChatUpIcon from "uiKit/icons/ChatUpIcon";

const IntercomLauncher: React.FC = () => {
    const {
        store: { isOpen, isHidden },
    } = useStore(IntercomStore);
    const styles = useIntercomStyles({ isOpen });
    const localize = useLocalize();

    if (isHidden) {
        return null;
    }

    return (
        <div id="intercom_launcher" className={styles.launcher}>
            <Tooltip title={localize(IntercomChatLocalizationId.ChatTooltip)} placement={"top"} disabled={isOpen}>
                <div className={styles.iconWrapper}>
                    {isOpen ? (
                        <ChatDownIcon fill="#FFFFFF" className={styles.iconDown} />
                    ) : (
                        <ChatUpIcon fill="#FFFFFF" className={styles.iconUp} />
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

export default withLocalization(observer(IntercomLauncher), LocalizedComponentName.IntercomChat);
