import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";

import NotificationItem from "components/notifications/notificationItem";
import { useNotificationsListStyles } from "components/notifications/notificationsList/styles";
import { UserNotification } from "shared/interfaces/notifications";

const NOTIFICATIONS_SCROLLABLE_TARGET = "notificationsScrollableTarget";

interface NotificationsListProps {
    notifications: UserNotification[];
    onDeleteNotification: (appNotificationId: number) => Promise<void>;
    onClose: () => Promise<void>;
    onNextPage: () => Promise<void>;
    hasMore: boolean;
}

const NotificationsList = ({
    notifications,
    onDeleteNotification,
    onClose,
    onNextPage,
    hasMore,
}: NotificationsListProps): JSX.Element => {
    const styles = useNotificationsListStyles();
    const scrollableRef = useRef<HTMLDivElement>(null);

    const handleDeleteNotification = useCallback(
        async (appNotificationId: number) => {
            await onDeleteNotification(appNotificationId);
            scrollableRef.current?.scrollTo({ top: 0 });
        },
        [onDeleteNotification]
    );

    const onNext = useCallback(async () => {
        await onNextPage();
    }, [onNextPage]);

    useEffect(() => {
        const handleOrientationChange = async () => {
            await onClose();
        };
        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    });

    const loader = useMemo(
        () => (
            <div className={styles.loader}>
                <CircularProgress />
            </div>
        ),
        [styles.loader]
    );

    const items = useMemo(
        () =>
            notifications.map(notification => (
                <NotificationItem
                    notification={notification}
                    onDeleteNotification={handleDeleteNotification}
                    key={notification.appNotificationId}
                />
            )),
        [handleDeleteNotification, notifications]
    );

    return (
        <div id={NOTIFICATIONS_SCROLLABLE_TARGET} className={clsx(styles.root)} ref={scrollableRef}>
            <InfiniteScroll
                scrollableTarget={NOTIFICATIONS_SCROLLABLE_TARGET}
                dataLength={notifications.length}
                next={onNext}
                hasMore={hasMore}
                loader={loader}
            >
                {items}
            </InfiniteScroll>
        </div>
    );
};

export default observer(NotificationsList);
