import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";

import { transformResponse } from "./utils";

export const httpGet = async <TResponse, TRequest = Record<string, unknown>>(
    baseUrl: string,
    relativeUrl: string,
    data?: TRequest,
    config?: AxiosRequestConfig
): Promise<AxiosResponse<TResponse>> => {
    return getResponse<TResponse>(baseUrl, relativeUrl, "GET", {
        ...config,
        params: data || undefined,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: "repeat" });
        },
    });
};

export const httpPost = async <TResponse, TRequest = Record<string, unknown>>(
    baseUrl: string,
    relativeUrl: string,
    data?: TRequest,
    config?: AxiosRequestConfig
): Promise<AxiosResponse<TResponse>> => {
    return getResponse<TResponse, TRequest>(baseUrl, relativeUrl, "POST", config, data);
};

export const httpPut = async <TResponse, TRequest = Record<string, unknown>>(
    baseUrl: string,
    relativeUrl: string,
    data?: TRequest,
    config?: AxiosRequestConfig
): Promise<AxiosResponse<TResponse>> => {
    return getResponse<TResponse, TRequest>(baseUrl, relativeUrl, "PUT", config, data);
};

export const httpDelete = async <TResponse, TRequest = Record<string, unknown>>(
    baseUrl: string,
    relativeUrl: string,
    data?: TRequest,
    config?: AxiosRequestConfig
): Promise<AxiosResponse<TResponse>> => {
    return getResponse(baseUrl, relativeUrl, "DELETE", config, data);
};

const getResponse = async <TResponse, TRequest = Record<string, unknown>>(
    baseUrl: string,
    relativeUrl: string,
    method: "GET" | "POST" | "DELETE" | "PUT",
    config?: AxiosRequestConfig,
    data?: TRequest
): Promise<AxiosResponse<TResponse>> => {
    const axiosRequestConfig = {
        ...config,
        url: `${baseUrl}${relativeUrl}`,
        method,
        data,
        transformResponse,
    };
    return axios(axiosRequestConfig);
};
