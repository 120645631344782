import { hasIn } from "lodash";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";

import { isAxios404Error, pickServerValidationErrors } from "shared/api/utils";
import { showErrorToast } from "uiKit/container/toast/helpers";

export function handleValidationErrors<TFieldValues extends FieldValues>(
    error: unknown,
    formFields?: TFieldValues,
    methods?: UseFormReturn<TFieldValues>,
    formFieldsPrefix = "",
    concurrencyErrorText = "",
    concurrencyExceptionErrorCodes: string[] = []
): void {
    const validationErrors = pickServerValidationErrors(error);
    const getFieldName = (fieldName: string) => {
        if (hasIn(formFields, fieldName)) {
            return fieldName as FieldPath<TFieldValues>;
        }
        const prefixedFieldName = `${formFieldsPrefix}${fieldName}`;
        if (!!formFieldsPrefix && hasIn(formFields, prefixedFieldName)) {
            return prefixedFieldName as FieldPath<TFieldValues>;
        }
        return undefined;
    };

    const fieldsErrors = validationErrors.filter(({ fieldName }) => !!getFieldName(fieldName));
    const otherErrors = validationErrors.filter(({ fieldName }) => !getFieldName(fieldName));

    if (methods) {
        fieldsErrors.forEach(({ message, fieldName }) => {
            const formFieldName = getFieldName(fieldName)!;
            methods.setError(formFieldName, { type: "manual", message });
        });
    }

    // TODO: add isAxios409Error(error)
    if (!otherErrors.length && !isAxios404Error(error)) {
        return;
    }

    if (!!concurrencyErrorText) {
        /** Concurrency error
            const title = otherErrors[0].message;

            showErrorToast({
                title: title ?? concurrencyErrorText,
                message: title ? concurrencyErrorText : undefined,
            });
            */
        const exceptions = validationErrors.filter(e => concurrencyExceptionErrorCodes.includes(e.code));
        if (exceptions.length) {
            exceptions.forEach(({ message }) => showErrorToast({ message }));
        } else {
            showErrorToast({ title: concurrencyErrorText });
        }
    } else {
        otherErrors.forEach(({ message }) => showErrorToast({ message }));
    }
}
