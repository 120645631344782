import { TxtColor, applicationPalettes } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useNotificationItemStyles = makeSharedStyles(theme => ({
    root: {
        borderBottom: `1px solid ${applicationPalettes.primary[50]}`,
        padding: theme.spacing(2, 0, 1),
    },
    icon: {
        marginRight: theme.spacing(),
        height: 18,
        "& svg": {
            width: 18,
            height: 18,
        },
    },
    contentContainer: {},
    title: {
        ...theme.typography.button2,
        color: TxtColor.Txt1,
        lineHeight: "24px",
    },
    description: {
        color: TxtColor.Txt2,
        wordBreak: "break-word",
    },
}));
