const routeNames = {
    LOGIN: {
        ROOT: "/login",
        CALLBACK: "/login/callback",
    },
    LOGOUT: {
        ROOT: "/logout",
        SWITCH: "/logout/switch/:accountId(\\d+)",
    },
    LOGGED_OUT: "/logged-out",
    SIGN_UP: {
        SIGN_UP: "/sign-up",
        SIGN_UP_LINK: "/continue-sign-up",
    },
    DASHBOARD: "/dashboard",
    ACTIVITY: {
        ROOT: "/activity",
        GENERAL_ACTIVITIES: "/activity/general-activities",
        ACCESS_LOG: "/activity/access-log",
        INSURANCE_ACTIVITIES: "/activity/insurance-activities",
    },
    ERRORS: {
        NOT_FOUND: "/not-found",
        FORBIDDEN: "/forbidden",
        INTERNAL_ERROR: "/internal-error",
        EXPIRED_CHANGE_EMAIL_LINK: "/change-email-expired",
    },
    RESOLUTION_PANEL: {
        ROOT: "/resolution-panel",
        RESOLUTIONS_MANAGEMENT: "/resolution-panel/management",
        RELEASE_REQUESTS: "/resolution-panel/release-requests",
    },
    MY_PROFILE: {
        ROOT: "/profile",
        BILLING: "/profile/billing",
    },
    CHANGE_EMAIL: "/change-email",
    PAYMENT_HISTORY: {
        ROOT: "/payment-history",
    },
    COMPANY: {
        ROOT: "/company",
        DETAILS: "/company/details",
        OFFICES: "/company/offices",
        OFFICE: {
            ROOT: "/company/offices/:id(\\d+)",
            DETAILS: "/company/offices/:id(\\d+)/details",
            PROPERTIES: "/company/offices/:id(\\d+)/properties",
            STAFF: "/company/offices/:id(\\d+)/staff",
            REDIRECT: "/company/offices/:id(\\d+)/redirect",
        },
        BILLING: {
            ROOT: "/company/billing",
            OFFICE: "/company/billing/offices/:id(\\d+)",
        },
    },
    STAFF: {
        ROOT: "/staff",
        USERS: "/staff/users",
        USER: "/staff/users/:id(\\d+)",
        ROLES: {
            ROOT: "/staff/roles",
            NEW: "/staff/roles/new",
        },
        ROLE: "/staff/roles/:roleId",
    },
    PROPERTIES: {
        ROOT: "/properties",
        LIST: "/properties/list",
        CREATE: "/properties/create",
        VOUCHERS: "/properties/vouchers",
    },
    PROPERTY: {
        ROOT: "/properties/:id(\\d+)",
        DETAILS: "/properties/:id(\\d+)/property",
        CLAIMS: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/claims",
            CREATE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/claims/create",
        },
        END_OF_TENANCY: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/end-of-tenancy",
            CREATE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/end-of-tenancy/create",
        },
        TENANCY: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)?",
            CREATE: "/properties/:id(\\d+)/tenancy/create",
            CHANGE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/change",
        },
        RELEASE_REQUEST: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/release-request/:releaseRequestId(\\d+)?",
            CREATE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/release-request/create/:releaseRequestId(\\d+)?",
            SINGLE_RELEASE: {
                CREATE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/release-request/:releaseRequestId(\\d+)/single-release/create",
                UPDATE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/release-request/:releaseRequestId(\\d+)/single-release/:singleReleaseId(\\d+)/update",
            },
        },
        DEPOSIT: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/deposit",
            EMPTY: "/properties/:id(\\d+)/deposit",
            TRANSFER: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/deposit/transfer",
            MID_TERM_RELEASE: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/deposit/mid-term-release",
        },
        CHANGE_OF_TENANTS: {
            ROOT: "/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)/change-of-tenants",
        },
        MATCH_IDS: "/properties/:id(\\d+)/(tenancy)?/:tenancyId(\\d+)?/(release-request)?/:releaseRequestId(\\d+)?",
    },
    TENANCIES: {
        ROOT: "/tenancies",
        LIST: "/tenancies/list",
        BULK_UPLOAD: "/tenancies/import",
    },
    DEPOSITS: {
        ROOT: "/deposits",
        LIST: "/deposits/list",
        BASKET: "/deposits/basket",
        BULK_RELEASE_REQUEST_CONFIRMATION: "/deposits/bulk-release-request-confirmation",
        IN_PROGRESS: {
            ROOT: "/deposits/in-progress",
        },
        UNALLOCATED_FUNDS: {
            ROOT: "/deposits/unallocated-funds",
            REFUND: "/deposits/unallocated-funds/refund",
            ALLOCATE: "/deposits/unallocated-funds/allocate",
        },
        BASKET_CHECKOUT: "/deposits/checkout",
    },
    LANDLORDS: {
        ROOT: "/landlords",
    },
    LANDLORD: {
        ROOT: "/landlords/:id(\\d+)",
    },
    INVITATIONS: {
        REVOKE_INVITE: "/revoke-invite",
        EXPIRED_INVITE: "/expired-invite",
    },
    INSURANCE: {
        ROOT: "/insurance",
        LANDLORD_INSURANCE: "/insurance/landlord",
    },
    TENANT_HOME: {
        ROOT: "/home",
        PROPERTY: "/home/property",
        TENANCY: "/home/tenancy",
        DEPOSIT: "/home/deposit",
        END_OF_TENANCY: {
            ROOT: "/home/end-of-tenancy",
            CREATE: "/home/end-of-tenancy/create",
        },
        CHANGE_OF_TENANTS: {
            ROOT: "/home/change-of-tenants",
        },
        RELEASE_REQUEST: {
            CREATE: "/home/properties/:id(\\d+)/tenancy/:tenancyId(\\d+)?/release-request/create",
        },
    },
    TENANT_BILLING: {
        ROOT: "/billing",
    },
    MISSING_DETAILS: "/missing-details",

    // Routes for redirects
    REDIRECTS: {
        PROPERTY: {
            RESOLUTION_MANAGEMENT: "/properties/:id(\\d+)/resolution-management",
            TENANCY: {
                ROOT: "/properties/:id(\\d+)/tenancy",
            },
            CLAIMS: {
                ROOT: "/properties/:id(\\d+)/claims",
                CREATE: "/properties/:id(\\d+)/claims/create",
            },
            END_OF_TENANCY: {
                ROOT: "/properties/:id(\\d+)/end-of-tenancy",
                CREATE: "/properties/:id(\\d+)/end-of-tenancy/create",
            },
            DEPOSIT: {
                ROOT: "/properties/:id(\\d+)/deposit",
            },
        },
        TENANCY: {
            ROOT: "/tenancy/:id(\\d+)",
        },
        DEPOSIT: {
            ROOT: "/deposit/:id(\\d+)",
        },
        ACCOUNT: {
            ROOT: "/accounts/:id(\\d+)",
        },
        FILE_LOADING: {
            ROOT: "/file-loading",
        },
        RELEASE_REQUEST: {
            ROOT: "/release-request/:id(\\d+)",
            ALL_EVIDENCE: "/release-request/:id(\\d+)/all-evidence",
        },
    },

    // ONLY Internal User routes
    ADMIN: {
        USERS: {
            ROOT: "/users",
            CREATE: "/users/create",
        },
        USER: {
            ROOT: "/users/:id(\\d+)",
            DETAILS: "/users/:id(\\d+)/details",
            VOUCHERS: "/users/:id(\\d+)/vouchers",
            PROPERTIES: "/users/:id(\\d+)/properties",
            BILLING: "/users/:id(\\d+)/billing",
            BASKET: "/users/:id(\\d+)/basket",
            IN_PROGRESS: "/users/:id(\\d+)/in-progress",
            PAYMENT_HISTORY: "/users/:id(\\d+)/payment-history",
            TENANCIES: "/users/:id(\\d+)/tenancies",
            UNALLOCATED_FUNDS: {
                ROOT: "/users/:id(\\d+)/unallocated-funds",
                REFUND: "/users/:id(\\d+)/unallocated-funds/refund",
                TRANSFER: "/users/:id(\\d+)/unallocated-funds/transfer",
            },
            LINK_ACCOUNT: "/users/:id(\\d+)/link-account",
            MERGE_ACCOUNT: "/users/:id(\\d+)/merge-account",
        },
        SETTINGS: {
            ROOT: "/settings",
            PORTAL: "/settings/portal",
            MEMBERSHIP_RATES: "/settings/membership-rates",
            ADMINS: "/settings/admins",
            ADMIN: {
                ROOT: "/settings/admins/:id(\\d+)",
                DETAILS: "/settings/admins/:id(\\d+)/details",
            },
            ROLES: {
                ROOT: "/settings/roles",
                NEW: "/settings/roles/new",
            },
            ROLE: "/settings/roles/:roleId",
            SCHEME_CONFIGURATION: {
                ROOT: "/settings/scheme-configuration",
                SCHEME: "/settings/scheme-configuration/:schemeId(\\d+)",
            },
            PARTNERS: {
                ROOT: "/settings/partners",
            },
            PARTNER: {
                ROOT: "/settings/partners/:id(\\d+)",
            },
        },
        FINANCE: {
            ROOT: "/finance",
            VOUCHERS: "/finance/vouchers",
            FAILED_PAYMENTS: "/finance/failed-payments",
            FAILED_PAYMENTS_REPLACEMENT: "/finance/failed-payments-replacement",
            PAYMENT_HISTORY: "/finance/payment-history",
            CHARITY: "/finance/charity",
            SETTINGS: "/finance/settings",
        },
        BUSINESSES: {
            ROOT: "/businesses",
            COMPANIES: "/businesses/companies",
            PROFILE: {
                ROOT: "/businesses/companies/:id(\\d+)",
                STAFF: "/businesses/companies/:id(\\d+)/staff",
                DETAILS: "/businesses/companies/:id(\\d+)/details",
                OFFICES: "/businesses/companies/:id(\\d+)/offices",
                PROPERTIES: "/businesses/companies/:id(\\d+)/properties",
                VOUCHERS: "/businesses/companies/:id(\\d+)/vouchers",
                BASKET: "/businesses/companies/:id(\\d+)/basket",
                IN_PROGRESS: "/businesses/companies/:id(\\d+)/in-progress",
                BILLING: {
                    ROOT: "/businesses/companies/:id(\\d+)/billing",
                    OFFICE: "/businesses/companies/:id(\\d+)/billing/offices/:officeId(\\d+)",
                },
                UNALLOCATED_FUNDS: {
                    ROOT: "/businesses/companies/:id(\\d+)/unallocated-funds",
                    REFUND: "/businesses/companies/:id(\\d+)/unallocated-funds/refund",
                    TRANSFER: "/businesses/companies/:id(\\d+)/unallocated-funds/transfer",
                },
                PAYMENT_HISTORY: "/businesses/companies/:id(\\d+)/payment-history",
            },
            MERGE_ACCOUNT: "/businesses/companies/:id(\\d+)/merge-account",
            OFFICE_PROFILE: {
                ROOT: "/businesses/companies/:companyId(\\d+)/offices/:id(\\d+)",
                DETAILS: "/businesses/companies/:companyId(\\d+)/offices/:id(\\d+)/details",
                PROPERTIES: "/businesses/companies/:companyId(\\d+)/offices/:id(\\d+)/properties",
                STAFF: "/businesses/companies/:companyId(\\d+)/offices/:id(\\d+)/staff",
            },
        },
    },
};

export default routeNames;
