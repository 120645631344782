import {
    Context,
    Dispatch,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

const REMOVE_STORE_CONTEXT_TIMEOUT = 1000;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StoreType {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StoreClassType<T> = abstract new (...args: any[]) => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CreatableStoreClassType<T> = new (...args: any[]) => T;

type ContextListenerType = Dispatch<SetStateAction<number>>;

const contexts: Map<StoreClassType<unknown>, Context<unknown>> = new Map();
const contextUsages: Map<StoreClassType<unknown>, number> = new Map();
const contextCleaners: Map<StoreClassType<unknown>, NodeJS.Timeout> = new Map();
const contextListeners: Map<StoreClassType<unknown>, Set<ContextListenerType>> = new Map();

const triggerWeakStoreRender = <T>(StoreClass: StoreClassType<T>) => {
    const currentListeners = contextListeners.get(StoreClass) || new Set<ContextListenerType>();
    for (const listener of currentListeners) {
        listener(prev => prev + 1);
    }
};

const addContextListener = <T>(StoreClass: StoreClassType<T>, listener: ContextListenerType) => {
    const listeners = contextListeners.get(StoreClass);
    if (listeners) {
        listeners.add(listener);
    } else {
        contextListeners.set(StoreClass, new Set<ContextListenerType>([listener]));
    }
};

const removeContextListener = <T>(StoreClass: StoreClassType<T>, listener: ContextListenerType) => {
    const listeners = contextListeners.get(StoreClass);
    if (!listeners) {
        return;
    }

    listeners.delete(listener);
    if (!listeners.size) {
        contextListeners.delete(StoreClass);
    }
};

export const useStore = <T extends StoreType>(
    StoreClass: StoreClassType<T>,
    storeFactory?: () => T
): { store: T; context: Context<T> } => {
    const create = useCallback(
        () => createContext<unknown>(storeFactory ? storeFactory() : new (StoreClass as CreatableStoreClassType<T>)()),
        [StoreClass, storeFactory]
    );

    const context = useMemo(() => (contexts.get(StoreClass) || create()) as Context<T>, [StoreClass, create]);
    const store = useContext<T>(context);

    if (!contexts.has(StoreClass)) {
        contexts.set(StoreClass, context as Context<unknown>);
    }

    useEffect(() => {
        // Please don't remove, sometimes ^^ will be removed in unmount effect before size in current usage will be incremented
        if (!contexts.has(StoreClass)) {
            console.error("Missing store on mount", StoreClass.name);
        }

        const currentContextUsageCount = contextUsages.get(StoreClass) || 0;
        contextUsages.set(StoreClass, currentContextUsageCount + 1);
        if (!currentContextUsageCount) {
            triggerWeakStoreRender(StoreClass);
        }
    }, [StoreClass]);

    useEffect(
        () => () => {
            const currentContextUsageCount = contextUsages.get(StoreClass) || 0;
            const newContextUsageCount = currentContextUsageCount - 1;
            contextUsages.set(StoreClass, newContextUsageCount);

            setupContextCleaner(StoreClass);
        },
        [StoreClass]
    );

    return {
        store,
        context,
    };
};

export const useWeakStore = <T extends StoreType>(
    StoreClass: StoreClassType<T>
): { store?: T; context?: Context<T> } => {
    const context = contexts.get(StoreClass) as Context<T>;
    const store = useContext<T>(context || createContext<unknown>({}));
    const [, setRenderCounter] = useState(0);

    addContextListener(StoreClass, setRenderCounter);

    useEffect(() => {
        return () => {
            removeContextListener(StoreClass, setRenderCounter);
        };
    }, [StoreClass]);

    if (!context) {
        return {
            store: undefined,
            context: undefined,
        };
    }

    return {
        store,
        context,
    };
};

export const dropStoreContext = <T extends StoreType>(StoreClass: StoreClassType<T>) => {
    contexts.delete(StoreClass);
    triggerWeakStoreRender(StoreClass);
};

const clearStoreContext = <T extends StoreType>(StoreClass: StoreClassType<T>) => {
    const usageCount = contextUsages.get(StoreClass);

    if (usageCount === undefined) {
        return;
    }

    if (usageCount <= 0) {
        dropStoreContext(StoreClass);
        contextUsages.delete(StoreClass);
    }
};

const setupContextCleaner = <T extends StoreType>(StoreClass: StoreClassType<T>) => {
    const existCleaner = contextCleaners.get(StoreClass);
    if (existCleaner) {
        clearTimeout(existCleaner);
    }

    const timer = setTimeout(() => {
        if (typeof requestIdleCallback === "function") {
            requestIdleCallback(() => {
                clearStoreContext(StoreClass);
            });
        } else {
            clearStoreContext(StoreClass);
        }
    }, REMOVE_STORE_CONTEXT_TIMEOUT);

    contextCleaners.set(StoreClass, timer);
};
