import ButtonProgressIndicatorSizeControl from "shared/interfaces/buttons/buttonProgress/buttonProgress";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { StylesProps } from "shared/theme/utils";

import { ButtonSize } from "./commonButtonsProps";

interface ButtonStyle {
    height: string;
    width: string;
    minWidth: string;
    borderRadius: string;
    padding: string;
}

const buttonsStyles: { [key in ButtonSize]: ButtonStyle } = {
    l: { height: "3.5rem", width: "22.5rem", minWidth: "unset", borderRadius: "0.5rem", padding: "1rem 0" },
    m: { height: "3rem", width: "auto", minWidth: "11rem", borderRadius: "0.5rem", padding: "0.75rem 1.5rem" },
    s: { height: "2.5rem", width: "auto", minWidth: "7.5rem", borderRadius: "0.375rem", padding: "0.5rem 1rem" },
};

export function buttonHeight(props: StylesProps): string {
    return buttonStyle(props, "height");
}

export function buttonWidth(props: StylesProps): string {
    return buttonStyle(props, "width");
}

export function buttonMinWidth(props: StylesProps): string {
    return buttonStyle(props, "minWidth");
}

export function buttonMaxWidth(): string {
    return "22.5rem";
}

export function buttonBorderRadius(props: StylesProps): string {
    return buttonStyle(props, "borderRadius");
}

export function buttonPaddings(props: StylesProps): string {
    return buttonStyle(props, "padding");
}

function buttonStyle(props: StylesProps, style: keyof ButtonStyle) {
    return buttonsStyles[props.size || "m"][style];
}

type ButtonTextVariant = "button1" | "button2";

export const buttonSizeToTextVariantMapping: { [key in ButtonSize]: ButtonTextVariant } = {
    l: "button1",
    m: "button1",
    s: "button2",
};

export const useButtonProgressStyles = makeSharedStyles(() => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: (props: ButtonProgressIndicatorSizeControl) => props.width,
        height: (props: ButtonProgressIndicatorSizeControl) => props.height,
        margin: (props: ButtonProgressIndicatorSizeControl) => props.margin,
    },
}));
