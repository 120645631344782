import React, { useContext } from "react";

import { RootStore } from "app/rootStore";

interface RootStoreContextValue {
    rootStore: RootStore;
}

export const RootStoreContext = React.createContext<RootStoreContextValue | undefined>(undefined);

export function useRootStore(): RootStoreContextValue {
    const context = useContext(RootStoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be within RootStoreContext.Provider");
    }

    return context;
}
