/**
 * Summary: Get deposit tenancy.
 *
 * Tags: Deposits
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_DEPOSIT_TENANCY_URL = (depositId: number): string => `api/v1/deposits/${depositId}/tenancy`;

export const apiGetDepositTenancy = async (
    depositId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetDepositTenancyResponse> => {
    const result = await httpGet<ApiGetDepositTenancyResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_DEPOSIT_TENANCY_URL(depositId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetDepositTenancyResponse = DepositTenancyLookupModel;

interface DepositTenancyLookupModel {
    tenancyId: number; // Tenancy Id. Example: 123
    propertyId: number; // Property Id. Example: 456
}
