import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

import { TxtColor } from "shared/theme/defaultTheme";

const NotificationEnvelopeClosed = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path
                d="M16.4925 6C16.4925 5.46 16.215 4.9875 15.7875 4.725L9 0.75L2.2125 4.725C1.785 4.9875 1.5 5.46 1.5 6V13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5L16.4925 6ZM14.9925 6V6.0075L9 9.75L3 6L9 2.49L14.9925 6ZM3 13.5V7.755L9 11.52L14.9925 7.7775L15 13.5H3Z"
                fill={TxtColor.Txt4}
            />
        </SvgIcon>
    );
};

export default NotificationEnvelopeClosed;
