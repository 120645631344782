/**
 * Summary: Get Tenants for selected Tenancy.
 *
 * Tags: TenancyTenants
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_TENANCY_TENANTS_URL = (tenancyId: number): string => `api/v1/tenancies/${tenancyId}/tenants`;

export const apiGetTenancyTenants = async (
    tenancyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetTenancyTenantsResponse> => {
    const result = await httpGet<ApiGetTenancyTenantsResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_TENANCY_TENANTS_URL(tenancyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetTenancyTenantsResponse = TenancyTenantEntity[];

interface AddressModel {
    postcode: string; // Postcode. Example: ST13 2NA
    addressLine1: string; // Address Line 1. Example: 79 Folkestone Road
    addressLine2?: string; // Address Line 2. Example: Building 5
    city: string; // City. Example: London
    country: CountryLookUpModel; // Country.
    latitude?: number; // Gets or sets latitude. Example: 53.89301
    longitude?: number; // Gets or sets longitude. Example: 27.567444
    uniquePropertyReferenceNumber?: string; // Unique Property Reference Number.  It consists of numbers of up to 12 digits in length. Example: 87611
    isAddressSetManually: boolean; // Gets or sets flag indicating that address was set manually.
    region?: LookUpModel; // Administrative unit.
}

interface CountryLookUpModel {
    id: number;
    text: string;
    iso?: string;
}

interface LookUpModel {
    id: number;
    text: string;
}

interface TenancyTenantEntity {
    email: string; // Email. Example: john@doe.com
    firstName: string; // First Name. Example: James
    lastName: string; // Last Name. Example: Smith
    phoneNumber: string; // PhoneNumber. Example: +44555555555
    address?: AddressModel; // Address.
    isLeadTenant: boolean; // Is Lead Tenant.
    id: number; // Id.
    tenantAccountId: number; // Tenant account Id.
    tenancyTenantStatus?: LookUpModel; // Tenant status.
}
