/**
 * Summary: Get stripe configuration.
 *
 * Tags: Settings
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_SETTINGS_STRIPE_KEYS_URL = "api/v1/settings/stripe-keys";

export const apiGetSettingsStripeKeys = async (
    config?: AxiosRequestConfig
): Promise<ApiGetSettingsStripeKeysResponse> => {
    const result = await httpGet<ApiGetSettingsStripeKeysResponse>(
        window.APP_SETTINGS.paymentServiceApiBaseUri,
        API_GET_SETTINGS_STRIPE_KEYS_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetSettingsStripeKeysResponse = GetStripeKeysResponse;

interface StripeConfiguration {
    schemeId: number; // Deposit Scheme Id. Example: 2
    publicKey?: string; // Stripe public key. Example: a7a7fcc7ceghdebebert
}

interface GetStripeKeysResponse {
    keys?: StripeConfiguration[];
}
