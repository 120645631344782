/**
 * Summary: Get account.
 *
 * Tags: Accounts
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_ADMIN_ACCOUNT_URL = (accountId: number): string => `api/v1/admin/accounts/${accountId}`;

export const apiGetAdminAccount = async (
    accountId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetAdminAccountResponse> => {
    const result = await httpGet<ApiGetAdminAccountResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_ADMIN_ACCOUNT_URL(accountId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetAdminAccountResponse = GetAccountResponse;

interface GetAccountResponse {
    accountTypeId: 1 | 2 | 3 | 4 | 5 | 100; // Account type ID.<p>Descriptions:</p><ul><li><i>1</i> - Admin.</li><li><i>2</i> - Private landlord.</li><li><i>3</i> - Corporate landlord.</li><li><i>4</i> - Tenant.</li><li><i>5</i> - Agent.</li><li><i>100</i> - None.</li></ul> Example: 5
    primaryUserId: number; // Primary user ID. Example: 42
}
