import { History } from "history";

const prependScript = (code: string) => {
    const script = document.createElement("script");
    script.async = true;
    script.setAttribute("type", "text/javascript");
    script.appendChild(document.createTextNode(code));
    document.head.prepend(script);
};

const prependScriptBySrc = (src: string) => {
    const script = document.createElement("script");
    script.async = true;
    script.setAttribute("type", "text/javascript");
    script.src = src;
    document.head.prepend(script);
};

const initializeReactGA = (browserHistory: History): void => {
    const { gaTrackingId, gaDebugMode } = window.APP_SETTINGS;

    if (gaTrackingId) {
        const gtagScriptCode = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "${gaTrackingId}", {
            send_page_view: false,
            debug_mode: ${gaDebugMode},
        });
        `;
        prependScript(gtagScriptCode);
        prependScriptBySrc(`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`);

        browserHistory.listen(location => {
            gtag("set", { page_location: location.pathname });
            gtag("event", "page_view", { page_location: location.pathname });
        });
    }
};

export default initializeReactGA;
