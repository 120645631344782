import React from "react";

import useLeavePage from "shared/hooks/useLeavePage";
import { LeavePagePromptProps } from "shared/interfaces/container/dialog/leavePagePrompt";
import Dialog from "uiKit/container/dialog/Dialog";

const LeavePagePrompt = ({
    isDirty,
    title,
    children,
    primaryButtonText,
    additionalButtonText,
    optionalButtonText,
    onPrimaryButtonClick,
    onAdditionalButtonClick,
    onOptionalButtonClick,
    ignoreLoading = false,
}: LeavePagePromptProps): JSX.Element => {
    const { modalVisible, closeModal, confirmNavigation } = useLeavePage({ isDirty });

    const handleConfirmNavigationClick = () => {
        closeModal();
        confirmNavigation();
    };

    const handlePrimaryButton = async () => {
        await onPrimaryButtonClick?.();
        handleConfirmNavigationClick();
    };

    const handleAdditionalButton = async () => {
        await onAdditionalButtonClick?.();

        if (optionalButtonText) {
            handleConfirmNavigationClick();
        } else {
            closeModal();
        }
    };

    const handleOptionalButton = () => {
        onOptionalButtonClick?.();
        closeModal();
    };

    return (
        <Dialog
            isOpened={modalVisible}
            dialogTitle={title}
            primaryButtonText={primaryButtonText}
            additionalButtonText={additionalButtonText}
            optionalButtonText={optionalButtonText}
            handleClose={closeModal}
            handlePrimaryButton={handlePrimaryButton}
            handleAdditionalButton={handleAdditionalButton}
            handleOptionalButton={handleOptionalButton}
            ignoreLoading={ignoreLoading}
        >
            {children}
        </Dialog>
    );
};

export default LeavePagePrompt;
