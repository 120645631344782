import { LocalizeFunction } from "shared/hooks/useLocalize";
import { BaseLookup, OptionalLookup } from "shared/models/lookups";

export const findLookupById = <T extends BaseLookup | OptionalLookup>(lookups: T[], id: number): T | undefined =>
    lookups.find(lookup => lookup.id === id);

export const isPossibleBaseLookup = (obj: BaseLookup | Record<string, unknown> | unknown): obj is BaseLookup => {
    if (!obj || typeof obj !== "object" || obj === null) {
        return false;
    }

    if ("id" in obj && "text" in obj) {
        return true;
    }

    return false;
};

export const localizeLookupText = (lookup: BaseLookup | OptionalLookup, localize: LocalizeFunction): BaseLookup => ({
    ...lookup,
    text: lookup.text ? localize(lookup.text) : "",
});

export const localizeLookupsText = (
    lookups: BaseLookup[] | OptionalLookup[],
    localize: LocalizeFunction
): BaseLookup[] => {
    return lookups.map(it => localizeLookupText(it, localize));
};
