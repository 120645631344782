import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

interface ChatDownIconProps extends SvgIconProps {
    fill: string;
}

const ChatDownIcon = (props: ChatDownIconProps): JSX.Element => {
    const { fill, ...rest } = props;

    return (
        <SvgIcon {...rest} viewBox="0 0 16 14">
            <path fill={fill} d="M.116 4.884l1.768-1.768L8 9.232l6.116-6.116 1.768 1.768L8 12.768.116 4.884z" />
        </SvgIcon>
    );
};

export default ChatDownIcon;
