import { shouldPolyfill as shouldPolyfillCanonical } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillPlural } from "@formatjs/intl-pluralrules/should-polyfill";

const loadIntlPolyfill = async (locale: string): Promise<void> => {
    loadCanonicalPolyfill();
    loadLocalePolyfill();
    loadPluralPolyfill(locale);
};

export const loadCanonicalPolyfill = async (): Promise<void> => {
    if (shouldPolyfillCanonical()) {
        await import("@formatjs/intl-getcanonicallocales/polyfill");
    }
};

export const loadLocalePolyfill = async (): Promise<void> => {
    if (shouldPolyfillLocale()) {
        await import("@formatjs/intl-locale/polyfill");
    }
};

export const loadPluralPolyfill = async (locale: string): Promise<void> => {
    if (shouldPolyfillPlural()) {
        await import("@formatjs/intl-pluralrules/polyfill");
    }
    if ("polyfilled" in Intl.PluralRules) {
        switch (locale) {
            default:
                await import("@formatjs/intl-pluralrules/locale-data/en");
                break;
        }
    }
};

export default loadIntlPolyfill;
