import React, { useCallback } from "react";

import Markdown from "markdown-to-jsx";

import Link from "uiKit/Link";

export interface MarkdownTextProps {
    text: string;
    isOpenAtNewPage?: boolean;
}

const MarkdownText = ({ text, isOpenAtNewPage = false }: MarkdownTextProps): JSX.Element => {
    const getThemeLink = useCallback(
        ({
            href,
            children,
        }: React.ClassAttributes<HTMLAnchorElement> & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
            const label = children && Array.isArray(children) ? children[0] : undefined;
            return label ? (
                <Link label={label} link={href} isOpenAtNewPage={isOpenAtNewPage} typographyVariant="body2" />
            ) : (
                <></>
            );
        },
        [isOpenAtNewPage]
    );

    return (
        <Markdown
            options={{
                overrides: {
                    a: {
                        component: getThemeLink,
                    },
                },
            }}
        >
            {text}
        </Markdown>
    );
};

export default MarkdownText;
