import { generatePath } from "react-router";

import { AccountType } from "shared/constants/enums";
import routeNames from "shared/routes/constants/routeNames";

export const getLinkToLandlord = (currentUserType: AccountType, landlordId: number) => {
    if (currentUserType === AccountType.Agent) {
        return generatePath(routeNames.LANDLORD.ROOT, { id: landlordId });
    }

    if (currentUserType === AccountType.Admin) {
        return generatePath(routeNames.REDIRECTS.ACCOUNT.ROOT, { id: landlordId });
    }

    return undefined;
};

export const getLinkToProperty = (currentUserType: AccountType, propertyId: number) => {
    if (currentUserType === AccountType.Tenant) {
        return generatePath(routeNames.TENANT_HOME.PROPERTY);
    }

    return generatePath(routeNames.PROPERTY.ROOT, { id: propertyId });
};

export const getLinkToDeposit = (propertyId: number, tenancyId: number | undefined) => {
    if (!tenancyId) {
        return generatePath(routeNames.PROPERTY.DEPOSIT.EMPTY, {
            id: propertyId,
        });
    }

    return generatePath(routeNames.PROPERTY.DEPOSIT.ROOT, {
        id: propertyId,
        tenancyId,
    });
};

export const getLinkToAgency = (currentUserType: AccountType, agencyId: number) => {
    if (currentUserType === AccountType.Tenant) {
        return;
    }

    return generatePath(routeNames.ADMIN.BUSINESSES.PROFILE.DETAILS, {
        id: agencyId,
    });
};
