import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

import { TxtColor, applicationPalettes } from "shared/theme/defaultTheme";

const NotificationEnvelopeClosed = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path
                d="M16.5 4.5C16.5 3.675 15.825 3 15 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5ZM15 4.5L9 8.25L3 4.5H15ZM15 13.5H3V6L9 9.75L15 6V13.5Z"
                fill={TxtColor.Txt4}
            />
            <circle cx="14.5" cy="3.5" r="3" fill={applicationPalettes.red[500]} stroke="white" />
        </SvgIcon>
    );
};

export default NotificationEnvelopeClosed;
