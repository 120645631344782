import { mapValues } from "lodash";
import { RouteProps, useParams, useRouteMatch } from "react-router-dom";

export const useRouteIdParam = (): number => {
    const { id } = useParams<{ id: string }>();

    return Number(id);
};

type ParamsType<Params, Value> = {
    [K in keyof Params]: Value;
};

export function useRouteIdParams<Params extends ParamsType<Params, number | undefined> = { id: number }>(): Params {
    const result = useParams<ParamsType<Params, string>>();
    return mapValues(result, it => (it ? Number(it) : undefined)) as Params;
}

export function useRouteIdMatchParams<Params extends ParamsType<Params, number | undefined> = { id: number }>(
    path: string | string[] | RouteProps
): Params {
    const result = useRouteMatch<ParamsType<Params, string>>(path);
    if (!result) {
        return {} as Params;
    }

    return mapValues(result.params, it => (it ? Number(it) : undefined)) as Params;
}
