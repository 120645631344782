import React from "react";

import { Button, CircularProgress } from "@material-ui/core";

import { getButtonSpinnerDimensionsBySize } from "shared/helpers";
import { useDoubleClickPreventer } from "shared/hooks/useDoubleClickPreventer";
import theme, { TxtColor, applicationPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { colorFromPalette } from "shared/theme/utils";

import ButtonText from "./ButtonText";
import {
    buttonBorderRadius,
    buttonHeight,
    buttonMaxWidth,
    buttonMinWidth,
    buttonPaddings,
    buttonSizeToTextVariantMapping,
    buttonWidth,
    useButtonProgressStyles,
} from "./buttonsStyles";
import { CommonResizableButtonsProps } from "./commonButtonsProps";

const useStyles = makeSharedStyles({
    root: {
        height: buttonHeight,
        borderRadius: buttonBorderRadius,
        padding: buttonPaddings,
        boxShadow: "none",
        backgroundColor: colorFromPalette(2),
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: buttonWidth,
            minWidth: buttonMinWidth,
            maxWidth: buttonMaxWidth,
        },
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: colorFromPalette(100),
                boxShadow: "none",

                "& $label": {
                    color: colorFromPalette(700),
                },
            },
        },
        "@media (hover: none)": {
            "&:hover": {
                backgroundColor: colorFromPalette(2),
                boxShadow: "none",
            },
        },
        "&:active": {
            backgroundColor: colorFromPalette(200),
            boxShadow: "none",
        },

        "&.Mui-disabled": {
            backgroundColor: colorFromPalette(2),
            boxShadow: "none",

            "& $label": {
                color: colorFromPalette(200),
            },
        },
    },
    contained: {
        color: TxtColor.Txt8,
    },
    focusVisible: {
        border: `3px solid ${applicationPalettes.default[100]}`,
    },
    label: { color: colorFromPalette(500) },
    disabled: {
        color: colorFromPalette(200),
    },
});

export default function TranslucentButton({
    id,
    label,
    palette,
    size = "m",
    startIcon,
    endIcon,
    disabled,
    isLoading,
    className,
    onClick,
    ...rest
}: CommonResizableButtonsProps): JSX.Element {
    const classes = useStyles({ palette: palette || "default", size });
    const progressStyles = useButtonProgressStyles(getButtonSpinnerDimensionsBySize(size));
    const variant = size && buttonSizeToTextVariantMapping[size];
    const [isClickProcessing, onClickHandler] = useDoubleClickPreventer(onClick);

    return (
        <Button
            {...rest}
            id={id}
            variant="contained"
            focusRipple={false}
            startIcon={startIcon}
            endIcon={endIcon}
            disabled={isLoading || disabled || isClickProcessing}
            classes={{ ...classes }}
            className={className}
            onClick={onClickHandler}
        >
            <ButtonText label={label} textVariant={variant} />
            {isLoading && <CircularProgress size="" className={progressStyles.root} />}
        </Button>
    );
}
