import React, { memo, useCallback, useState } from "react";

import { MenuItem as MaterialMenuItem, Typography } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { generatePath } from "react-router";

import { useHistory } from "shared/hooks/useHistory";
import { useLocalize } from "shared/hooks/useLocalize";
import routeNames from "shared/routes/constants/routeNames";
import Avatar from "uiKit/avatar";
import TextButton from "uiKit/buttons/TextButton";

import { MenuAccountModel } from "app/layout/menu/profile/interfaces";
import { useMenuItemStyles } from "app/layout/menu/profile/menuItem/styles";
import { MenuProfileButtonLocalizationId } from "app/layout/menu/profile/menuProfileButton/localization";

type Props = MenuAccountModel & {
    primaryName?: string;
    secondaryName?: string;
};

const MenuItem: React.FC<Props> = ({
    primaryName,
    secondaryName,
    accountName,
    accountLogo,
    accountTypeText,
    accountId,
}: Props): JSX.Element => {
    const styles = useMenuItemStyles();
    const localize = useLocalize();
    const { historyPush } = useHistory();
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const onSwitch = useCallback(async () => {
        historyPush(generatePath(routeNames.LOGOUT.SWITCH, { accountId }));
    }, [accountId, historyPush]);

    return (
        <MaterialMenuItem
            className={styles.item}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            disableRipple
        >
            {hovered && (
                <div className={styles.switchButtonContainer}>
                    <TextButton
                        onClick={onSwitch}
                        startIcon={<AutorenewIcon />}
                        className={styles.switch}
                        label={localize(MenuProfileButtonLocalizationId.SwitchButton)}
                    />
                </div>
            )}
            <Avatar
                noMargin
                size="xs"
                variant="body2"
                primaryName={primaryName || ""}
                secondaryName={secondaryName || ""}
                src={accountLogo?.downloadUrl}
            />
            <div className={styles.info}>
                <Typography className={clsx(styles.name, styles.text)}>{accountName}</Typography>
                <Typography variant="caption" className={clsx(styles.account, styles.text)}>
                    {accountTypeText}
                </Typography>
            </div>
        </MaterialMenuItem>
    );
};

export default memo(observer(MenuItem));
