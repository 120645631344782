import React from "react";

import { Typography } from "@material-ui/core";

import { useRequestContext } from "shared/api/RequestProvider";
import { useLocalize } from "shared/hooks/useLocalize";
import { LeavePagePromptProps } from "shared/interfaces/container/dialog/leavePagePrompt";
import LeavePagePrompt from "uiKit/container/dialog/LeavePagePrompt";
import { LocalizationId } from "uiKit/container/dialog/formLeavePagePrompt.localization";

type DefaultLeavePagePromptProps = Pick<LeavePagePromptProps, "ignoreLoading" | "isDirty">;

const DefaultLeavePagePrompt = ({ isDirty, ignoreLoading }: DefaultLeavePagePromptProps): JSX.Element => {
    const localize = useLocalize();
    const { abortUploads } = useRequestContext();

    return (
        <LeavePagePrompt
            isDirty={isDirty}
            title={localize(LocalizationId.PromptTitle)}
            primaryButtonText={localize(LocalizationId.PromptConfirmButton)}
            additionalButtonText={localize(LocalizationId.PromptCancelButton)}
            ignoreLoading={ignoreLoading}
            onPrimaryButtonClick={() => {
                if (ignoreLoading) {
                    abortUploads();
                }
            }}
        >
            <Typography variant="body1">{localize(LocalizationId.PromptBody)}</Typography>
        </LeavePagePrompt>
    );
};

export default DefaultLeavePagePrompt;
