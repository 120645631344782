import React from "react";

import { DialogProps } from "shared/interfaces/container/dialog/dialog";
import Dialog from "uiKit/container/dialog/Dialog";

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const EMPTY_FUNC = () => void 0;
const DialogContext = React.createContext<ProviderContext>([EMPTY_FUNC, EMPTY_FUNC]);
export const useDialog = (): ProviderContext => React.useContext(DialogContext);

type DialogOption = Omit<DialogProps, "isOpened" | "handleClose">;

export default function DialogProvider({ children }: { children: React.ReactNode }): JSX.Element {
    const [dialogs, setDialogs] = React.useState<DialogProps[]>([]);
    const createDialog = (option: DialogOption) => {
        const dialog = { ...option, isOpened: true };
        setDialogs(it => [...it, dialog]);
    };
    const closeDialog = () => {
        setDialogs(it => {
            const latestDialog = it.pop();
            if (!latestDialog) {
                return dialogs;
            }
            if (latestDialog.handleClose) {
                latestDialog.handleClose();
            }
            return [...dialogs].concat({ ...latestDialog, isOpened: false });
        });
    };
    const contextValue = React.useRef([createDialog, closeDialog] as const);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}
            {dialogs.map((dialog, index) => {
                const { ...dialogParams } = dialog;

                // We dont have here any other uniq id
                // eslint-disable-next-line react/no-array-index-key
                return <Dialog key={index} handleClose={closeDialog} {...dialogParams} />;
            })}
        </DialogContext.Provider>
    );
}
