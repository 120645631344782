import { ButtonSize } from "uiKit/buttons/commonButtonsProps";

import {
    ApplicationColorPalette,
    Color,
    ShadowsPaletteKey,
    applicationPalettes,
    applicationShadowPalettes,
} from "./defaultTheme";

export interface StylesProps {
    palette: ApplicationColorPalette;
    size?: ButtonSize;
}

export function colorFromPalette(color: keyof Color = 500): (props: StylesProps) => string | undefined {
    return props => applicationPalettes[props.palette][color];
}

export function shadowFromPalette(shadow: ShadowsPaletteKey): (props: StylesProps) => string | undefined {
    return props => applicationShadowPalettes[props.palette][shadow];
}
