import React, { useCallback, useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FieldError, FieldErrors } from "react-hook-form/dist/types/errors";

import { useLocalize } from "shared/hooks/useLocalize";
import { FormProps } from "shared/interfaces/container/form/form";
import { FormLocalizationId } from "uiKit/container/form/localization";
import { showErrorToast } from "uiKit/container/toast/helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFieldError(error: any): error is FieldError {
    return "message" in error && "type" in error && "ref" in error;
}

function flattenErrors<TFieldValues extends FieldValues>(obj: FieldErrors<TFieldValues>): FieldError[] {
    const result: FieldError[] = [];
    Object.values(obj).forEach(it => {
        if (isFieldError(it)) {
            result.push(it);
        } else {
            result.push(...flattenErrors(it));
        }
    });

    return result;
}

function isInViewport(element: HTMLElement) {
    if (!element) {
        return false;
    }

    const { left, top, width, height } = element.getBoundingClientRect();
    const centerX = left + width / 2;
    const centerY = top + height / 2;
    const currentPointerEventsStyle = element.style.pointerEvents;
    element.style.pointerEvents = "auto";
    const isVisible = document.elementFromPoint(centerX, centerY) === element;
    element.style.pointerEvents = currentPointerEventsStyle;

    return isVisible;
}

export default function Form<TValue extends FieldValues>({
    children,
    schema,
    defaultValues,
    onHandleSubmit,
    className,
    mode,
    shouldUnregister,
    submitOnEnter = false,
}: FormProps<TValue>): JSX.Element {
    const localize = useLocalize();
    const methods = useForm<TValue>({
        resolver: yupResolver(schema, {}, { rawValues: true }),
        defaultValues,
        reValidateMode: "onBlur",
        mode: mode || "onSubmit",
        shouldUnregister,
    });

    const { handleSubmit } = methods;
    const onKeyDown = useMemo(() => {
        if (submitOnEnter) {
            return undefined;
        }
        return (event: React.KeyboardEvent) => {
            if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
                event.preventDefault();
            }
        };
    }, [submitOnEnter]);

    const onHandleWrappedSubmit: SubmitHandler<TValue> = useCallback(
        (data, e) => onHandleSubmit(data, e, methods),
        [methods, onHandleSubmit]
    );

    useEffect(() => {
        const flatErrors = flattenErrors(methods.formState.errors);
        const filteredErrors = flatErrors
            .map(it => (it.ref ? document.getElementsByName(it.ref.name)[0] : undefined))
            .filter(it => !!it);

        const isAllErrorsNotVisible = filteredErrors.length
            ? filteredErrors.every(it => (it ? !isInViewport(it) : false))
            : false;

        if (isAllErrorsNotVisible) {
            showErrorToast({ title: localize(FormLocalizationId.ThereAreValidationErrors) });
        }
    }, [localize, methods.formState.errors]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onHandleWrappedSubmit)} className={className} onKeyDown={onKeyDown}>
                {children}
            </form>
        </FormProvider>
    );
}
