import React from "react";

import { Typography } from "@material-ui/core";

import { BadgeProps } from "shared/interfaces/badge/badge";
import { useBadgeStyles } from "uiKit/labels/badge/badge.styles";

const Badge = ({ label, className, typographyVariant = "body2" }: BadgeProps): JSX.Element => {
    const badgeClasses = useBadgeStyles();

    return (
        <Typography className={`${badgeClasses.root} ${className}`} variant={typographyVariant}>
            {label}
        </Typography>
    );
};

export default Badge;
