import React from "react";

import { Grid, Paper } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import Footer from "components/footer";
import { LocalizedComponentName } from "localization/enums";
import { withLocalizations } from "localization/withLocalization";

import MenuHeader from "app/layout/menu/header";
import MenuItems from "app/layout/menu/items";
import MenuProfile from "app/layout/menu/profile";
import { useSidePanelStyles } from "app/layout/sidePanel/styles";
import { useRootStore } from "app/useRootStore";

const SidePanel = (): JSX.Element => {
    const {
        rootStore: {
            menuStore: { menuItems },
        },
    } = useRootStore();
    const classes = useSidePanelStyles();

    return (
        <Paper className={classes.fullHeight}>
            <Grid container className={classes.fullHeight} direction="column" wrap="nowrap">
                <Grid item>
                    <MenuHeader />
                </Grid>
                <Grid className={classes.hiddenOnMobile} item>
                    <MenuProfile />
                </Grid>
                <Grid item className={classes.navMenu}>
                    <MenuItems menuItems={menuItems} />
                </Grid>
                <Grid item>
                    <Footer />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default withLocalizations(observer(SidePanel), [
    LocalizedComponentName.NavMenu,
    LocalizedComponentName.NotificationsModal,
]);
