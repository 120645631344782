/**
 * Summary: Mark all notifications for the authorized user as read.
 *
 * Tags: Notifications
 */
import { AxiosRequestConfig } from "axios";

import { httpPut } from "shared/api/apiHandler";

export const API_PUT_NOTIFICATIONS_MARK_ALL_AS_READ_URL = "api/v1/notifications/mark-all-as-read";

export const apiPutNotificationsMarkAllAsRead = async (
    config?: AxiosRequestConfig
): Promise<ApiPutNotificationsMarkAllAsReadResponse> => {
    const result = await httpPut<ApiPutNotificationsMarkAllAsReadResponse>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_PUT_NOTIFICATIONS_MARK_ALL_AS_READ_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiPutNotificationsMarkAllAsReadResponse = void;
