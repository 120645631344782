import { round } from "lodash";
import prettyBytes from "pretty-bytes";

import { EN_LANG } from "localization/constants";

const formatMoneyDefaultLocales = EN_LANG;
const formatMoneyDefaultSettings = {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};
const DECIMAL_PLACES_AMOUNT = 2;

export const formatMoney = (money: number): string => {
    const formatter = new Intl.NumberFormat(formatMoneyDefaultLocales, formatMoneyDefaultSettings);

    return formatter.format(money);
};

export const formatMoneyWithoutCents = (money: number): string => {
    const formatter = new Intl.NumberFormat(formatMoneyDefaultLocales, {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formatter.format(money);
};

export const formatNumberWithThousandSeparator = (number: number) => {
    const formatter = new Intl.NumberFormat(formatMoneyDefaultLocales);

    return formatter.format(number);
};

export const formatPercent = (number: number) => {
    return formatPercentNumber(number / 100);
};

export const formatPercentNumber = (number: number) => {
    const formatter = new Intl.NumberFormat(formatMoneyDefaultLocales, {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    return formatter.format(number);
};

export const BYTES_IN_KB = 1024;

export const prettyBytesFromKb = (sizeKb?: number | string): string => {
    return prettyBytes(Number(sizeKb ?? 0) * BYTES_IN_KB);
};

export const roundDecimalNumber = (number: number): number => round(number, DECIMAL_PLACES_AMOUNT);

export function formatMinTwoDigitNumber(number: number) {
    const formatter = new Intl.NumberFormat(EN_LANG, {
        minimumIntegerDigits: 2,
    });

    return formatter.format(number);
}
