import React, { ReactNode, useState } from "react";

import { CssBaseline, StylesProvider, createGenerateClassName } from "@material-ui/core";
import { Theme, ThemeProvider } from "@material-ui/core/styles";

import CustomIntlProvider from "localization/CustomIntlProvider";
import { LocalizedComponentName } from "localization/enums";
import { withLocalizations } from "localization/withLocalization";
import { RequestContextProvider } from "shared/api/RequestProvider";
import DialogProvider from "shared/providers/DialogProvider";
import IntercomContextProvider from "shared/providers/intercomContextProvider";
import theme from "shared/theme/defaultTheme";
import ToastContainer from "uiKit/container/toast/ToastContainer";

import { ApplicationThemeContextProvider } from "app/layout/ApplicationThemeProvider";

const LocalizedRequestContextProvider = withLocalizations(
    RequestContextProvider,
    [
        LocalizedComponentName.AccountTypes,
        LocalizedComponentName.AddressForm,
        LocalizedComponentName.CommonDataGrid,
        LocalizedComponentName.Notifications,
        LocalizedComponentName.SharedMessages,
        LocalizedComponentName.ValidationMessages,
    ],
    false,
    true
);

const generateClassName = createGenerateClassName();

export const AppProviders = ({ children }: { children?: ReactNode }): JSX.Element => {
    const [childTheme, setChildTheme] = useState<Theme | undefined>(undefined);

    return (
        <IntercomContextProvider>
            <CustomIntlProvider>
                <StylesProvider generateClassName={generateClassName}>
                    <ApplicationThemeContextProvider value={{ theme, childTheme, setChildTheme }}>
                        <ThemeProvider theme={childTheme || theme}>
                            <LocalizedRequestContextProvider>
                                <CssBaseline />
                                <DialogProvider>{children}</DialogProvider>
                                <ToastContainer />
                            </LocalizedRequestContextProvider>
                        </ThemeProvider>
                    </ApplicationThemeContextProvider>
                </StylesProvider>
            </CustomIntlProvider>
        </IntercomContextProvider>
    );
};
