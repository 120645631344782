import semver from "semver";

import { APP_VERSION, OAUTH_TOKEN } from "shared/constants/storageKeys";

const dropStorageOn = ["major", "minor"];

const initializeLocalStorage = async (currentAppVersion: string) => {
    try {
        if (!semver.valid(currentAppVersion)) {
            return;
        }

        const storedVersion = localStorage.getItem(APP_VERSION);
        if (!storedVersion) {
            await dropLocalStorage(currentAppVersion);
            return;
        }

        const diff = semver.diff(storedVersion, currentAppVersion);
        if (diff) {
            if (dropStorageOn.includes(diff)) {
                await dropLocalStorage(currentAppVersion);
            } else {
                localStorage.setItem(APP_VERSION, currentAppVersion);
            }
        }
    } catch (e) {
        console.error("Failed to initialize local storage", e);
    }
};

const dropLocalStorage = async (currentAppVersion: string) => {
    const oauthToken = localStorage.getItem(OAUTH_TOKEN);

    localStorage.clear();
    localStorage.setItem(APP_VERSION, currentAppVersion);

    if (oauthToken) {
        localStorage.setItem(OAUTH_TOKEN, oauthToken);
    }
};

export default initializeLocalStorage;
