import { scrollStyles } from "shared/styles";
import { appAdditionalShadow, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useSidePanelStyles = makeSharedStyles(theme => ({
    fullHeight: {
        height: "100%",
        // We need it to correct render shadow on flex elements
        position: "relative",
        zIndex: 1,
        boxShadow: appAdditionalShadow.bg2["24"],
        [theme.breakpoints.down(tabletBreakpoint)]: {
            boxShadow: "none",
        },
        ...scrollStyles,
    },

    navMenu: {
        flex: "1 0 auto",
    },

    hiddenOnMobile: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            display: "none",
        },
    },
}));
