import { DialogSizeControl, DialogThinControl } from "shared/interfaces/container/dialog/dialog";
import { scrollStyles } from "shared/styles";
import {
    andLandscapeOrientation,
    applicationPalettes,
    applicationShadowPalettes,
    mediumBreakpoint,
    mobileBreakpoint,
    tabletBreakpoint,
    whiteColor,
} from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useBackdropStyles = makeSharedStyles(() => ({
    backdrop: {
        backgroundColor: applicationPalettes.primary[800],
        opacity: "0.4 !important",
    },
}));

export const useDialogWideStyles = makeSharedStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.up(tabletBreakpoint)]: {
            margin: theme.spacing(0, 5.5),
        },
        [theme.breakpoints.up(1605 + theme.spacing(11))]: {
            maxWidth: 1605,
            margin: "0 auto",
        },
    },
    paperScrollPaper: {
        minHeight: "calc(100% - 96px)",
        [theme.breakpoints.up(mediumBreakpoint)]: {
            maxHeight: "calc(100% - 72px)",
            minHeight: "auto",
        },
    },

    content: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },

    dialogContent: {
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: (props: DialogThinControl) => (props.thin ? 0 : theme.spacing(0, 2, 0, 2)),
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            maxHeight: "none",
        },

        ...scrollStyles,
        maxHeight: "calc(100vh - 300px)",
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
        wordBreak: "break-word",
        padding: (props: DialogThinControl) => (props.thin ? 0 : theme.spacing(0, 3, 0, 4)),

        "&:first-child": {
            paddingTop: 0,
        },
    },
}));

export const useDialogStyles = makeSharedStyles(theme => ({
    paper: {
        flex: "1 1 auto",
        maxWidth: (props: DialogSizeControl) => props.maxWidth,
        boxShadow: applicationShadowPalettes.primary[4],
        borderRadius: theme.spacing(1, 1, 0, 0),
        margin: "auto 0 0 0",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            margin: 0,
            top: 80,
            borderRadius: theme.spacing(0.5),
        },
        [`${theme.breakpoints.down(tabletBreakpoint)} ${andLandscapeOrientation}`]: {
            margin: "auto 0 0 0",
            top: 0,
        },
    },
    content: {},
    scrollPaper: {
        alignItems: "flex-start",
        position: "relative",
    },
    paperScrollPaper: {
        maxHeight: "calc(100% - 16px)",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            maxHeight: "calc(100% - 72px)",
        },
    },
    startButtons: {
        justifyContent: "start",
    },
    optionalButtonBox: {
        marginRight: "auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginRight: 0,
            margin: theme.spacing(1.5, 0, 1, 0),
        },
    },
}));

export const useDialogTitleStyles = makeSharedStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(3, 3, 2, 4),
        position: "sticky",
        top: 0,
        background: whiteColor,
        zIndex: 1,
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: theme.spacing(2, 2, 2, 2),
            borderRadius: theme.spacing(1, 1, 0, 0),
        },
    },
}));

export const useDialogContentStyles = makeSharedStyles(theme => ({
    root: {
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: (props: DialogThinControl) => (props.thin ? 0 : theme.spacing(0, 2, 0, 2)),
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            maxHeight: "none",
        },
        ...scrollStyles,
        maxHeight: "calc(100vh - 300px)",
        flex: "1 0 auto",
        wordBreak: "break-word",
        padding: (props: DialogThinControl) => (props.thin ? 0 : theme.spacing(0, 3, 0, 4)),
        "&:first-child": {
            paddingTop: 0,
        },
    },
}));

export const useDialogActionsStyles = makeSharedStyles(theme => ({
    root: {
        padding: theme.spacing(0, 3, 3, 4),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: theme.spacing(0, 2, 3),
            flexDirection: "column",
            alignItems: "center",
            "& > :not(:first-child)": {
                marginLeft: 0,
            },
        },
    },
}));

export const useDialogButtonsStyles = makeSharedStyles(theme => ({
    primary: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up(tabletBreakpoint)]: {
            marginTop: 0,
        },
    },
    additional: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up(tabletBreakpoint)]: {
            marginTop: 0,
        },
    },
    optional: {
        "& svg": {
            fontSize: "24px !important",
        },
    },
}));

export const useTitleStyles = makeSharedStyles(theme => ({
    text: {
        marginRight: theme.spacing(5),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(2),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            top: theme.spacing(1.5),
        },
    },
}));

export const useDividerStyles = makeSharedStyles(theme => ({
    root: {
        height: "1px",
        backgroundColor: applicationPalettes.default[50],
        margin: theme.spacing(4, 0, 3),
        [theme.breakpoints.down(mobileBreakpoint)]: {
            margin: theme.spacing(4, 0, 3, 0),
        },
    },
}));
