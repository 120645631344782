import React, { useCallback, useMemo } from "react";

import { ListItemIcon, MenuItem as MaterialMenuItem, MenuList, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { MenuItem } from "app/layout/interfaces";
import { useListItemIconClasses, useMenuItemClasses, useMenuItemsStyles } from "app/layout/menu/items/styles";
import { useRootStore } from "app/useRootStore";

interface MenuItemsProps {
    menuItems: MenuItem[];
    isPopup?: boolean;
}

const MenuItems: React.FC<MenuItemsProps> = ({ menuItems, isPopup }: MenuItemsProps): JSX.Element => {
    const styles = useMenuItemsStyles({ isPopup });
    const menuItemClasses = useMenuItemClasses({ isPopup });
    const listItemIconClasses = useListItemIconClasses({ isPopup });

    const { pathname } = useLocation();

    const {
        rootStore: {
            layoutStore: { closeMenu },
            menuStore: { isSelectedMenuItem },
        },
    } = useRootStore();

    const handleMenuItemClick = useCallback(
        ({ onClick }: MenuItem) => {
            if (onClick) {
                onClick();
            }

            closeMenu();
        },
        [closeMenu]
    );

    const menuItemVariant = isPopup ? "body2" : "subtitle2";

    const menuItemRender = useCallback<(menuItem: MenuItem, index: number) => React.ReactElement>(
        menuItem => {
            const key = menuItem.sortOrder || menuItem.name;
            const isSelected = isSelectedMenuItem(menuItem, pathname);

            if (!menuItem.route && !menuItem.onClick) {
                return (
                    <div key={key} className={styles.separator}>
                        <Typography variant="overline" noWrap color="inherit">
                            {menuItem.name}
                        </Typography>
                    </div>
                );
            }

            const icon = (menuItem.iconElement || menuItem.iconLink) && (
                <ListItemIcon classes={{ ...listItemIconClasses }}>
                    {menuItem.iconElement ||
                        (menuItem.iconLink && (
                            <img className={styles.icon} src={menuItem.iconLink} alt={menuItem.name} />
                        ))}
                </ListItemIcon>
            );

            const materialMenuItem = (
                <MaterialMenuItem
                    key={key}
                    selected={isSelected}
                    onClick={() => handleMenuItemClick(menuItem)}
                    classes={{ ...menuItemClasses }}
                    data-testid={menuItem.testId}
                    disableRipple
                >
                    {icon}
                    <Typography variant={menuItemVariant} className={styles.itemName} noWrap>
                        {menuItem.name}
                    </Typography>
                </MaterialMenuItem>
            );

            return menuItem.route ? (
                <Link key={key} className={styles.link} to={menuItem.route}>
                    {materialMenuItem}
                </Link>
            ) : (
                materialMenuItem
            );
        },
        [
            isSelectedMenuItem,
            pathname,
            listItemIconClasses,
            styles.icon,
            styles.link,
            styles.itemName,
            styles.separator,
            menuItemClasses,
            menuItemVariant,
            handleMenuItemClick,
        ]
    );

    const menuItemsRendered = useMemo(() => menuItems.map(menuItemRender), [menuItemRender, menuItems]);

    return <MenuList className={styles.root}>{menuItemsRendered}</MenuList>;
};

export default observer(MenuItems);
