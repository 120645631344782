import React, { forwardRef, memo, useCallback } from "react";

import { Grid, Typography } from "@material-ui/core";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";

import { useNotificationItemStyles } from "components/notifications/notificationItem/styles";
import { LocalizationId } from "components/notifications/notificationsButton/localization";
import { useRequestContext } from "shared/api/RequestProvider";
import { DELETE_ALL_NOTIFICATIONS, DELETE_NOTIFICATION } from "shared/constants/requestActionConstants";
import { useLocalize } from "shared/hooks/useLocalize";
import { UserNotification } from "shared/interfaces/notifications";
import { formatDate } from "shared/utils/date.utils";
import MarkdownText from "uiKit/MarkdownText";
import IconButton from "uiKit/buttons/IconButton";
import Tooltip from "uiKit/container/tooltip";
import NotificationEnvelopeClosed from "uiKit/icons/NotificationEnvelopeClosed";
import NotificationEnvelopeOpened from "uiKit/icons/NotificationEnvelopeOpened";

interface NotificationItemProps {
    notification: UserNotification;
    onDeleteNotification: (appNotificationId: number) => Promise<void>;
}

const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>(
    ({ notification, onDeleteNotification }: NotificationItemProps, ref) => {
        const notificationItemClasses = useNotificationItemStyles();
        const localize = useLocalize();
        const { executeRequest, isLoading, actionName } = useRequestContext();
        const { isRead, appNotificationId, createdDateRelativeToNow, createdDate, text } = notification;

        const handleDeleteNotification = useCallback(async () => {
            await executeRequest(onDeleteNotification, DELETE_NOTIFICATION(appNotificationId), [appNotificationId]);
        }, [executeRequest, onDeleteNotification, appNotificationId]);

        const isDeleteActionDisabled =
            isLoading &&
            (actionName === DELETE_NOTIFICATION(appNotificationId) || actionName === DELETE_ALL_NOTIFICATIONS);

        return (
            <div>
                <Grid container direction="row" className={notificationItemClasses.root} ref={ref}>
                    <Grid container item>
                        <Grid container item>
                            <div className={notificationItemClasses.icon}>
                                {isRead ? <NotificationEnvelopeOpened /> : <NotificationEnvelopeClosed />}
                            </div>
                            <Typography variant="caption">
                                {createdDateRelativeToNow || formatDate(createdDate)}
                            </Typography>
                        </Grid>
                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between">
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    className={notificationItemClasses.description}
                                    component="div"
                                >
                                    {text ? <MarkdownText text={text} isOpenAtNewPage /> : null}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title={localize(LocalizationId.RemoveNotificationTooltip)}>
                                    <span>
                                        <IconButton
                                            icon={DeleteOutlineIcon}
                                            variant="primary"
                                            onClick={handleDeleteNotification}
                                            disabled={isDeleteActionDisabled}
                                            noShadow
                                        />
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
);

export default memo(NotificationItem);
