import { TxtColor, applicationPalettes, mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useMobileHeaderStyles = makeSharedStyles(theme => ({
    root: {
        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: theme.spacing(1, 3),
        },

        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: theme.spacing(1, 2),
        },
    },

    search: {
        paddingRight: theme.spacing(2),
    },

    notifyIconContainer: {
        display: "flex",
        flexDirection: "row",
    },

    notifyIcon: {
        color: TxtColor.Txt4,
    },

    menuIconContainer: {
        paddingLeft: 0,
        position: "relative",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            paddingLeft: theme.spacing(4),
        },
    },
}));

export const useMobileBadgeStyles = makeSharedStyles(theme => ({
    notifyIconOnMenu: {
        display: "none",
        width: "5px !important",
        height: "5px !important",
        borderRadius: "50% !important",
        padding: "0 !important",
        top: "10px !important",
        right: "10px !important",
        backgroundColor: `${applicationPalettes.red[500]} !important`,

        [theme.breakpoints.down(mobileBreakpoint)]: {
            display: "initial",
        },
    },
}));
