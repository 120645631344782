import { History } from "history";
import { useHistory as useHistoryReactRouter } from "react-router-dom";

export type HistoryReplace<T = DefaultHistoryState> = History<T>["replace"];
export type HistoryPush<T = DefaultHistoryState> = History<T>["push"];
export type HistoryLocation<T = DefaultHistoryState> = History<T>["location"];

export type DefaultHistoryState = {
    ignoreLeaveConfirmationDialog?: boolean;
};

export const useHistory = <T = DefaultHistoryState>() => {
    const { push, replace, goBack, go, location, length } = useHistoryReactRouter<T>();

    return {
        historyPush: push,
        historyReplace: replace,
        historyGoBack: goBack,
        historyGo: go,
        historyLocation: location,
        historyLength: length,
    };
};
