import React, { ReactNode } from "react";

import { observer } from "mobx-react-lite";
import { IntlProvider } from "react-intl";

import { useRootStore } from "app/useRootStore";

export default observer(function CustomIntlProvider({ children }: { children?: ReactNode }): JSX.Element {
    const {
        rootStore: {
            localizationStore: { locale, messages },
        },
    } = useRootStore();

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
});
