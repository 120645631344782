/**
* Summary: Remove notification for the authorized user.
* Description: Sample request:
            
    DELETE /api/v1/notifications/42
* Tags: Notifications
*/
import { AxiosRequestConfig } from "axios";

import { httpDelete } from "shared/api/apiHandler";

export const API_DELETE_NOTIFICATION_URL = (appNotificationId: number): string =>
    `api/v1/notifications/${appNotificationId}`;

export const apiDeleteNotification = async (
    appNotificationId: number,
    config?: AxiosRequestConfig
): Promise<ApiDeleteNotificationResponse> => {
    const result = await httpDelete<ApiDeleteNotificationResponse>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_DELETE_NOTIFICATION_URL(appNotificationId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiDeleteNotificationResponse = void;
