import React, { useCallback } from "react";

import { observer } from "mobx-react-lite";
import { IntercomProvider } from "react-use-intercom";

import { IntercomStore } from "components/bootstrapIntercom/store";
import { useStore } from "shared/hooks/useStore";

import { useRootStore } from "app/useRootStore";

interface IntercomContextProviderProps {
    children: React.ReactNode;
}

const IntercomContextProvider = ({ children }: IntercomContextProviderProps): JSX.Element => {
    const { rootStore } = useRootStore();
    const {
        store: { setOpen },
    } = useStore(IntercomStore, () => new IntercomStore(rootStore));

    const onShow = useCallback(() => setOpen(true), [setOpen]);
    const onHide = useCallback(() => setOpen(false), [setOpen]);

    if (!window.APP_SETTINGS.intercomClientId) {
        return <>{children}</>;
    }
    return (
        <IntercomProvider appId={window.APP_SETTINGS.intercomClientId} onHide={onHide} onShow={onShow}>
            {children}
        </IntercomProvider>
    );
};

export default observer(IntercomContextProvider);
