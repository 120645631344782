/**
 *
 *
 * Tags: Connect
 */
import { AxiosRequestConfig } from "axios";
import { stringify } from "query-string";

import { httpPost } from "shared/api/apiHandler";

export const POST_CONNECT_TOKEN_URL = "connect/token";

export const postConnectToken = async (
    request: PostConnectTokenRequest,
    config?: AxiosRequestConfig
): Promise<PostConnectTokenResponse> => {
    const result = await httpPost<PostConnectTokenResponse, string>(
        window.APP_SETTINGS.oauth2ApiBaseUri,
        POST_CONNECT_TOKEN_URL,
        stringify(request),
        { ...config, headers: { "Content-Type": "application/x-www-form-urlencoded" } }
    );
    return result.data;
};

/**
 *
 */
export type PostConnectTokenRequest = TokenRequest;

/**
 * Description: OK
 */
export type PostConnectTokenResponse = TokenResponse;

interface TokenRequest extends Record<string, string | undefined> {
    grant_type: string;
    client_id?: string;
    client_secret?: string;
    refresh_token?: string;
    username?: string;
    password?: string;
    code?: string;
    code_verifier?: string;
    subject_token?: string;
    subject_token_type?: string;
    redirect_uri?: string;
}

interface TokenResponse {
    access_token: string;
    expires_in: number;
    token_type: string;
    refresh_token: string;
    id_token: string;
}
