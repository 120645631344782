import React from "react";

import { Tooltip as MaterialTooltip, Typography } from "@material-ui/core";

import { TooltipProps } from "shared/interfaces/container/tooltip/tooltip";
import {
    LEAVE_TOUCH_DELAY_MS,
    LONG_LEAVE_TOUCH_DELAY_MS,
    NO_DELAY_MS,
    TOUCH_DELAY_MS,
} from "uiKit/container/tooltip/constants";
import { useTitleStyles, useTooltipStyles } from "uiKit/container/tooltip/styles";

const Tooltip = ({
    children,
    title,
    titleVariant = "body2",
    arrow = false,
    placement = "bottom",
    disablePortal = false,
    withoutLeaveTouchDelay = false,
    withoutEnterTouchDelay = false,
    disabled = false,
}: TooltipProps): JSX.Element => {
    const titleClasses = useTitleStyles();
    const tooltipClasses = useTooltipStyles();
    return disabled ? (
        children
    ) : (
        <MaterialTooltip
            enterTouchDelay={withoutEnterTouchDelay ? NO_DELAY_MS : TOUCH_DELAY_MS}
            leaveTouchDelay={withoutLeaveTouchDelay ? LONG_LEAVE_TOUCH_DELAY_MS : LEAVE_TOUCH_DELAY_MS}
            PopperProps={{ disablePortal }}
            arrow={arrow}
            classes={{ ...tooltipClasses }}
            title={
                title && (
                    <Typography classes={titleClasses} variant={titleVariant}>
                        {title}
                    </Typography>
                )
            }
            placement={placement}
        >
            {children}
        </MaterialTooltip>
    );
};

export default Tooltip;
