/**
 * Summary: Get single release details.
 *
 * Tags: SingleReleases
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_SINGLE_RELEASE_URL = (singleReleaseId: number): string =>
    `api/v1/single-releases/${singleReleaseId}`;

export const apiGetSingleRelease = async (
    singleReleaseId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetSingleReleaseResponse> => {
    const result = await httpGet<ApiGetSingleReleaseResponse>(
        window.APP_SETTINGS.timeStoneApiBaseUri,
        API_GET_SINGLE_RELEASE_URL(singleReleaseId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetSingleReleaseResponse = GetSingleReleaseDetailsResponse;

interface AlternativeContactDetails {
    email?: string; // Email. Example: perry.wainwright@gmail.com
    phoneNumber?: string; // Phone number. Example: +4402089802089
    landlineNumber?: string; // Landline number. Example: +4416321054796
    address?: string; // Address. Example: 49 Roman Road, London, United Kingdom, E2 0HU
}

interface GetSingleReleaseDetailsResponse {
    releaseRequestId: number; // Release request id. Example: 12
    initiatorId: number; // Initiator user id. Example: 42
    initiatorName?: string; // Initiator name. Example: Alain Delon
    approvedDate?: Date; // Approved date. Example: 2022-11-08T16:26:38.129Z
    daysToRespond?: number; // Count of days before RR will be automatically resolved. Example: 3
    responderName?: string; // Responder name. Example: John Doe
    alternativeContactDetails?: AlternativeContactDetails; // Alternative contact details.
}
