import React, { useEffect, useMemo } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react-lite";
import { useRegisterSW } from "virtual:pwa-register/react";

import BootstrapGoogleAnalytics from "components/bootstrapGoogleAnalytics";
import BootstrapIntercom from "components/bootstrapIntercom";
import configureAppInsights from "shared/helpers/configureAppInsights";
import generateDataTestId from "shared/helpers/generateDataTestId";
import initializeLocalStorage from "shared/helpers/initializeLocalStorage";
import initializeReactGA from "shared/helpers/initializeReactGA";
import ApplicationRouter from "shared/routes/ApplicationRouter";

import { AppProviders } from "app/AppProviders";
import ErrorBoundary from "app/ErrorBoundary";
import { useStyles } from "app/app.styles";
import { RootStore } from "app/rootStore";
import { RootStoreContext } from "app/useRootStore";

const browserHistory = createBrowserHistory();

configureAppInsights();
initializeReactGA(browserHistory);
generateDataTestId();

const rootStore = new RootStore(browserHistory);

// eslint-disable-next-line no-console
console.info(`Version: ${window.APP_VERSION}`);

function App(): JSX.Element {
    const styles = useStyles();
    const { userHomeRoute, routerStore, authStore, appNotificationsStore } = rootStore;
    const { isAuthenticated, requiresMissingDetails } = authStore;
    const { connectSharedWorker } = appNotificationsStore;
    const store = useMemo(() => ({ rootStore }), []);
    useRegisterSW({ immediate: true });

    useEffect(() => {
        return browserHistory.listen((location, action) => routerStore.addLocation(location, action));
    }, [routerStore]);

    useEffect(() => {
        initializeLocalStorage(window.APP_VERSION);
    }, []);

    useEffect(() => {
        if (isAuthenticated && !requiresMissingDetails) {
            connectSharedWorker();
        }
    }, [connectSharedWorker, isAuthenticated, requiresMissingDetails]);

    return (
        <div className={styles.main}>
            <RootStoreContext.Provider value={store}>
                <AppProviders>
                    <ErrorBoundary>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ApplicationRouter homeRoute={userHomeRoute} history={browserHistory} />
                            <BootstrapIntercom />
                            <BootstrapGoogleAnalytics />
                        </MuiPickersUtilsProvider>
                    </ErrorBoundary>
                </AppProviders>
            </RootStoreContext.Provider>
        </div>
    );
}

export default observer(App);
