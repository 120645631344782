import { ComponentType, lazy } from "react";

import { PAGE_HAS_BEEN_FORCE_REFRESHED_KEY } from "shared/constants/appConstants";

const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType<unknown> }>): ComponentType<unknown> => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED_KEY) || "false"
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED_KEY, "false");

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED_KEY, "true");
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
};

export default lazyWithRetry;
