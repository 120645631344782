import React, { useCallback, useEffect } from "react";

import { Badge } from "@material-ui/core";
import { ShoppingBasket } from "@material-ui/icons";
import { observer } from "mobx-react-lite";

import { useBadgeStyles } from "components/notifications/notificationsButton/styles";
import { useHistory } from "shared/hooks/useHistory";
import routeNames from "shared/routes/constants/routeNames";
import IconButton from "uiKit/buttons/IconButton";

import { useRootStore } from "app/useRootStore";

const BasketNotificationsButton = (): JSX.Element | null => {
    const badgeStyles = useBadgeStyles();

    const { historyPush } = useHistory();
    const {
        rootStore: {
            layoutStore: { closeMenu },
            hasCorrectPermissionsToProtectDeposit,
            appNotificationsStore: { shouldCheckBasketItemsCount, basketItemsCount, refreshBasketItemsCount },
        },
    } = useRootStore();

    const onButtonClick = useCallback(() => {
        historyPush(routeNames.DEPOSITS.BASKET);
        closeMenu();
    }, [historyPush, closeMenu]);

    const hasBasketButton = hasCorrectPermissionsToProtectDeposit && shouldCheckBasketItemsCount;

    useEffect(() => {
        refreshBasketItemsCount();
    }, [refreshBasketItemsCount]);

    if (!hasBasketButton) {
        return null;
    }

    return (
        <div>
            <Badge
                id="notification-badge"
                classes={{ ...badgeStyles }}
                badgeContent={basketItemsCount}
                onClick={onButtonClick}
                color="error"
                overlap="rectangular"
            >
                <IconButton noShadow icon={ShoppingBasket} />
            </Badge>
        </div>
    );
};

export default observer(BasketNotificationsButton);
