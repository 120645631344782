import React from "react";

import { Button, CircularProgress } from "@material-ui/core";

import { getButtonSpinnerDimensionsBySize } from "shared/helpers";
import { useDoubleClickPreventer } from "shared/hooks/useDoubleClickPreventer";
import theme, { TxtColor, applicationPalettes, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { colorFromPalette, shadowFromPalette } from "shared/theme/utils";

import ButtonText from "./ButtonText";
import {
    buttonBorderRadius,
    buttonHeight,
    buttonMaxWidth,
    buttonMinWidth,
    buttonPaddings,
    buttonSizeToTextVariantMapping,
    buttonWidth,
    useButtonProgressStyles,
} from "./buttonsStyles";
import { CommonResizableButtonsProps } from "./commonButtonsProps";

const useStyles = makeSharedStyles({
    root: {
        borderRadius: buttonBorderRadius,
        padding: buttonPaddings,
        height: buttonHeight,
        width: "100%",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            width: buttonWidth,
            minWidth: buttonMinWidth,
            maxWidth: buttonMaxWidth,
        },
    },
    contained: {
        color: TxtColor.Txt8,
        boxShadow: shadowFromPalette(4),
        backgroundColor: colorFromPalette(500),

        [theme.breakpoints.up(tabletBreakpoint)]: {
            boxShadow: shadowFromPalette(24),
        },
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: colorFromPalette(600),
                boxShadow: "none",
            },
        },
        "@media (hover: none)": {
            "&:hover": {
                backgroundColor: colorFromPalette(500),
                boxShadow: "none",
            },
        },
        "&:active": {
            backgroundColor: colorFromPalette(500),
            boxShadow: "none",
        },
        "&.Mui-disabled": {
            backgroundColor: colorFromPalette(50),
            boxShadow: "none",
        },
    },
    focusVisible: {
        border: `3px solid ${applicationPalettes.default[600]}`,
    },
    label: {
        color: TxtColor.Txt8,
    },
    disabled: {
        backgroundColor: colorFromPalette(50),
        boxShadow: "none",
    },
});

export default function SolidButton({
    id,
    label,
    palette,
    size = "m",
    startIcon,
    endIcon,
    disabled,
    isLoading,
    href,
    openInNewTab,
    onClick,
    ...rest
}: CommonResizableButtonsProps): JSX.Element {
    const classes = useStyles({ palette: palette || "default", size });
    const progressStyles = useButtonProgressStyles(getButtonSpinnerDimensionsBySize(size));
    const variant = size && buttonSizeToTextVariantMapping[size];
    const additionalProps = href
        ? { href, target: openInNewTab ? "_blank" : "_self", className: rest.className }
        : rest;
    const [isClickProcessing, onClickHandler] = useDoubleClickPreventer(onClick);

    return (
        <Button
            id={id}
            {...additionalProps}
            onClick={onClickHandler}
            disabled={isLoading || disabled || isClickProcessing}
            startIcon={startIcon}
            endIcon={endIcon}
            variant="contained"
            focusRipple={false}
            classes={{ ...classes }}
            disableElevation
        >
            <ButtonText label={label} textVariant={variant} />
            {isLoading && <CircularProgress size="" className={progressStyles.root} />}
        </Button>
    );
}
