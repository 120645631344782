import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Badge, Popover, useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

import {
    useBadgeStyles,
    useFullScreenPopoverClasses,
    useNotificationsButtonStyles,
    usePopoverClasses,
} from "components/notifications/notificationsButton/styles";
import NotificationsContainer from "components/notifications/notificationsContainer";
import { useRequestContext } from "shared/api/RequestProvider";
import { FETCH_NOTIFICATIONS } from "shared/constants/requestActionConstants";
import { mobileBreakpoint } from "shared/theme/defaultTheme";
import IconButton from "uiKit/buttons/IconButton";

import { useRootStore } from "app/useRootStore";

const NotificationsButton = (): JSX.Element => {
    const styles = useNotificationsButtonStyles();
    const badgeStyles = useBadgeStyles();
    const fullScreenPopoverClasses = useFullScreenPopoverClasses();
    const popoverClasses = usePopoverClasses();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [buttonVariant, setButtonVariant] = useState<"default" | "primary">("default");
    const isMobileScreenSize = useMediaQuery<Theme>(theme => theme.breakpoints.down(mobileBreakpoint));
    const { executeRequest, isLoadingAction } = useRequestContext();
    const {
        rootStore: {
            appNotificationsStore: {
                canMoreNotificationsBeFetched,
                notifications,
                notReadNotificationsCount,

                deleteAllNotifications,
                deleteNotification,
                fetchMoreNotifications,
                markAllAsRead,
                refreshNotReadCount,
                fetchRecentNotifications,
            },
        },
    } = useRootStore();

    const openPopover = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
            await executeRequest(fetchRecentNotifications, FETCH_NOTIFICATIONS, []);
        },
        [executeRequest, fetchRecentNotifications]
    );

    const closePopover = useCallback(async () => {
        setButtonVariant("default");
        setAnchorEl(null);
        await markAllAsRead();
    }, [markAllAsRead]);

    useEffect(() => {
        refreshNotReadCount();
    }, [refreshNotReadCount]);

    const open = Boolean(anchorEl);
    const hasMore = useMemo(() => toJS(canMoreNotificationsBeFetched), [canMoreNotificationsBeFetched]);
    const notificationsArray = useMemo(() => toJS(notifications), [notifications]);
    const isLoadingNotifications = isLoadingAction(FETCH_NOTIFICATIONS);

    return (
        <>
            <div onClick={openPopover}>
                <Badge
                    id="notification-badge"
                    classes={{ ...badgeStyles }}
                    badgeContent={notReadNotificationsCount}
                    color="error"
                    overlap="rectangular"
                >
                    <IconButton
                        noShadow
                        onClick={() => setButtonVariant("primary")}
                        variant={buttonVariant}
                        icon={NotificationsIcon}
                    />
                </Badge>
            </div>
            <Popover
                open={open}
                onClose={closePopover}
                anchorEl={anchorEl}
                className={styles.root}
                classes={isMobileScreenSize ? fullScreenPopoverClasses : popoverClasses}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <NotificationsContainer
                    onClose={closePopover}
                    notifications={notificationsArray}
                    onDeleteNotification={deleteNotification}
                    onDeleteNotifications={deleteAllNotifications}
                    onNextPage={fetchMoreNotifications}
                    hasMore={hasMore}
                    isLoading={isLoadingNotifications}
                />
            </Popover>
        </>
    );
};

export default observer(NotificationsButton);
