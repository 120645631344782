/**
 * Summary: Get Terms and Conditions and Privacy Policy links.
 *
 * Tags: Settings
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_SETTINGS_PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS_URL =
    "api/v1/settings/privacy-policy-and-terms-and-conditions";

export const apiGetSettingsPrivacyPolicyAndTermsAndConditions = async (
    config?: AxiosRequestConfig
): Promise<ApiGetSettingsPrivacyPolicyAndTermsAndConditionsResponse> => {
    const result = await httpGet<ApiGetSettingsPrivacyPolicyAndTermsAndConditionsResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_SETTINGS_PRIVACY_POLICY_AND_TERMS_AND_CONDITIONS_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetSettingsPrivacyPolicyAndTermsAndConditionsResponse = TermsConditionsPrivacyPolicyModel;

interface TermsConditionsPrivacyPolicyModel {
    termsConditions?: string; // Terms conditions. Example: https://www.portal.totalproperty.co.uk/terms-and-conditions
    privacyPolicy?: string; // Privacy policy. Example: https://www.portal.totalproperty.co.uk/security-and-privacy
}
