/* istanbul ignore file */
import React from "react";

import toKebabCase from "shared/helpers/toKebabCase";

interface TypeCreateElement {
    render: {
        displayName?: string;
        name: string;
    };
}

const getKey = (childrenCreateElement: React.ReactNode): string => {
    return childrenCreateElement
        ? Object.entries(childrenCreateElement).reduce((acc, node) => {
              const value = node[1];
              if (value) {
                  return value.key ? value.key : "";
              }

              return acc;
          }, "")
        : "";
};

const getName = (typeCreateElement: TypeCreateElement): string => {
    let renderType = "";

    if (typeCreateElement.render.displayName) {
        [, renderType] = typeCreateElement.render.displayName.match(/\(([^()]*)\)/) ?? "";
        renderType = toKebabCase(renderType);
    } else {
        renderType = typeCreateElement.render.name;
    }

    return renderType;
};

export default (): void => {
    if (window.APP_SETTINGS.testIdEnabled) {
        const reactCreateElement = React.createElement;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.createElement = (type: any, props?: any, ...children: React.ReactNode[]): any => {
            const propsCreateElement = props;
            const typeCreateElement = type;

            if (type?.render !== undefined && children?.length > 0 && !propsCreateElement["data-testid"]) {
                let renderKey = "";
                const key = getKey(children[0]);
                const name = getName(typeCreateElement);

                if (key) {
                    renderKey = `-${key}`;
                }

                propsCreateElement["data-testid"] = `${name}${renderKey}`;
            }

            return reactCreateElement(type, propsCreateElement, ...children);
        };
    }
};
