import { Theme } from "@material-ui/core";

import { TxtColor, applicationPalettes, tabletBreakpoint, whiteColor } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

interface MenuItemsStylesProps {
    isPopup?: boolean;
}

export const useMenuItemsStyles = makeSharedStyles<Theme, MenuItemsStylesProps>(theme => ({
    root: {
        minWidth: "160px",

        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: ({ isPopup }) => (isPopup ? theme.spacing(1, 0) : theme.spacing(0)),
        },
        "&:focus": {
            outline: "none",
        },
    },
    iconContainer: {
        padding: theme.spacing(2),
    },
    icon: {
        height: "24px",
        display: "block",
    },
    itemName: {
        color: "inherit",
        paddingLeft: theme.spacing(2),
    },
    separator: {
        backgroundColor: whiteColor,
        padding: theme.spacing(3.5, 0, 1.5, 4),
        color: TxtColor.Txt4,
        "& span": {
            display: "flex",
            width: "117px",
        },
    },
    link: {
        textDecoration: "none",
    },
}));

export const useMenuItemClasses = makeSharedStyles<Theme, MenuItemsStylesProps>(theme => ({
    root: {
        height: ({ isPopup }) => (isPopup ? "48px" : "56px"),
        borderLeft: ({ isPopup }) => (isPopup ? "none" : `4px solid ${TxtColor.Txt8}`),
        padding: ({ isPopup }) => (isPopup ? theme.spacing(0, 2, 0, 0) : theme.spacing(0.75, 2)),
        color: TxtColor.Txt2,
        background: TxtColor.Txt8,
        "&:hover": {
            background: ({ isPopup }) => (isPopup ? applicationPalettes.primary[2] : applicationPalettes.primary[1]),
            color: ({ isPopup }) => (isPopup ? TxtColor.Txt1 : TxtColor.Txt2),
            "& div > svg": {
                color: TxtColor.Txt2,
            },
        },
    },
    selected: {
        color: ({ isPopup }) => (isPopup ? TxtColor.Txt2 : applicationPalettes.primary[500]),
        borderLeft: ({ isPopup }) => (isPopup ? "none" : `4px solid ${applicationPalettes.primary[500]}`),
        background: ({ isPopup }) =>
            isPopup ? `${applicationPalettes.primary[1]} !important` : `${applicationPalettes.primary[2]} !important`,
        "& div > img": {
            filter: "invert(51%) sepia(65%) saturate(5917%) hue-rotate(205deg) brightness(100%) contrast(101%)",
        },
        "& div > svg": { color: TxtColor.Txt3 },
    },
}));

export const useListItemIconClasses = makeSharedStyles<Theme, MenuItemsStylesProps>(theme => ({
    root: {
        padding: ({ isPopup }) => (isPopup ? theme.spacing(1.5, 0, 1.5, 2.37) : theme.spacing(2, 0, 2, 2)),
        marginRight: ({ isPopup }) => (isPopup ? -theme.spacing(0.63) : 0),
    },
}));
