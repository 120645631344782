/**
 * Summary: Returns tenancy and property ids for a particular release request.
 *
 * Tags: ReleaseRequests
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_RELEASE_REQUEST_TENANCY_URL = (releaseRequestId: number): string =>
    `api/v1/release-requests/${releaseRequestId}/tenancy`;

export const apiGetReleaseRequestTenancy = async (
    releaseRequestId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetReleaseRequestTenancyResponse> => {
    const result = await httpGet<ApiGetReleaseRequestTenancyResponse>(
        window.APP_SETTINGS.timeStoneApiBaseUri,
        API_GET_RELEASE_REQUEST_TENANCY_URL(releaseRequestId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetReleaseRequestTenancyResponse = GetReleaseRequestTenancyResponse;

interface GetReleaseRequestTenancyResponse {
    tenancyId: number; // Tenancy Id. Example: 42
    propertyId: number; // Property Id. Example: 42
}
