import React from "react";

import { Theme, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import BackButton from "components/backButton";
import { tabletBreakpoint } from "shared/theme/defaultTheme";

import { useBackButtonContainerStyles } from "app/layout/backButtonContainer/styles";
import { useRootStore } from "app/useRootStore";

interface BackButtonContainerProps {
    isFullWidthLayout?: boolean;
}

const BackButtonContainer: React.FC<BackButtonContainerProps> = ({
    isFullWidthLayout = false,
}: BackButtonContainerProps) => {
    const isStandaloneApp = matchMedia("(display-mode: standalone)").matches;
    const isSmallScreenSize = useMediaQuery<Theme>(theme => theme.breakpoints.down(tabletBreakpoint));
    const {
        rootStore: {
            routerStore: { historyIndex },
        },
    } = useRootStore();

    const isHistoryAvailable =
        window.APP_SETTINGS.isBackButtonForDesktopEnabled || isStandaloneApp || isSmallScreenSize;
    const isVisible = historyIndex > 0 && isHistoryAvailable;
    const backButtonContainerStyles = useBackButtonContainerStyles({ isFullWidthLayout });

    if (!isVisible) {
        return null;
    }

    return (
        <div className={backButtonContainerStyles.container}>
            <BackButton />
        </div>
    );
};

export default observer(BackButtonContainer);
