import React from "react";

import { ToastContainer as BaseToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useToastContainerStyles } from "uiKit/container/toast/styles";

const MAX_TOASTS = 5;

const ToastContainer = (): JSX.Element => {
    const classes = useToastContainerStyles();
    return <BaseToastContainer className={classes.root} limit={MAX_TOASTS} hideProgressBar closeButton={false} />;
};

export default ToastContainer;
