import { useEffect } from "react";

import { observer } from "mobx-react-lite";

import { useRootStore } from "app/useRootStore";

const BootstrapGoogleAnalytics = (): null => {
    const {
        rootStore: {
            authStore: { isAuthenticated, userId },
        },
    } = useRootStore();
    const { gaTrackingId } = window.APP_SETTINGS;

    useEffect(() => {
        if (isAuthenticated && gaTrackingId) {
            gtag("set", { user_id: userId });
        }
    }, [gaTrackingId, isAuthenticated, userId]);

    return null;
};

export default observer(BootstrapGoogleAnalytics);
