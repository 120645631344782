/**
 * Summary: Join to real time hub.
 *
 * Tags: Hubs
 */
import { AxiosRequestConfig } from "axios";

import { httpPost } from "shared/api/apiHandler";

export const API_POST_HUBS_REAL_TIME_JOIN_URL = "api/v1/hubs/real-time/join";

export const apiPostHubsRealTimeJoin = async (
    request?: ApiPostHubsRealTimeJoinRequest,
    config?: AxiosRequestConfig
): Promise<ApiPostHubsRealTimeJoinResponse> => {
    const result = await httpPost<ApiPostHubsRealTimeJoinResponse, ApiPostHubsRealTimeJoinRequest>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_POST_HUBS_REAL_TIME_JOIN_URL,
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export type ApiPostHubsRealTimeJoinRequest = JoinToRealTimeHubRequest;

/**
 * Description: Success
 */
export type ApiPostHubsRealTimeJoinResponse = void;

interface JoinToRealTimeHubRequest {
    connectionId?: string; // Connection Id to hub. Example: 123
}
