/**
 * Summary: Get property landlord details.
 *
 * Tags: Properties
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_PROPERTY_LANDLORD_URL = (propertyId: number): string => `api/v1/properties/${propertyId}/landlord`;

export const apiGetPropertyLandlord = async (
    propertyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetPropertyLandlordResponse> => {
    const result = await httpGet<ApiGetPropertyLandlordResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_PROPERTY_LANDLORD_URL(propertyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetPropertyLandlordResponse = PropertyLandlordDetailsModel;

interface PropertyLandlordDetailsModel {
    id: number; // Landlord ID.
    name?: string; // Name or company name.
    email?: string; // Email.
    phone?: string; // Phone.
}
