/**
 * Summary: Delete checkout session for basket items.
 *
 * Tags: Checkout
 */
import { AxiosRequestConfig } from "axios";

import { httpDelete } from "shared/api/apiHandler";

export const API_DELETE_CHECKOUT_URL = "api/v1/checkout";

export const apiDeleteCheckout = async (config?: AxiosRequestConfig): Promise<ApiDeleteCheckoutResponse> => {
    const result = await httpDelete<ApiDeleteCheckoutResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_DELETE_CHECKOUT_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiDeleteCheckoutResponse = void;
