import React from "react";

import { TypeOptions, toast } from "react-toastify";

import { ToastContentProps } from "shared/interfaces/container/toast/toast";
import ToastContent from "uiKit/container/toast/ToastContent";

export const showSuccessToast = ({ title = "", message = "", showCloseIcon }: Partial<ToastContentProps>): void => {
    if (!title && !message) {
        return;
    }
    showToast(title, message, "success", showCloseIcon);
};
export const showErrorToast = ({ title = "", message = "", showCloseIcon }: Partial<ToastContentProps>): void => {
    if (!title && !message) {
        return;
    }
    showToast(title, message, "error", showCloseIcon);
};

export const showInfoToast = ({ title = "", message = "", showCloseIcon }: Partial<ToastContentProps>): void => {
    if (!title && !message) {
        return;
    }
    showToast(title, message, "info", showCloseIcon);
};

const showToast = (title: React.ReactNode, message: React.ReactNode, type: TypeOptions, showCloseIcon = true): void => {
    toast(props => (
        <ToastContent
            closeToast={props.closeToast}
            title={title}
            message={message}
            toastProps={{ ...props.toastProps, type }}
            showCloseIcon={showCloseIcon}
        />
    ));
};
