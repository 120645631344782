import React from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

const LogOut = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon {...props} viewBox="0 0 18 18">
            <path d="M14 5.51758L12.59 6.92758L14.17 8.51758H6V10.5176H14.17L12.59 12.0976L14 13.5176L18 9.51758L14 5.51758ZM2 2.51758H9V0.517578H2C0.9 0.517578 0 1.41758 0 2.51758V16.5176C0 17.6176 0.9 18.5176 2 18.5176H9V16.5176H2V2.51758Z" />
        </SvgIcon>
    );
};

export default LogOut;
