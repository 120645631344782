import React, { useCallback, useEffect } from "react";

import { generatePath } from "react-router";

import { FullscreenLoader } from "components/fullscreenLoader";
import { useRequestContext } from "shared/api/RequestProvider";
import { apiGetReleaseRequestTenancy } from "shared/api/swagger/timeStone/releaseRequests/apiGetReleaseRequestTenancy";
import { FETCH_RELEASE_REQUEST_DETAILS_FOR_LINK } from "shared/constants/requestActionConstants";
import { useHistory } from "shared/hooks/useHistory";
import { useRouteIdParam } from "shared/hooks/useRouteIdParam";
import routeNames from "shared/routes/constants/routeNames";

const ReleaseRequestRouter = (): JSX.Element => {
    const { historyReplace } = useHistory();

    const { executeRequest, isLoading } = useRequestContext();

    const releaseRequestId = useRouteIdParam();

    const redirectToPropertyReleaseRequest = useCallback(async () => {
        const response = await executeRequest(apiGetReleaseRequestTenancy, FETCH_RELEASE_REQUEST_DETAILS_FOR_LINK, [
            Number(releaseRequestId),
        ]);

        historyReplace(
            generatePath(routeNames.PROPERTY.RELEASE_REQUEST.ROOT, {
                id: response.propertyId,
                tenancyId: response.tenancyId,
                releaseRequestId,
            })
        );
    }, [executeRequest, historyReplace, releaseRequestId]);

    useEffect(() => {
        redirectToPropertyReleaseRequest();
    }, [redirectToPropertyReleaseRequest]);

    if (isLoading) {
        return <FullscreenLoader />;
    }

    return <></>;
};

export default ReleaseRequestRouter;
