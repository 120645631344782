import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";

import Avatar from "uiKit/avatar";

import MenuProfileButton from "app/layout/menu/profile/menuProfileButton";
import { useMenuProfileStyles } from "app/layout/menu/profile/styles";
import { useRootStore } from "app/useRootStore";

const MenuProfile: React.FC = (): JSX.Element => {
    const {
        rootStore: {
            menuStore: { avatarUrl, firstName, lastName, currentAccountTypeLabel },
            hasCorrectPermissionsToProtectDeposit,
        },
    } = useRootStore();

    const styles = useMenuProfileStyles({ palette: "default", hasCorrectPermissionsToProtectDeposit });

    return (
        <Grid container wrap="nowrap" className={styles.root}>
            <Grid item>
                <Avatar src={avatarUrl} primaryName={firstName} secondaryName={lastName} />
            </Grid>
            <Grid item container direction="column" justifyContent="center" className={styles.profileContainer}>
                <Grid item container wrap="nowrap" alignItems="center" className={styles.subMenuIconContainer}>
                    <Typography variant="h4" className={styles.heading}>
                        {firstName}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={styles.roleOrAccountTypeTypography} variant="caption">
                        {currentAccountTypeLabel}
                    </Typography>
                </Grid>
            </Grid>
            <MenuProfileButton />
        </Grid>
    );
};

export default observer(MenuProfile);
