import React from "react";

import { Typography, TypographyVariant } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles/withStyles";
import clsx from "clsx";

import { useContentStyles } from "uiKit/container/note/Note.styles";

interface Props {
    title?: React.ReactNode;
    text?: React.ReactNode;
    titleClassName?: string;
    titleVariant?: Exclude<TypographyVariant, "button">;
    textVariant?: Exclude<TypographyVariant, "button">;
    classes?: ClassNameMap;
}

const NoteText = ({
    title,
    titleClassName,
    text,
    titleVariant = "subtitle2",
    textVariant = "body2",
    classes,
}: Props): JSX.Element => {
    const contentStyles = { ...useContentStyles(), ...classes };

    return (
        <div className={contentStyles.root}>
            {!!title && (
                <Typography className={titleClassName} variant={titleVariant}>
                    {title}
                </Typography>
            )}
            {!!text && (
                <Typography variant={textVariant} className={clsx({ [contentStyles.text]: !!title })}>
                    {text}
                </Typography>
            )}
        </div>
    );
};

export default NoteText;
