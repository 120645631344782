import { NO_VALUE_PLACEHOLDER } from "shared/constants/appConstants";
import { formatDateUtc } from "shared/utils/date.utils";

export const getEndDateValue = (isPeriodic: boolean, periodicLabel: string, endDate?: Date): string => {
    if (isPeriodic) {
        return periodicLabel;
    }

    if (endDate) {
        return formatDateUtc(endDate);
    }

    return NO_VALUE_PLACEHOLDER;
};
