import React, { useCallback, useEffect, useState } from "react";

import { Redirect } from "react-router";
import { generatePath } from "react-router-dom";

import { FullscreenLoader } from "components/fullscreenLoader";
import { apiGetDepositTenancy } from "shared/api/swagger/realityStone/deposits/apiGetDepositTenancy";
import { useRouteIdParam } from "shared/hooks/useRouteIdParam";
import routeNames from "shared/routes/constants/routeNames";

const DepositRedirector = (): JSX.Element => {
    const depositId = useRouteIdParam();

    const [propertyId, setPropertyId] = useState<number | undefined>();
    const [tenancyId, setTenancyId] = useState<number | undefined>();

    const fetchPropertyTenancy = useCallback(async () => {
        const response = await apiGetDepositTenancy(depositId);
        setTenancyId(response.tenancyId);
        setPropertyId(response.propertyId);
    }, [depositId]);

    useEffect(() => {
        fetchPropertyTenancy();
    }, [fetchPropertyTenancy]);

    if (propertyId) {
        if (tenancyId) {
            return <Redirect to={generatePath(routeNames.PROPERTY.DEPOSIT.ROOT, { id: propertyId, tenancyId })} />;
        }

        return <Redirect to={generatePath(routeNames.PROPERTY.DEPOSIT.EMPTY, { id: propertyId })} />;
    }

    return <FullscreenLoader />;
};

export default DepositRedirector;
