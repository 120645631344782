/**
 * Summary: Get Navigation menu according to the current user account type.
 *
 * Tags: Menu
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_MENU_URL = "api/v1/menu";

export const apiGetMenu = async (config?: AxiosRequestConfig): Promise<ApiGetMenuResponse> => {
    const result = await httpGet<ApiGetMenuResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_MENU_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetMenuResponse = MenuItemModel[];

interface MenuItemModel {
    sortOrder: number; // Sort order. Example: 1
    name?: string; // Name. Example: menu.activity
    route?: string; // Route. Example: /activity
    iconLink?: string; // Icon serialized as base64. Example: data:image/svg+xml;base64,...
    children?: MenuItemModel[]; // Children.
}
