import { Theme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/styles/withStyles";

import {
    TxtColor,
    appAdditionalShadow,
    mobileBreakpoint,
    tabletBreakpoint,
    whiteColor,
} from "shared/theme/defaultTheme";

export const scrollStyles: CSSProperties = {
    overflowY: "auto",
    "@supports not selector(::-webkit-scrollbar)": {
        scrollbarWidth: "thin",
    },
    "@supports (overflow-y: overlay)": {
        "overflow-y": "overlay",
    },
    "&::-webkit-scrollbar": {
        width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
        border: "4px solid transparent",
        backgroundClip: "padding-box",
        borderRadius: "8px",
        backgroundColor: TxtColor.Txt6,
    },
};

export const scrollHorizontalStyles: CSSProperties = {
    overflowX: "auto",
    "@supports not selector(::-webkit-scrollbar)": {
        scrollbarWidth: "thin",
    },
    "@supports (overflow-x: overlay)": {
        "overflow-x": "overlay",
    },
    "&::-webkit-scrollbar": {
        height: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
        border: "4px solid transparent",
        backgroundClip: "padding-box",
        borderRadius: "8px",
        backgroundColor: TxtColor.Txt6,
    },
};

export const getMainScrollbarStyles = (theme: Theme): CSSProperties => {
    return {
        scrollBehavior: "smooth",
        overflowY: "auto",
        "@supports (overflow-y: overlay)": {
            "overflow-y": "overlay",
        },
        "&::-webkit-scrollbar": {
            [theme.breakpoints.down(tabletBreakpoint)]: {
                width: "12px",
            },
            width: "22px",
        },
        "&::-webkit-scrollbar-thumb": {
            border: "8px solid transparent",
            backgroundClip: "padding-box",
            borderRadius: "16px",
            backgroundColor: TxtColor.Txt6,
            [theme.breakpoints.down(tabletBreakpoint)]: {
                border: "4px solid transparent",
                borderRadius: "8px",
            },
        },
    };
};

export const cardStyles: CSSProperties = {
    border: `1px solid ${TxtColor.Txt7}`,
    borderRadius: "8px",
    boxShadow: appAdditionalShadow.bg1[8],
    backgroundColor: whiteColor,
};

export const getAdaptiveCardStyles = (theme: Theme): CSSProperties => ({
    ...cardStyles,
    [theme.breakpoints.down(mobileBreakpoint)]: {
        border: "none",
        boxShadow: "none",
        borderRadius: 0,
    },
});
