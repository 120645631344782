import { apiGetSettingsPrivacyPolicyAndTermsAndConditions } from "shared/api/swagger/spaceStone/settings/apiGetSettingsPrivacyPolicyAndTermsAndConditions";

export const updateFooterLinks = async (): Promise<void> => {
    const links = await apiGetSettingsPrivacyPolicyAndTermsAndConditions();

    window.SYSTEM_SETTINGS = {
        ...window.SYSTEM_SETTINGS,
        footerLinks: {
            privacy: links.privacyPolicy,
            terms: links.termsConditions,
        },
    };
};
