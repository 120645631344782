/**
 * Summary: Get property agency details.
 *
 * Tags: Properties
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_PROPERTY_AGENCY_URL = (propertyId: number): string => `api/v1/properties/${propertyId}/agency`;

export const apiGetPropertyAgency = async (
    propertyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetPropertyAgencyResponse> => {
    const result = await httpGet<ApiGetPropertyAgencyResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_PROPERTY_AGENCY_URL(propertyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetPropertyAgencyResponse = PropertyAgencyDetailsModel;

interface AddressModel {
    postcode: string; // Postcode. Example: ST13 2NA
    addressLine1: string; // Address Line 1. Example: 79 Folkestone Road
    addressLine2?: string; // Address Line 2. Example: Building 5
    city: string; // City. Example: London
    country: CountryLookUpModel; // Country.
    latitude?: number; // Gets or sets latitude. Example: 53.89301
    longitude?: number; // Gets or sets longitude. Example: 27.567444
    uniquePropertyReferenceNumber?: string; // Unique Property Reference Number.  It consists of numbers of up to 12 digits in length. Example: 87611
    isAddressSetManually: boolean; // Gets or sets flag indicating that address was set manually.
    region?: LookUpModel; // Administrative unit.
}

interface CountryLookUpModel {
    id: number;
    text: string;
    iso?: string;
}

interface LookUpModel {
    id: number;
    text: string;
}

interface CompanyModel {
    id: number; // Id.
    tradingName?: string; // Trading name.
    address?: AddressModel; // Address.
    officeName?: string; // Company name.
    centralEmail?: string; // Company central email.
}

interface PropertyAgencyDetailsModel {
    agencyDetails?: CompanyModel; // Agency details.
}
