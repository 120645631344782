/**
 * Summary: Get default bank account by internal user.
 *
 * Tags: BankAccounts
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_ADMIN_ACCOUNT_BANK_ACCOUNTS_DEFAULT_URL = (accountId: number): string =>
    `api/v1/admin/accounts/${accountId}/bank-accounts/default`;

export const apiGetAdminAccountBankAccountsDefault = async (
    accountId: number,
    request?: ApiGetAdminAccountBankAccountsDefaultRequest,
    config?: AxiosRequestConfig
): Promise<ApiGetAdminAccountBankAccountsDefaultResponse> => {
    const result = await httpGet<
        ApiGetAdminAccountBankAccountsDefaultResponse,
        ApiGetAdminAccountBankAccountsDefaultRequest
    >(
        window.APP_SETTINGS.paymentServiceApiBaseUri,
        API_GET_ADMIN_ACCOUNT_BANK_ACCOUNTS_DEFAULT_URL(accountId),
        request,
        config
    );
    return result.data;
};

/**
 *
 */
export interface ApiGetAdminAccountBankAccountsDefaultRequest {
    officeId?: number;
}

/**
 * Description: Success
 */
export type ApiGetAdminAccountBankAccountsDefaultResponse = GetDefaultBankAccountResponse;

interface GetDefaultBankAccountResponse {
    bankIdentifierCode: string;
    accountNumber: string;
    accountName: string;
    routingNumber?: string;
    countryBankAccountTypeId: number;
    bankAccountId: number;
    bankAccountTypeId: number;
    isDefault: boolean;
}
