import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";

import { ExecuteRequestTyped, ExecuteRequestsTyped } from "shared/api/interfaces";

export interface UseRequest {
    isLoading: boolean;
    setUploadAbortControllers: Dispatch<SetStateAction<AbortController[]>>;
    abortUploads: () => void;
    executeRequest: ExecuteRequestTyped;
    executeRequests: ExecuteRequestsTyped;
    actionName: string;
    isLoadingAction: (name: string) => boolean;
}

const MAX_COUNTER = 1_000;

export function useRequest(): UseRequest {
    const actionNames = useMemo((): string[] => [], []);
    const [, updateLoadingCounter] = useState<number>(0);

    const addActionName = useCallback(
        (name: string) => {
            actionNames.push(name);
            updateLoadingCounter(counter => (counter + 1) % MAX_COUNTER);
        },
        [actionNames]
    );
    const removeActionName = useCallback(
        (name: string) => {
            const index = actionNames.indexOf(name);
            if (index > -1) {
                actionNames.splice(index, 1);
            }
            updateLoadingCounter(counter => (counter + 1) % MAX_COUNTER);
        },
        [actionNames]
    );
    const hasActionName = useCallback((name: string) => actionNames.includes(name), [actionNames]);

    const [, setUploadAbortControllers] = useState<AbortController[]>([]);

    const abortUploads = useCallback(() => {
        setUploadAbortControllers(abortControllers => {
            abortControllers.forEach(abortController => {
                abortController.abort();
            });
            return [];
        });
    }, []);

    const executeRequest: ExecuteRequestTyped = useCallback(
        async (fn, requestName, args) => {
            try {
                addActionName(requestName);
                return await fn.call(null, ...args);
            } finally {
                removeActionName(requestName);
            }
        },
        [addActionName, removeActionName]
    );

    // Disabled warning because of the type casting.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const executeRequests = useCallback(
        (async (fn, requestName, args) => {
            try {
                addActionName(requestName);
                const promises = fn.map((f, i) => f.call(null, ...args[i]));
                return await Promise.all(promises);
            } finally {
                removeActionName(requestName);
            }
        }) as ExecuteRequestsTyped,
        [addActionName, removeActionName]
    );

    // Backwards compatibility
    const actionName = actionNames.length ? actionNames[actionNames.length - 1] : "";
    const isLoading = !!actionNames.length;

    return {
        actionName,
        isLoading,
        isLoadingAction: hasActionName,
        setUploadAbortControllers,
        abortUploads,
        executeRequest,
        executeRequests,
    };
}

export const RequestContext = React.createContext<UseRequest>({
    isLoading: false,
    setUploadAbortControllers: () => null,
    abortUploads: () => null,
    executeRequest: () => new Promise(() => null),
    executeRequests: () => new Promise(() => null),
    actionName: "",
    isLoadingAction: () => false,
});

export const RequestContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const {
        isLoading,
        setUploadAbortControllers,
        abortUploads,
        executeRequest,
        executeRequests,
        actionName,
        isLoadingAction,
    } = useRequest();
    return (
        <RequestContext.Provider
            value={{
                isLoading,
                setUploadAbortControllers,
                abortUploads,
                executeRequest,
                executeRequests,
                actionName,
                isLoadingAction,
            }}
        >
            {children}
        </RequestContext.Provider>
    );
};

export const useRequestContext = (): UseRequest => React.useContext(RequestContext);
