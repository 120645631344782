import React from "react";

import { IconButton as MaterialIconButton, SvgIcon } from "@material-ui/core";
import { Theme, alpha } from "@material-ui/core/styles";

import {
    ApplicationColorPalette,
    TxtColor,
    applicationPalettes,
    applicationShadowPalettes,
} from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { ButtonSize, CommonButtonsProps } from "uiKit/buttons/commonButtonsProps";

type IconButtonVariant = ApplicationColorPalette;

interface IconButtonStyleProps {
    noShadow?: boolean;
    variant: IconButtonVariant;
}

export interface IconButtonProps extends CommonButtonsProps {
    id?: string;
    icon: typeof SvgIcon;
    variant?: IconButtonVariant;
    noShadow?: boolean;
    size?: ButtonSize;
    buttonClasses?: Record<string, string>;
    iconClasses?: Record<string, string>;
}

const useStyles = makeSharedStyles<Theme, IconButtonStyleProps>(theme => ({
    icon: {
        fontSize: "inherit",
        color: "inherit",
    },
    root: {
        fontSize: 24,
        boxShadow: ({ noShadow }) => (noShadow ? "none" : applicationShadowPalettes.default[16]),
        color: ({ variant }) => {
            if (variant === "default") {
                return TxtColor.Txt4;
            }

            return applicationPalettes[variant]["400"];
        },
        cursor: "pointer",
        padding: theme.spacing(1),
        "@media (hover: hover)": {
            "&:hover": {
                boxShadow: ({ noShadow }) => (noShadow ? "none" : applicationShadowPalettes.default[4]),
                backgroundColor: ({ variant }) => {
                    if (variant === "default") {
                        return alpha(applicationPalettes.primary["500"]!, 0.12);
                    }

                    return alpha(applicationPalettes[variant]["500"]!, 0.12);
                },
                color: ({ variant }) => {
                    if (variant === "default") {
                        return applicationPalettes.primary["500"];
                    }

                    return applicationPalettes[variant]["700"];
                },
            },
        },
        "&:active": {
            boxShadow: ({ noShadow }) => (noShadow ? "none" : applicationShadowPalettes.default[2]),
            backgroundColor: ({ variant }) => {
                if (variant === "default") {
                    return alpha(applicationPalettes.primary["500"]!, 0.16);
                }

                return alpha(applicationPalettes[variant]["500"]!, 0.16);
            },
            color: ({ variant }) => {
                if (variant === "default") {
                    return applicationPalettes.primary["700"];
                }

                return applicationPalettes[variant]["900"];
            },
        },
        "&.Mui-disabled": {
            color: ({ variant }) => {
                if (variant === "default") {
                    return TxtColor.Txt5;
                }

                return applicationPalettes[variant]["200"];
            },
        },
    },
    sizeSmall: {
        padding: theme.spacing(0.5),
        fontSize: 18,
    },
    disabled: {
        boxShadow: ({ noShadow }) => (noShadow ? "none" : applicationShadowPalettes.default[1]),
    },
}));

export default function IconButton({
    id,
    icon,
    variant,
    noShadow,
    size,
    className,
    buttonClasses,
    iconClasses,
    href,
    openInNewTab,
    ...rest
}: IconButtonProps): JSX.Element {
    const { icon: iconRoot, ...classes } = useStyles({
        noShadow,
        variant: variant || "default",
    });
    const Icon = icon;
    const additionalProps = { ...rest, href, target: openInNewTab ? "_blank" : "_self" };

    return (
        <MaterialIconButton
            id={id}
            className={className}
            classes={{ ...classes, ...buttonClasses }}
            size={size === "s" ? "small" : "medium"}
            disableRipple
            {...additionalProps}
        >
            <Icon classes={{ root: iconRoot, ...iconClasses }} />
        </MaterialIconButton>
    );
}
