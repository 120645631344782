import React, { useMemo } from "react";

import { ArrowBack } from "@material-ui/icons";
import { observer } from "mobx-react-lite";

import { LocalizationId } from "components/backButton/localization";
import { useBackButtonStyles } from "components/backButton/styles";
import { useLocalize } from "shared/hooks/useLocalize";
import TextButton from "uiKit/buttons/TextButton";

import { useRootStore } from "app/useRootStore";

const BackButton = (): JSX.Element => {
    const localize = useLocalize();
    const styles = useBackButtonStyles();

    const {
        rootStore: {
            routerStore: { goBack },
        },
    } = useRootStore();

    const label = useMemo(() => localize(LocalizationId.BackButton), [localize]);

    return (
        <TextButton
            id="go-back-button"
            startIcon={<ArrowBack className={styles.icon} fontSize="small" />}
            label={label}
            onClick={goBack}
        />
    );
};

export default observer(BackButton);
