import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FullscreenLoader } from "components/fullscreenLoader";
import { LocalizedComponentName } from "localization/enums";

import { useRootStore } from "app/useRootStore";

export function withLocalization<Props extends object>(
    WrappedComponent: React.ComponentType<Props>,
    componentName: LocalizedComponentName,
    isSilent = false
): React.FC<Props> {
    return withLocalizations(WrappedComponent, [componentName], isSilent);
}

export function withLocalizations<Props extends object>(
    WrappedComponent: React.ComponentType<Props>,
    componentNames: LocalizedComponentName[],
    isSilent = false,
    isInitialLoading = false
): React.FC<Props> {
    return (props: Props) => {
        const {
            rootStore: { localizationStore },
        } = useRootStore();
        const [loaded, setLoaded] = useState(false);

        const loadLocalization = useCallback(async () => {
            await Promise.all(
                componentNames.map(async componentName => {
                    try {
                        await localizationStore.fetchLocalization(componentName);
                    } catch (error: unknown) {
                        // eslint-disable-next-line no-console
                        console.error(`Failed to load localization for '${componentName}' component`, error);
                    }
                })
            );
        }, [localizationStore]);

        useEffect(() => {
            let isCancelled = false;
            loadLocalization().then(() => {
                if (!isCancelled) {
                    setLoaded(true);
                }
            });

            return () => {
                isCancelled = true;
            };
        }, [loadLocalization]);

        return useMemo(() => {
            if (!loaded && isInitialLoading) {
                return <FullscreenLoader />;
            }

            const loading = isSilent ? null : <FullscreenLoader />;

            return loaded ? <WrappedComponent {...props} /> : loading;
        }, [loaded, props]);
    };
}
