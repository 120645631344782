import { HubConnectionState } from "@microsoft/signalr";
import { orderBy, uniqBy } from "lodash";
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import getRealTimeHubSharedWorker, { WorkerWrapper } from "shared/api/signalr/getRealTimeHubSharedWorker";
import { type RealTimeHubMessage, RealTimeHubMessageType } from "shared/api/signalr/interfaces";
import { apiPostHubsRealTimeJoin } from "shared/api/swagger/notificationService/hubs/apiPostHubsRealTimeJoin";
import { apiDeleteNotification } from "shared/api/swagger/notificationService/notifications/apiDeleteNotification";
import { apiDeleteNotifications } from "shared/api/swagger/notificationService/notifications/apiDeleteNotifications";
import { apiGetNotifications } from "shared/api/swagger/notificationService/notifications/apiGetNotifications";
import { apiGetNotificationsNotReadCount } from "shared/api/swagger/notificationService/notifications/apiGetNotificationsNotReadCount";
import { apiPutNotificationsMarkAllAsRead } from "shared/api/swagger/notificationService/notifications/apiPutNotificationsMarkAllAsRead";
import { apiGetBasketItemsCount } from "shared/api/swagger/realityStone/basket/apiGetBasketItemsCount";
import { isAxios400Error } from "shared/api/utils";
import { NOTIFICATIONS_PAGE_SIZE } from "shared/constants/appConstants";
import { UserNotification } from "shared/interfaces/notifications";

import { RootStore } from "app/rootStore";
import { ChatMessage } from "app/timeStone/releaseRequests/view/chat/interfaces";

export class AppNotificationsStore {
    @observable.shallow public notifications: UserNotification[] = [];

    @observable public notReadNotificationsCount = 0;
    @observable public totalNotificationsCount = 100;
    @observable public basketItemsCount = 0;
    @observable public noInternetConnection = false;

    @observable public connectionId?: string;
    @observable public connectionState: HubConnectionState = HubConnectionState.Disconnected;

    private realTimeHubSharedWorker: WorkerWrapper | undefined;

    constructor(private rootStore: RootStore) {
        makeObservable(this);
    }

    @computed
    public get hasUnreadNotifications(): boolean {
        return Boolean(this.notReadNotificationsCount || this.basketItemsCount);
    }

    @computed
    public get canMoreNotificationsBeFetched(): boolean {
        return this.notifications.length < this.totalNotificationsCount;
    }

    @computed
    public get currentNotificationsPage(): number {
        return Math.ceil(this.notifications.length / NOTIFICATIONS_PAGE_SIZE);
    }

    @computed
    public get shouldCheckBasketItemsCount(): boolean {
        const { isAdmin, isTenant } = this.rootStore;
        return !isAdmin && !isTenant;
    }

    @computed
    private get lowestNotificationId(): number | undefined {
        if (!this.notifications.length) {
            return undefined;
        }
        return this.notifications[this.notifications.length - 1]?.appNotificationId;
    }

    @computed
    private get highestNotificationId(): number | undefined {
        if (!this.notifications.length) {
            return undefined;
        }
        return this.notifications[0]?.appNotificationId;
    }

    @action.bound
    public setNoInternetConnection(flag: boolean): void {
        this.noInternetConnection = flag;
    }

    @action.bound
    public setChatCallbacks(
        onReceiveMessage: typeof this.onChatReceiveMessage,
        onMarkAllAsRead: typeof this.onChatMarkAllAsRead
    ): void {
        this.onChatReceiveMessage = onReceiveMessage;
        this.onChatMarkAllAsRead = onMarkAllAsRead;
    }

    @action.bound
    public async markAllAsRead(): Promise<void> {
        await apiPutNotificationsMarkAllAsRead();

        runInAction(() => {
            this.notReadNotificationsCount = 0;
            this.notifications = this.notifications.map(notification => ({ ...notification, isRead: true }));
        });
    }

    @action.bound
    public async deleteNotification(appNotificationId: number): Promise<void> {
        const itemToDelete = this.notifications.find(
            notification => notification.appNotificationId === appNotificationId
        );
        if (!itemToDelete) {
            return;
        }

        await apiDeleteNotification(appNotificationId);

        runInAction(() => {
            this.notifications = [
                ...this.notifications.filter(notification => notification.appNotificationId !== appNotificationId),
            ];
        });
    }

    @action.bound
    public async deleteAllNotifications(): Promise<void> {
        await apiDeleteNotifications();

        runInAction(() => {
            this.notifications = [];
        });
    }

    @action.bound
    public async refreshNotReadCount(): Promise<void> {
        const count = await apiGetNotificationsNotReadCount({ hideToastOnNoInternetConnection: true });

        this.setNotReadCount(count);
    }

    @action.bound
    public async refreshBasketItemsCount(): Promise<void> {
        if (!this.shouldCheckBasketItemsCount || !this.rootStore.hasCorrectPermissionsToProtectDeposit) {
            return;
        }

        return this.fetchBasketItemsCount();
    }

    @action.bound
    public clearStore(): void {
        this.notifications = [];
        this.notReadNotificationsCount = 0;
        this.basketItemsCount = 0;
        this.totalNotificationsCount = 0;
    }

    @action.bound
    public async fetchRecentNotifications(): Promise<void> {
        const { totalCount, items } = await apiGetNotifications({
            limit: NOTIFICATIONS_PAGE_SIZE,
        });

        runInAction(() => {
            this.totalNotificationsCount = totalCount;
            this.addNotifications(items);
        });
    }

    @action.bound
    public async fetchMoreNotifications(): Promise<void> {
        if (!this.totalNotificationsCount) {
            return;
        }

        const { totalCount, items } = await apiGetNotifications({
            beforeId: this.lowestNotificationId,
            limit: NOTIFICATIONS_PAGE_SIZE,
        });

        runInAction(() => {
            this.totalNotificationsCount = totalCount;
            this.addNotifications(items);
        });
    }

    @action.bound
    public addNotifications(notifications: UserNotification[] | undefined) {
        if (!notifications?.length) {
            return;
        }

        const newNotifications = uniqBy([...this.notifications, ...notifications], "appNotificationId");
        this.notifications = orderBy(newNotifications, "appNotificationId", "desc");
    }

    @action.bound
    public async clearNotifications() {
        this.notifications = [];
    }

    @action.bound
    public connectSharedWorker() {
        this.realTimeHubSharedWorker = getRealTimeHubSharedWorker(this.onWorkerMessage);
    }

    @action.bound
    private setNotReadCount(count: number) {
        this.notReadNotificationsCount = count;
    }

    @action.bound
    private setBasketItemsCount(count: number) {
        this.basketItemsCount = count;
    }

    @action.bound
    private async fetchBasketItemsCount(): Promise<void> {
        const { totalItems } = await apiGetBasketItemsCount({ hideToastOnNoInternetConnection: true });
        this.setBasketItemsCount(totalItems);
    }

    @action.bound
    private onWorkerMessage(message: RealTimeHubMessage): void {
        switch (message.realTimeMessageType) {
            case RealTimeHubMessageType.Connected: {
                const { state, connectionId } = message.payload;
                this.onConnect(state, connectionId!);
                break;
            }

            case RealTimeHubMessageType.Disconnected: {
                this.onDisconnect();
                break;
            }

            case RealTimeHubMessageType.NotificationCountChanged: {
                const { countToChange } = message.payload;
                runInAction(() => {
                    this.setNotReadCount(Math.max(this.notReadNotificationsCount + countToChange, 0));
                });
                break;
            }

            case RealTimeHubMessageType.NewNotificationAdded: {
                runInAction(() => {
                    this.addNotifications([message.payload]);
                });
                break;
            }

            case RealTimeHubMessageType.BasketItemCountChanged: {
                const { countToChange } = message.payload;
                runInAction(() => {
                    this.setBasketItemsCount(Math.max(this.basketItemsCount + countToChange, 0));
                });
                break;
            }

            case RealTimeHubMessageType.ChatMessageAdded: {
                const { payload: chatMessage } = message;
                this.onChatReceiveMessage({
                    ...chatMessage,
                    sentAt: new Date(chatMessage.sentAt),
                });
                break;
            }

            case RealTimeHubMessageType.ChatMessagesMarkedAsRead: {
                const { releaseRequestId, readAt } = message.payload;
                this.onChatMarkAllAsRead(releaseRequestId, new Date(readAt));
                break;
            }
        }
    }

    @action.bound
    private async onConnect(state: HubConnectionState, connectionId: string | undefined) {
        this.setConnectionState(state);
        this.setConnectionId(connectionId);

        try {
            if (state === HubConnectionState.Connected && connectionId) {
                await apiPostHubsRealTimeJoin(
                    { connectionId: connectionId },
                    { hideToastOnNoInternetConnection: true }
                );
            }

            this.setNoInternetConnection(false);
        } catch (e) {
            if (isAxios400Error(e)) {
                this.realTimeHubSharedWorker?.postMessage({
                    realTimeMessageType: RealTimeHubMessageType.Disconnect,
                });
            }

            throw e;
        }
    }

    @action.bound
    private onDisconnect() {
        this.setNoInternetConnection(true);
    }

    @action.bound
    private setConnectionState(state: HubConnectionState) {
        this.connectionState = state;
    }

    @action.bound
    private setConnectionId(connectionId: string | undefined) {
        this.connectionId = connectionId;
    }

    private onChatReceiveMessage: (msg: ChatMessage) => void = () => void 0;
    private onChatMarkAllAsRead: (releaseRequestId: number, readAt: Date) => void = () => void 0;
}
