import React from "react";

import { CircularProgress } from "@material-ui/core";

import useFullscreenLoaderStyles from "components/fullscreenLoader/styles";

interface FullscreenLoaderProps {
    inner?: boolean;
}

export const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({ inner = false }: FullscreenLoaderProps) => {
    const styles = useFullscreenLoaderStyles({ inner });

    return (
        <div className={styles.root} data-testid="fullscreen-loader">
            <CircularProgress />
        </div>
    );
};
