import React from "react";

import { Grid, Theme, useMediaQuery } from "@material-ui/core";

import BasketNotificationsButton from "components/basketNotifications/basketNotificationsButton";
import NotificationsButton from "components/notifications/notificationsButton";
import { mediumBreakpoint } from "shared/theme/defaultTheme";

import { useMenuHeaderStyles } from "app/layout/menu/header/styles";

const MenuHeader: React.FC = (): JSX.Element => {
    const classes = useMenuHeaderStyles();
    const isUpperTabletScreenSize = useMediaQuery<Theme>(theme => theme.breakpoints.up(mediumBreakpoint));

    return (
        <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
            <Grid item xs={12} className={classes.search} />
            <Grid item className={classes.notifyIconContainer}>
                {isUpperTabletScreenSize && (
                    <>
                        <BasketNotificationsButton />
                        <NotificationsButton />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default MenuHeader;
