import React, { useCallback, useMemo, useState } from "react";

import { ClickAwayListener, IconButton, Paper, Popper, Typography } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import { ArrowDropDown } from "@material-ui/icons";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import LinesEllipsis from "react-lines-ellipsis";

import { AccountType } from "shared/constants/enums";
import { useHistory } from "shared/hooks/useHistory";
import { useLocalize } from "shared/hooks/useLocalize";
import routeNames from "shared/routes/constants/routeNames";
import TextButton from "uiKit/buttons/TextButton";
import TranslucentButton from "uiKit/buttons/TranslucentButton";
import Note from "uiKit/container/note/Note";
import Tooltip from "uiKit/container/tooltip";
import LogOut from "uiKit/icons/LogOut";

import { sortProfileAccounts } from "app/layout/menu/profile/helper";
import MenuItem from "app/layout/menu/profile/menuItem";
import { MenuProfileButtonLocalizationId } from "app/layout/menu/profile/menuProfileButton/localization";
import { useMenuProfileButtonStyles, usePopupStyles } from "app/layout/menu/profile/menuProfileButton/styles";
import { useRootStore } from "app/useRootStore";

const MenuProfileButton = (): JSX.Element => {
    const localize = useLocalize();
    const { historyPush } = useHistory();
    const [isTextOverflow, setIsTextOverflow] = useState(false);

    const {
        rootStore: {
            isAdmin,
            authStore: { accountId },
            menuStore: {
                fullName,
                firstName,
                lastName,
                hasMultipleAccounts,
                hasMultipleAccountsOfSameType,
                activeLinkedAccounts,
                userAccountTypesIds,
                currentAccountTypeLabel,
            },
        },
    } = useRootStore();

    const popupStyles = usePopupStyles({ hasMultipleAccounts });
    const styles = useMenuProfileButtonStyles();

    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const onOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchor(event.currentTarget);
    }, []);

    const onCloseMenu = useCallback(() => {
        setAnchor(null);
    }, []);

    const onMenuClick = useCallback(() => {
        onCloseMenu();
        historyPush(routeNames.MY_PROFILE.ROOT);
    }, [historyPush, onCloseMenu]);

    const onLogOut = useCallback(() => {
        historyPush(routeNames.LOGOUT.ROOT);
    }, [historyPush]);

    const accountsContent = useMemo(() => {
        if (!hasMultipleAccounts) {
            return null;
        }

        const accounts = sortProfileAccounts(userAccountTypesIds, activeLinkedAccounts, localize);
        const availableAccounts = accounts.filter(acc => acc.accountId !== accountId);

        return (
            <Paper className={clsx(popupStyles.paper, popupStyles.accountsList)}>
                <MenuList className={popupStyles.listContainer}>
                    {availableAccounts.map(account => {
                        const isCompanyUser =
                            account.accountTypeId == AccountType.CorporateLandlord ||
                            account.accountTypeId == AccountType.Agent;

                        const primaryName = isCompanyUser ? account.accountName : firstName;
                        const secondaryName = isCompanyUser ? undefined : lastName;
                        return (
                            <MenuItem
                                primaryName={primaryName}
                                secondaryName={secondaryName}
                                key={account.accountId}
                                {...account}
                            />
                        );
                    })}
                </MenuList>
            </Paper>
        );
    }, [
        accountId,
        hasMultipleAccounts,
        firstName,
        lastName,
        activeLinkedAccounts,
        localize,
        popupStyles.accountsList,
        popupStyles.listContainer,
        popupStyles.paper,
        userAccountTypesIds,
    ]);

    const severalAccountsNote = useMemo(() => {
        if (isAdmin || !hasMultipleAccountsOfSameType) {
            return null;
        }

        return (
            <div className={popupStyles.note}>
                <Note
                    variant="transparent"
                    titleVariant="caption"
                    title={localize(MenuProfileButtonLocalizationId.SeveralAccountsNote)}
                    titleClassName={popupStyles.noteText}
                />
            </div>
        );
    }, [hasMultipleAccountsOfSameType, isAdmin, localize, popupStyles.note, popupStyles.noteText]);

    return (
        <div className={styles.toggleMobilePopperIcon}>
            <IconButton
                onClick={onOpenMenu}
                id="long-menu-button"
                color="primary"
                className={styles.subMenuIcon}
                data-testid="main-menu-button"
            >
                <ArrowDropDown />
            </IconButton>
            <Popper
                transition
                id="long-menu"
                placement="bottom"
                anchorEl={anchor}
                open={Boolean(anchor)}
                className={popupStyles.list}
            >
                <ClickAwayListener onClickAway={onCloseMenu}>
                    <div className={popupStyles.container}>
                        <Paper className={clsx(popupStyles.paper, popupStyles.profileInfoSection)}>
                            <div className={popupStyles.userInfo}>
                                <Tooltip placement="top" title={isTextOverflow ? fullName : ""}>
                                    <Typography variant="h6">
                                        <LinesEllipsis
                                            trimRight
                                            maxLine={2}
                                            text={fullName}
                                            ellipsis="..."
                                            basedOn="letters"
                                            onReflow={({ clamped }) => setIsTextOverflow(clamped)}
                                        />
                                    </Typography>
                                </Tooltip>
                                <Typography className={styles.roleOrAccountTypeTypography} variant="caption">
                                    {currentAccountTypeLabel}
                                </Typography>
                            </div>
                            <TranslucentButton
                                size="s"
                                palette="primary"
                                onClick={onMenuClick}
                                className={popupStyles.myProfileButton}
                                label={localize(MenuProfileButtonLocalizationId.ProfileMenuItem)}
                            />
                        </Paper>
                        {accountsContent}
                        {severalAccountsNote}
                        <div className={popupStyles.logoutContainer}>
                            <TextButton
                                onClick={onLogOut}
                                startIcon={<LogOut />}
                                className={popupStyles.logoutButton}
                                label={localize(MenuProfileButtonLocalizationId.LogoutMenuItem)}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default observer(MenuProfileButton);
