import { mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useMenuHeaderStyles = makeSharedStyles(theme => ({
    root: {
        padding: theme.spacing(2, 2.5, 3, 2),
        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: theme.spacing(2, 5),
        },
    },

    search: {
        paddingRight: theme.spacing(2.5),

        [theme.breakpoints.down(tabletBreakpoint)]: {
            paddingRight: 0,
        },

        [theme.breakpoints.down(mobileBreakpoint)]: {
            paddingRight: theme.spacing(4),
        },
    },

    notifyIconContainer: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.between(tabletBreakpoint, mobileBreakpoint)]: {
            display: "none",
        },
    },
}));
