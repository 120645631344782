import React, { useMemo } from "react";

import axios from "axios";

import { handleErrorApiResponse } from "shared/api/interceptors/globalApiErrorHandler";
import { useHistory } from "shared/hooks/useHistory";
import { useLocalize } from "shared/hooks/useLocalize";

import { useRootStore } from "app/useRootStore";

let globalResponseInterceptor: number | null = null;

export function withGlobalApiInterceptors<Props extends object>(
    WrappedComponent: React.ComponentType<Props>
): React.FC<Props> {
    return (props: Props) => {
        const { historyLocation, historyReplace } = useHistory();
        const localize = useLocalize();
        const { rootStore } = useRootStore();

        globalResponseInterceptor = useMemo(() => {
            if (globalResponseInterceptor !== null) {
                axios.interceptors.response.eject(globalResponseInterceptor);
            }

            return axios.interceptors.response.use(
                response => response,
                error => handleErrorApiResponse(error, historyReplace, historyLocation, localize, rootStore)
            );
        }, [historyReplace, historyLocation, localize, rootStore]);

        return <WrappedComponent {...props} />;
    };
}
