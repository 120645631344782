/**
 * Summary: Get current user profile.
 *
 * Tags: Profile
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_PROFILE_URL = "api/v1/profile";

export const apiGetProfile = async (config?: AxiosRequestConfig): Promise<ApiGetProfileResponse> => {
    const result = await httpGet<ApiGetProfileResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_PROFILE_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetProfileResponse = GetProfileResponse;

interface LookUpModel {
    id: number;
    text: string;
}

interface FileInfoLightDto {
    fileId: number; // Id of a file. Example: 1
    downloadUrl: string; // SAS Uri to download a file. Example: https://storage.com/image_e1699e0c-017b-4541-a5af-71fd75dcd219.jpg?sas_query_parameters
}

interface GetProfileResponse {
    userId: number; // User ID. Example: 124
    firstName?: string; // First Name. Example: John
    lastName?: string; // Last Name. Example: Doe
    dateOfBirth?: Date; // Date of birth. Example: 1994-04-06T00:00:00Z
    status: LookUpModel; // Status.
    phoneNumber?: string; // Phone number. Example: +123234235
    fullName?: string; // User's full name. Example: John Doe
    email?: string; // Email. Example: admin@mail.com
    marketingPreferences: boolean; // Value indicating whether flag whether to send marketing notifications or not. Example: true
    photo?: FileInfoLightDto; // Photo.
    employmentStatus?: LookUpModel; // Employment Status.
    lastLoginDateTime?: Date; // Last login date. Example: 2021-05-05T12:34:56.789Z
    isLocked: boolean; // Is the user locked. Example: true
    lockoutEnd?: Date; // The user lockout date and time. Example: 2021-05-05T12:34:56.789Z
}
