import { Theme } from "@material-ui/core";

import { TxtColor, mediumBreakpoint, mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

interface MobileMenuHeaderStylesProps {
    palette: "default";
    hasCorrectPermissionsToProtectDeposit?: boolean;
}

export const useMenuProfileStyles = makeSharedStyles<Theme, MobileMenuHeaderStylesProps>(theme => ({
    root: {
        padding: theme.spacing(0, 2, 3),

        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: 0,
        },
    },

    heading: {
        maxWidth: ({ hasCorrectPermissionsToProtectDeposit }) =>
            hasCorrectPermissionsToProtectDeposit ? "89px" : "120px",
        lineHeight: "1.4rem",
        wordWrap: "break-word",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    roleOrAccountTypeTypography: {
        color: TxtColor.Txt2,
    },

    profileContainer: {
        width: "auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginLeft: theme.spacing(2),
        },
    },

    subMenuIconContainer: {
        position: "relative",
    },

    toggleMobilePopperIcon: {
        marginLeft: theme.spacing(2),
        alignSelf: "flex-start",
        marginTop: theme.spacing(),
        [theme.breakpoints.up(mediumBreakpoint)]: {
            marginTop: 0,
            marginLeft: 0,
        },
    },

    subMenuIcon: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        "&:not(:hover)": {
            color: TxtColor.Txt4,
        },
        [theme.breakpoints.up(mediumBreakpoint)]: {
            marginLeft: 0,
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: 0,
            marginLeft: 0,
        },
    },
}));
