import { Theme } from "@material-ui/core/styles";

import { mediumBreakpoint, mobileBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export interface BackButtonContainerProps {
    isFullWidthLayout?: boolean;
}

export const useBackButtonContainerStyles = makeSharedStyles<Theme, BackButtonContainerProps>(theme => ({
    container: {
        zIndex: theme.zIndex.backButton,
        marginBottom: theme.spacing(2),
        padding: ({ isFullWidthLayout }) => (isFullWidthLayout ? theme.spacing(5) : theme.spacing(0, 2)),
        [theme.breakpoints.down(mediumBreakpoint)]: {
            padding: ({ isFullWidthLayout }) => (isFullWidthLayout ? theme.spacing(3) : theme.spacing(0)),
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: ({ isFullWidthLayout }) => (isFullWidthLayout ? theme.spacing(3) : theme.spacing(0)),
        },
    },
}));
