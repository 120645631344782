import React, { useCallback } from "react";

import { Container } from "@material-ui/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

import PolicyPrivacyConfirmDialog from "components/policyPrivacyConfirmDialog";
import { PageNotificationProvider } from "shared/providers/pageNotificationProvider";

import BackButtonContainer from "app/layout/backButtonContainer";
import MobileHeader from "app/layout/menu/mobileHeader";
import { MOBILE_TOGGLED_STYLE, useProtectedLayoutStyles } from "app/layout/protected/basicProtectedLayout.styles";
import ProtectedContentContainer from "app/layout/protected/protectedContentContainer";
import SidePanel from "app/layout/sidePanel";
import { useRootStore } from "app/useRootStore";

interface BasicProtectedLayoutProps {
    children: NonNullable<React.ReactNode>;
}

const BasicProtectedLayout: React.FC<BasicProtectedLayoutProps> = ({
    children,
}: BasicProtectedLayoutProps): JSX.Element => {
    const layoutClasses = useProtectedLayoutStyles();
    const {
        rootStore: {
            layoutStore: { isMenuToggled, openMenu, closeMenu },
            appNotificationsStore: { hasUnreadNotifications },
        },
    } = useRootStore();

    const toggleMenuHandler = useCallback(
        () => (isMenuToggled ? closeMenu() : openMenu()),
        [closeMenu, isMenuToggled, openMenu]
    );

    return (
        <div className={layoutClasses.root} data-testid="basic-protected-layout">
            <div className={clsx(layoutClasses.mobileNavHeader, { [MOBILE_TOGGLED_STYLE]: isMenuToggled })}>
                <MobileHeader
                    toggleMenuHandler={toggleMenuHandler}
                    isMobileToggled={isMenuToggled}
                    hasUnreadNotifications={hasUnreadNotifications}
                />
            </div>
            <div className={clsx(layoutClasses.navigation, { [MOBILE_TOGGLED_STYLE]: isMenuToggled })}>
                <SidePanel />
            </div>
            <PageNotificationProvider>
                <ProtectedContentContainer>
                    <Container className={layoutClasses.container} maxWidth="xl">
                        <BackButtonContainer />
                        {children}
                    </Container>
                    <PolicyPrivacyConfirmDialog />
                </ProtectedContentContainer>
            </PageNotificationProvider>
        </div>
    );
};

export default observer(BasicProtectedLayout);
