import React from "react";

import { usePageNotificationContext } from "shared/providers/pageNotificationProvider";
import Note from "uiKit/container/note/Note";

import {
    usePageNotificationContainerStyles,
    useProtectedLayoutStyles,
} from "app/layout/protected/basicProtectedLayout.styles";
import { CONTENT_WRAPPER_ID } from "app/layout/protected/constants";

const ProtectedContentContainer = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const layoutClasses = useProtectedLayoutStyles();
    const pageNotificationClasses = usePageNotificationContainerStyles();
    const { isNotificationShown, notificationType, notificationMessage } = usePageNotificationContext();

    return (
        <div className={layoutClasses.contentWrapper}>
            {isNotificationShown && (
                <div className={pageNotificationClasses.root}>
                    <Note size="full" variant={notificationType} text={notificationMessage} />
                </div>
            )}
            <div id={CONTENT_WRAPPER_ID} className={layoutClasses.contentInnerWrapper}>
                <div className={layoutClasses.content}>{children}</div>
            </div>
        </div>
    );
};

export default ProtectedContentContainer;
