import { History } from "history";
import { computed, makeObservable, observable } from "mobx";

import { LocalizationStore } from "localization/localizationStore";
import { AccountType } from "shared/constants/enums";
import { CompanyUserPermissions, InternalUserPermissions } from "shared/constants/permissions";
import hasPermissions from "shared/helpers/hasPermissions";
import routeNames from "shared/routes/constants/routeNames";
import { RouterStore } from "shared/routes/routerStore";
import { AppNotificationsStore } from "shared/store/appNotificationsStore";
import { AuthStore } from "shared/store/authStore";
import { StripePublicKeyStore } from "shared/store/stripePublicKeyStore";

import { MenuStore } from "app/layout/menu/store";
import { LayoutStore } from "app/layout/store";

export class RootStore {
    @observable public readonly routerStore: RouterStore;
    @observable public readonly authStore: AuthStore;
    @observable public readonly localizationStore: LocalizationStore;
    @observable public readonly menuStore: MenuStore;
    @observable public readonly layoutStore: LayoutStore;
    @observable public readonly appNotificationsStore: AppNotificationsStore;
    @observable public readonly stripePublicKeyStore: StripePublicKeyStore;

    constructor(history: History) {
        makeObservable(this);

        this.routerStore = new RouterStore(history);
        this.authStore = new AuthStore(this);
        this.localizationStore = new LocalizationStore();
        this.menuStore = new MenuStore(this);
        this.layoutStore = new LayoutStore(this);
        this.appNotificationsStore = new AppNotificationsStore(this);
        this.stripePublicKeyStore = new StripePublicKeyStore();
    }

    @computed
    get accountTypeId(): AccountType {
        return this.authStore.accountTypeId || AccountType.None;
    }

    @computed
    get isAdmin(): boolean {
        return this.accountTypeId && this.accountTypeId === AccountType.Admin;
    }

    @computed
    get isTenant(): boolean {
        return this.accountTypeId && this.accountTypeId === AccountType.Tenant;
    }

    @computed
    get isAgent(): boolean {
        return this.accountTypeId === AccountType.Agent;
    }

    @computed
    get isCorporateLandlord(): boolean {
        return this.accountTypeId && this.accountTypeId === AccountType.CorporateLandlord;
    }

    @computed
    get isPrivateLandlord(): boolean {
        return this.accountTypeId && this.accountTypeId === AccountType.PrivateLandlord;
    }

    @computed
    get isLandlord(): boolean {
        return (
            this.accountTypeId &&
            (this.accountTypeId === AccountType.CorporateLandlord || this.accountTypeId === AccountType.PrivateLandlord)
        );
    }

    @computed
    get isCompanyUser(): boolean {
        return this.isCorporateLandlord || this.isAgent;
    }

    @computed
    get userHomeRoute(): string {
        if (this.isTenant) {
            return routeNames.TENANT_HOME.ROOT;
        }

        return routeNames.DASHBOARD;
    }

    @computed
    get hasCorrectPermissionsToProtectDeposit(): boolean {
        const { userPermissions } = this.authStore;
        if (this.isAdmin) {
            return hasPermissions([InternalUserPermissions.TransferMoneyForCustodialDeposits], userPermissions, false);
        }

        if (this.isCompanyUser) {
            return hasPermissions([CompanyUserPermissions.TransferMoneyForCompanyDeposits], userPermissions, false);
        }

        return this.isPrivateLandlord;
    }

    @computed
    get hasCorrectPermissionsToViewTenancy(): boolean {
        const { userPermissions } = this.authStore;
        if (this.isAdmin) {
            return hasPermissions(
                [InternalUserPermissions.ManageTenancies, InternalUserPermissions.ViewTenancies],
                userPermissions,
                true
            );
        }

        if (this.isCompanyUser) {
            return hasPermissions(
                [CompanyUserPermissions.ManageTenancies, CompanyUserPermissions.ViewTenancies],
                userPermissions,
                true
            );
        }

        return true;
    }
}
