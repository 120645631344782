import React, { useCallback, useMemo } from "react";

import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { observer } from "mobx-react-lite";

import { LocalizationId } from "components/notifications/notificationsButton/localization";
import { useNotificationsListStyles } from "components/notifications/notificationsButton/styles";
import { useNotificationsContainerStyles } from "components/notifications/notificationsContainer/styles";
import NotificationsList from "components/notifications/notificationsList";
import { useRequestContext } from "shared/api/RequestProvider";
import { DELETE_ALL_NOTIFICATIONS } from "shared/constants/requestActionConstants";
import { withLocalRequestContext } from "shared/hoc/localRequestContext";
import { useLocalize } from "shared/hooks/useLocalize";
import { UserNotification } from "shared/interfaces/notifications";
import IconButton from "uiKit/buttons/IconButton";
import TextButton from "uiKit/buttons/TextButton";

export interface NotificationsContainerProps {
    isLoading: boolean;
    notifications: UserNotification[];
    onClose: () => Promise<void>;
    onDeleteNotification: (appNotificationId: number) => Promise<void>;
    onDeleteNotifications: () => Promise<void>;
    onNextPage: () => Promise<void>;
    hasMore: boolean;
}

const NotificationsContainer = ({
    isLoading,
    notifications,
    onClose,
    onDeleteNotification,
    onDeleteNotifications,
    onNextPage,
    hasMore,
}: NotificationsContainerProps): JSX.Element => {
    const styles = useNotificationsContainerStyles();
    const localize = useLocalize();
    const listStyles = useNotificationsListStyles({ hasNotification: Boolean(notifications.length) });

    const { executeRequest, isLoadingAction } = useRequestContext();

    const handleDeleteAllNotifications = useCallback(async () => {
        await executeRequest(onDeleteNotifications, DELETE_ALL_NOTIFICATIONS, []);
    }, [executeRequest, onDeleteNotifications]);

    const isDeleteAllNotificationsButtonDisabled = isLoadingAction(DELETE_ALL_NOTIFICATIONS);

    const clearAllButton = useMemo(() => {
        if (isLoading || !notifications.length) {
            return null;
        }

        return (
            <TextButton
                id="clear-all-notifications"
                label={localize(LocalizationId.ClearAllButton)}
                variant="primary"
                className={styles.clearAllButton}
                onClick={handleDeleteAllNotifications}
                disabled={isDeleteAllNotificationsButtonDisabled}
            />
        );
    }, [
        handleDeleteAllNotifications,
        isDeleteAllNotificationsButtonDisabled,
        isLoading,
        localize,
        notifications.length,
        styles.clearAllButton,
    ]);

    const closeButton = useMemo(() => {
        if (isLoading) {
            return null;
        }
        return (
            <Grid item>
                <IconButton
                    id="close-notification"
                    className={styles.closeIcon}
                    icon={Close}
                    noShadow
                    onClick={onClose}
                />
            </Grid>
        );
    }, [isLoading, onClose, styles.closeIcon]);

    const notificationsList = useMemo(() => {
        if (isLoading) {
            return (
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
            );
        }
        if (!notifications.length) {
            return (
                <div className={styles.noNotificationsBox}>
                    <img
                        className={styles.noNotificationsImage}
                        src={localize(LocalizationId.NoNotificationsImage)}
                        alt=""
                    />
                    <Typography variant="body2">{localize(LocalizationId.NoNotificationsTitle)}</Typography>
                </div>
            );
        }
        return (
            <NotificationsList
                notifications={notifications}
                onDeleteNotification={onDeleteNotification}
                onClose={onClose}
                onNextPage={onNextPage}
                hasMore={hasMore}
            />
        );
    }, [
        hasMore,
        isLoading,
        localize,
        notifications,
        onClose,
        onDeleteNotification,
        onNextPage,
        styles.loader,
        styles.noNotificationsBox,
        styles.noNotificationsImage,
    ]);

    return (
        <Grid container className={styles.root}>
            <Grid container item className={styles.header}>
                <Grid item className={styles.titleContainer}>
                    <Typography variant="h5">{localize(LocalizationId.Title)}</Typography>
                    {clearAllButton}
                </Grid>
                {closeButton}
            </Grid>
            <Grid item className={listStyles.notificationsList}>
                {notificationsList}
            </Grid>
        </Grid>
    );
};

export default withLocalRequestContext(observer(NotificationsContainer));
