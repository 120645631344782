import axios, { AxiosError } from "axios";
import { stringify } from "query-string";

import { AxiosBadRequestError, BadRequestError, BadRequestErrorItem, OAuthError } from "shared/api/errors";
import apiConstants from "shared/constants/apiConstants";
import getValidationFieldName from "shared/helpers/formatValidationFieldError";

type SimpleType = string | number | boolean | number[];
export type SimpleTypeOptional = SimpleType | null | undefined;

function jsonDataReviver<T>(_key: string, value: T | null): Date | undefined | T {
    if (typeof value === "string" && isSerializedDate(value)) {
        return new Date(value);
    }

    if (value === null) {
        return undefined;
    }

    return value;
}

function isSerializedDate(value: string) {
    // Dates are serialized in TZ format, example: '1981-12-20T04:00:14.000Z'.
    const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{0,7})?Z?$/;
    return datePattern.test(value);
}

export function transformResponse<T = unknown>(data: unknown): T {
    if (typeof data === "string") {
        try {
            return JSON.parse(data, jsonDataReviver);
        } catch (e) {
            /* Ignore */
        }
    }

    return data as T;
}

export function getUrlWithQueryParams(url: string, params: { [key: string]: SimpleTypeOptional }): string {
    const qs = stringify(params);
    return qs ? `${url}?${qs}` : url;
}

export function setAuthHeader(token: string | undefined): void {
    axios.defaults.headers.common[apiConstants.HEADERS.AUTHORIZATION] = token ? `${apiConstants.BEARER} ${token}` : "";
}

/**
 * Pickup a single server validation error
 * @param {AxiosError}  error - An error from server
 */
export function pickServerValidationError(error: unknown): string {
    if (!isAxios400Error(error)) {
        return "";
    }

    const { response } = error;

    if (response.data.errors.length) {
        return response.data.errors[0].message;
    }

    return error.code as string;
}

/**
 * Pickup a single server validation code
 * @param {AxiosError}  error - An error from server
 */
export function pickServerValidationCode(error: unknown): string {
    if (!isAxios400Error(error)) {
        return "";
    }

    const { response } = error;

    if (response.data.errors.length) {
        return response.data.errors[0].code;
    }

    return error.code as string;
}

/**
 * Pickup all server validation codes
 * @param {AxiosError}  error - An error from server
 */
export function pickServerValidationCodes(error: unknown): string[] {
    if (!isAxios400Error(error)) {
        return [];
    }

    const { response } = error;

    return response.data.errors.map(it => it.code);
}

/**
 * Pickup all validation messages with field name
 * @param {AxiosError}  error - An error from server
 */
export function pickServerValidationErrors(error: unknown): BadRequestErrorItem[] {
    if (!isAxios400Error(error)) {
        return [];
    }

    const { response } = error;

    return response.data.errors.map(err => {
        const { fieldName, message, code } = err;

        return {
            code,
            message,
            fieldName: getValidationFieldName(fieldName),
        };
    });
}

export function isAxiosOAuthError(error: unknown): error is AxiosBadRequestError<OAuthError> {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    return (
        (error.response?.status === apiConstants.STATUS_CODES.BAD_REQUEST && !!error.response.data.error) ||
        error.response?.status === apiConstants.STATUS_CODES.INTERNAL_ERROR
    );
}

export function isAxios400Error(error: unknown): error is AxiosBadRequestError<BadRequestError> {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    return error.response?.status === apiConstants.STATUS_CODES.BAD_REQUEST && !!error.response?.data.errors;
}

export function isAxios403Error(error: unknown): error is AxiosError {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    return error.response?.status === apiConstants.STATUS_CODES.FORBIDDEN;
}

export function isAxios404Error(error: unknown): error is AxiosError {
    if (!axios.isAxiosError(error)) {
        return false;
    }

    return error.response?.status === apiConstants.STATUS_CODES.NOT_FOUND;
}
