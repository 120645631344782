import { type RealTimeHubMessage, RealTimeHubMessageType } from "shared/api/signalr/interfaces";

import sharedWorkerUrl from "./realTimeHubWorker?sharedworker&url";
import workerUrl from "./realTimeHubWorker?worker&url";

type MessageListener = (message: RealTimeHubMessage) => void;

export type WorkerWrapper = {
    worker: SharedWorker | Worker;
    postMessage: MessageListener;
};

const getRealTimeHubSharedWorker = (onMessage: MessageListener): WorkerWrapper => {
    const worker = getWorker(onMessage);

    const { postMessage } = worker;

    postMessage({
        realTimeMessageType: RealTimeHubMessageType.Connect,
        payload: window.APP_SETTINGS.notificationServiceApiBaseUri,
    });

    return worker;
};

const getWorker = (onMessage: MessageListener): WorkerWrapper => {
    if ("SharedWorker" in window) {
        const worker = new SharedWorker(sharedWorkerUrl, { type: "module" });

        worker.port.addEventListener("message", e => {
            onMessage(e.data as RealTimeHubMessage);
        });

        worker.port.start();

        return { worker, postMessage: (message: RealTimeHubMessage) => worker.port.postMessage(message) };
    } else {
        const worker = new Worker(workerUrl, { type: "module" });
        worker.addEventListener("message", e => {
            onMessage(e.data as RealTimeHubMessage);
        });

        return { worker, postMessage: (message: RealTimeHubMessage) => worker.postMessage(message) };
    }
};

export default getRealTimeHubSharedWorker;
