import jwtDecode from "jwt-decode";
import { isEqual } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import { OAuth2Client } from "shared/api/oauth/client";
import { OAuth2Token } from "shared/api/oauth/interfaces";
import { apiDeleteCheckout } from "shared/api/swagger/realityStone/checkout/apiDeleteCheckout";
import { isAxiosOAuthError, setAuthHeader } from "shared/api/utils";
import { AVENGERS_TOKEN_TYPE, AcrValues, LOCK_NAME, OAuthEndpoints } from "shared/constants/authConstants";
import { AccountType, UserStatus } from "shared/constants/enums";
import { CompanyUserPermissions, InternalUserPermissions } from "shared/constants/permissions";
import {
    AUTHORIZE_STATE,
    CODE_VERIFIER,
    IS_ACCOUNT_SWITCH,
    LOGIN_RETURN_URL,
    OAUTH_TOKEN,
    TARGET_ACCOUNT_ID,
} from "shared/constants/storageKeys";
import { BaseLookup } from "shared/models/lookups";
import routeNames from "shared/routes/constants/routeNames";
import { JwtContent } from "shared/store/interfaces";

import { RootStore } from "app/rootStore";

enum OAuthTokenSource {
    LocalStorage,
    UserRequest,
    TokenRefresh,
    AnotherTab,
    AccountSwitch,
}

interface LoginParams {
    returnUrl?: string;
    authToken?: string;
    accountId?: number;
    targetAccountId?: string;
}

export class AuthStore {
    @observable public isLoggedOutByAnotherTab = false;
    @observable public isLoggedOutBySwitch = false;
    @observable private oauthToken?: OAuth2Token;
    @observable private claims?: JwtContent;

    private client = new OAuth2Client({
        server: window.APP_SETTINGS.oauth2ApiBaseUri,
        clientId: window.APP_SETTINGS.clientId,
        tokenEndpoint: OAuthEndpoints.Token,
        authorizationEndpoint: OAuthEndpoints.Authorize,
        endSessionEndpoint: OAuthEndpoints.EndSession,
    });

    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
        this.loadTokenFromStorage();
        window.addEventListener("storage", this.updateLocalStorageHandler, false);
    }

    @computed
    get isAuthenticated(): boolean {
        return !!this.claims && this.claims.statusId === UserStatus.Active;
    }

    @computed
    get isDormant(): boolean {
        return !!this.claims && this.claims.statusId === UserStatus.Dormant;
    }

    @computed
    get userId(): number {
        return this.claims?.id ?? 0;
    }

    @computed
    get accountId(): number {
        return this.claims?.accountId ?? 0;
    }

    @computed
    get accountTypeId(): AccountType {
        return this.claims?.accountTypeId ?? AccountType.None;
    }

    @computed
    get isCompanyAdmin(): boolean {
        return this.claims?.isCompanyAdmin ?? false;
    }

    @computed
    get isTermsAndConditionsConfirmed(): boolean {
        return this.claims?.isTermsAndConditionsConfirmed ?? false;
    }

    @computed
    get requiresMissingDetails(): boolean {
        return this.claims?.requiresMissingDetails ?? false;
    }

    @computed
    get userPermissions(): Set<InternalUserPermissions | CompanyUserPermissions> {
        if (!this.claims) {
            return new Set();
        }

        return new Set(this.claims.permissions);
    }

    @computed
    get officeIds(): number[] {
        if (!this.claims || !this.claims.officeIds) {
            return [];
        }

        return this.claims.officeIds;
    }

    private get redirectUri(): string {
        return document.location.origin + routeNames.LOGIN.CALLBACK;
    }

    @action.bound
    public async loginRedirect(params: LoginParams): Promise<void> {
        const { returnUrl, accountId, targetAccountId } = params;
        const acrValues = this.getAcrValues(params);
        const { codeVerifier, state, url } = await this.client.getAuthorizeUri({
            redirectUri: this.redirectUri,
            extraParams: acrValues
                ? {
                      acr_values: acrValues,
                  }
                : undefined,
        });

        sessionStorage.setItem(CODE_VERIFIER, codeVerifier);
        sessionStorage.setItem(TARGET_ACCOUNT_ID, targetAccountId ?? "");
        sessionStorage.setItem(AUTHORIZE_STATE, state);
        sessionStorage.setItem(LOGIN_RETURN_URL, returnUrl ?? "");
        sessionStorage.setItem(IS_ACCOUNT_SWITCH, JSON.stringify(!!accountId));
        window.location.replace(url);
    }

    @action.bound
    public async loginCallback(
        url: string
    ): Promise<{ returnUrl: string; isAccountSwitched: boolean; isAccountMismatched: boolean }> {
        const codeVerifier = sessionStorage.getItem(CODE_VERIFIER) || "";
        const targetAccountId = sessionStorage.getItem(TARGET_ACCOUNT_ID) || "";
        const returnUrl = sessionStorage.getItem(LOGIN_RETURN_URL) || "";
        const state = sessionStorage.getItem(AUTHORIZE_STATE) || "";
        const serializedIsAccountSwitchedFlag = sessionStorage.getItem(IS_ACCOUNT_SWITCH);

        if (!state) {
            return { returnUrl: routeNames.LOGIN.ROOT, isAccountSwitched: false, isAccountMismatched: false };
        }

        const response = await this.client.getTokenFromCodeRedirect({
            url,
            state,
            codeVerifier,
            redirectUri: this.redirectUri,
        });

        sessionStorage.removeItem(CODE_VERIFIER);
        sessionStorage.removeItem(TARGET_ACCOUNT_ID);
        sessionStorage.removeItem(LOGIN_RETURN_URL);
        sessionStorage.removeItem(AUTHORIZE_STATE);
        sessionStorage.removeItem(IS_ACCOUNT_SWITCH);

        this.setOAuthToken(OAuthTokenSource.UserRequest, response);

        const isAccountSwitched = serializedIsAccountSwitchedFlag
            ? JSON.parse(serializedIsAccountSwitchedFlag || "")
            : false;
        const isAccountMismatched = !!targetAccountId && this.accountId !== Number(targetAccountId);

        return { returnUrl, isAccountSwitched, isAccountMismatched };
    }

    @action.bound
    public async logout(clearCheckoutSession = true): Promise<void> {
        if (!this.oauthToken) {
            return;
        }

        if (clearCheckoutSession) {
            await this.deleteCheckoutSession();
        }

        const logoutUri = this.client.getEndSessionUri(this.oauthToken);
        this.setOAuthToken(OAuthTokenSource.UserRequest, undefined);
        window.location.replace(logoutUri);
    }

    @action.bound
    public async logoutWithSwitch(clearCheckoutSession = true): Promise<void> {
        if (!this.oauthToken) {
            return;
        }

        if (clearCheckoutSession) {
            await this.deleteCheckoutSession();
        }

        this.setOAuthToken(OAuthTokenSource.AccountSwitch, undefined);
    }

    @action.bound
    public async exchangeToken(authToken: string): Promise<void> {
        const response = await this.client.exchangeToken(authToken, AVENGERS_TOKEN_TYPE);
        this.setOAuthToken(OAuthTokenSource.UserRequest, response);
    }

    @action.bound
    public async refreshAccessToken(): Promise<void> {
        const oldPermissions = this.userPermissions;

        await navigator.locks.request(LOCK_NAME, async () => {
            if (!this.oauthToken) {
                throw new Error("No refresh token exists");
            }

            try {
                const response = await this.client.refreshToken(this.oauthToken);
                this.setOAuthToken(OAuthTokenSource.TokenRefresh, response);
                this.fetchMenuItemsIfPermissionsChanged(oldPermissions, this.userPermissions);
            } catch (error) {
                if (isAxiosOAuthError(error)) {
                    await this.logout(false);
                }

                throw error;
            }
        });
    }

    @action.bound
    public getUserOwnOfficesIntersection(officesToIntersect: BaseLookup[]): BaseLookup[] {
        const currentUserOfficeIds = this.officeIds || [];

        if (!currentUserOfficeIds.length || !officesToIntersect.length) {
            return [];
        }

        return officesToIntersect.filter(({ id }) => currentUserOfficeIds.includes(id));
    }

    @action.bound
    public async deleteCheckoutSession(): Promise<void> {
        if (this.rootStore.isAgent || this.rootStore.isLandlord || this.rootStore.isAdmin) {
            try {
                await apiDeleteCheckout({
                    on404Error: () => void 0,
                    on403Error: () => void 0,
                });
            } catch (e) {
                // stub
            }
        }
    }

    @action.bound
    private loadTokenFromStorage(): void {
        const serializedToken = localStorage.getItem(OAUTH_TOKEN);

        if (!serializedToken) {
            return;
        }

        this.setOAuthToken(OAuthTokenSource.LocalStorage, JSON.parse(serializedToken));
    }

    @action.bound
    private updateLocalStorageHandler(storageEvent: StorageEvent): void {
        if (storageEvent.key !== OAUTH_TOKEN) {
            return;
        }

        const newValue = storageEvent.newValue || undefined;
        const oauthToken = newValue ? JSON.parse(newValue) : undefined;
        this.setOAuthToken(OAuthTokenSource.AnotherTab, oauthToken);
    }

    @action.bound
    private fetchMenuItemsIfPermissionsChanged(oldPermissions: Set<number>, newPermissions: Set<number>) {
        if (isEqual(oldPermissions, newPermissions)) {
            return;
        }

        this.rootStore.menuStore.fetchMenuItems();
    }

    @action.bound
    private setOAuthToken(source: OAuthTokenSource, oauthToken?: OAuth2Token): void {
        if (oauthToken === this.oauthToken) {
            return;
        }

        if (oauthToken) {
            this.claims = jwtDecode<JwtContent>(oauthToken.accessToken);
            this.oauthToken = oauthToken;
            this.isLoggedOutByAnotherTab = false;
            this.isLoggedOutBySwitch = false;
            if (!this.isDormant) {
                localStorage.setItem(OAUTH_TOKEN, JSON.stringify(oauthToken));
            }
            setAuthHeader(this.oauthToken.accessToken);
        } else {
            this.oauthToken = undefined;
            this.claims = undefined;
            this.isLoggedOutByAnotherTab = source === OAuthTokenSource.AnotherTab;
            this.isLoggedOutBySwitch = source === OAuthTokenSource.AccountSwitch;
            localStorage.removeItem(OAUTH_TOKEN);
            setAuthHeader(undefined);
        }
    }

    private getAcrValues({ authToken, accountId, targetAccountId }: LoginParams): string | undefined {
        const acrValues = [];

        if (authToken) {
            acrValues.push(`${AcrValues.Token}:${authToken}`);
        }

        if (accountId) {
            acrValues.push(`${AcrValues.AccountId}:${accountId}`);
        }

        if (targetAccountId) {
            acrValues.push(`${AcrValues.AccountId}:${targetAccountId}`);
        }

        return acrValues.length ? acrValues.join(" ") : undefined;
    }
}
