import { applicationPalettes, whiteColor } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useTitleStyles = makeSharedStyles(() => ({
    root: {
        color: whiteColor,
    },
}));

export const useTooltipStyles = makeSharedStyles(theme => ({
    tooltip: {
        margin: theme.spacing(1, 0, 0.75, 0),
        maxWidth: 330,
        zIndex: theme.zIndex.tooltip,
    },
    arrow: {
        "&:before": {
            backgroundColor: applicationPalettes.primary[900],
        },
        fontSize: 15,
    },
}));
