import React, { useCallback } from "react";

import { useIntl } from "react-intl";

export type MessageValues<T extends string | React.ReactNode = string> = Record<
    string,
    string | number | undefined | null | T
>;

export type LocalizeFunction = <T extends string | React.ReactNode = string>(
    id: string,
    values?: MessageValues<T>
) => string;

export const useLocalize = (): LocalizeFunction => {
    const { formatMessage } = useIntl();

    return useCallback(
        <T extends string | React.ReactNode = string>(id: string, values?: MessageValues<T>): string => {
            return (id ? formatMessage({ id }, values) : id) as string;
        },
        [formatMessage]
    );
};
