import { mobileBreakpoint, tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useNotificationsContainerStyles = makeSharedStyles(theme => ({
    root: {
        padding: theme.spacing(2, 0, 2, 3),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        overflow: "hidden",

        [theme.breakpoints.up(tabletBreakpoint)]: {
            maxWidth: 472,
            width: "100vw",
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            width: "100%",
            height: "100%",
        },
    },

    header: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            paddingRight: theme.spacing(2),
        },
    },

    titleContainer: {
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            width: "100%",
        },
    },

    closeIcon: {
        fontSize: 36,
        display: "initial",
        [theme.breakpoints.up(tabletBreakpoint)]: {
            display: "none",
        },
    },

    clearAllButton: {
        marginRight: theme.spacing(3),
    },

    noNotificationsBox: {
        textAlign: "center",
        minHeight: 150,
    },
    noNotificationsImage: {
        marginBottom: theme.spacing(2),
    },

    loader: {
        display: "block",
        width: "fit-content",
        margin: theme.spacing(2, "auto"),
    },
}));
