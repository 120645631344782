import React from "react";

import { FieldValues } from "react-hook-form";

import { DialogProps } from "shared/interfaces/container/dialog/dialog";
import DialogComponent from "uiKit/container/dialog/DialogComponent";

export default function Dialog<TFormValue extends FieldValues = FieldValues>({
    isOpened,
    ...props
}: DialogProps<TFormValue>): JSX.Element | null {
    return <>{isOpened ? <DialogComponent isOpened={isOpened} {...props} /> : null}</>;
}
