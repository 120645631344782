import React, { FunctionComponent, Suspense, useEffect, useState } from "react";

import { History } from "history";
import { Redirect, Route, Router, Switch } from "react-router";
import { HashRouter } from "react-router-dom";

import { FullscreenLoader } from "components/fullscreenLoader";
import { withGlobalApiInterceptors } from "shared/api/interceptors/withGlobalApiInterceptors";
import { updateFooterLinks } from "shared/helpers/updateFooterLinks";
import LeavePageInterceptor from "shared/routes/LeavePageInterceptor";
import { PublicRoute } from "shared/routes/PublicRoute";

import Layout from "app/layout";

import { PrivateRoute } from "./PrivateRoute";
import routeNames from "./constants/routeNames";
import routes from "./routes";

interface ApplicationRouterProps {
    homeRoute: string;
    history: History;
}

const LayoutWithApiInterceptors = withGlobalApiInterceptors(Layout);

const ApplicationRouter: FunctionComponent<ApplicationRouterProps> = ({
    history,
    homeRoute,
}: ApplicationRouterProps) => {
    const [isFooterLinksLoaded, setIsFooterLinksLoaded] = useState(false);

    useEffect(() => {
        const request = async () => {
            await updateFooterLinks();
            setIsFooterLinksLoaded(true);
        };
        request();
    }, []);

    const renderedRoutes = (
        <LayoutWithApiInterceptors>
            <LeavePageInterceptor>
                <Suspense fallback={<FullscreenLoader />}>
                    <Switch>
                        {routes.map(route =>
                            route.isPrivate ? (
                                <PrivateRoute
                                    key={route.path}
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                />
                            ) : (
                                <PublicRoute
                                    key={route.path}
                                    exact={route.exact}
                                    path={route.path}
                                    component={route.component}
                                />
                            )
                        )}
                        <Route exact path="/">
                            <Redirect to={homeRoute} />
                        </Route>
                        <Route path="/">
                            <Redirect to={routeNames.ERRORS.NOT_FOUND} />
                        </Route>
                    </Switch>
                </Suspense>
            </LeavePageInterceptor>
        </LayoutWithApiInterceptors>
    );

    const router = window.SYSTEM_SETTINGS.isTestingEnvironment ? (
        <HashRouter>{renderedRoutes}</HashRouter>
    ) : (
        <Router history={history}>{renderedRoutes}</Router>
    );

    return !isFooterLinksLoaded ? <FullscreenLoader /> : router;
};

export default ApplicationRouter;
