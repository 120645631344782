import { addMinutes, addMonths, addYears, differenceInMonths, endOfDay, format, isValid, startOfDay } from "date-fns";

import { DATE_FORMATS } from "shared/constants/appConstants";

export const getUtcDate = (date: Date): Date =>
    date && isValid(date) ? addMinutes(date, date.getTimezoneOffset()) : date;

export const getStartOfDayUTC = (date: Date): Date => (date && isValid(date) ? startOfDay(date) : date);
export const getEndOfDayUTC = (date: Date): Date => (date && isValid(date) ? endOfDay(date) : date);

/**
 * Format date. Before formatting the date will be converted to local time using user locale
 * @param date - a date to format
 * @param dateFormat - date format, e.g dd/MM/yyyy
 */
export function formatDate(date: Date | number | string, dateFormat = DATE_FORMATS.DATE_FORMAT): string {
    return format(new Date(date), dateFormat);
}

/**
 * Format date ignoring user locale
 * @param date - a date to format
 * @param dateFormat - date format, e.g dd/MM/yyyy
 */
export function formatDateUtc(date: Date | number | string, dateFormat = DATE_FORMATS.DATE_FORMAT): string {
    const dateSource = new Date(date);

    return format(addMinutes(dateSource, dateSource.getTimezoneOffset()), dateFormat);
}

export function addOffsetToUtcDate(date: Date | number | string): Date {
    const dateSource = new Date(date);

    return addMinutes(dateSource, dateSource.getTimezoneOffset());
}
export function removeOffsetFromDate(date: Date | number | string): Date {
    const dateSource = new Date(date);

    return addMinutes(dateSource, -dateSource.getTimezoneOffset());
}

export const getDiffInMonths = (minuend: Date, subtrahend: Date): number => differenceInMonths(minuend, subtrahend);

export const getDateNYearsFromNow = (years: number): Date => {
    const dateNYearsFromNow = addYears(new Date(), years);

    return startOfDay(dateNYearsFromNow);
};

export const getDateNMonthsFromNow = (months: number): Date => {
    const dateNMonthsFromNow = addMonths(new Date(), months);

    return startOfDay(dateNMonthsFromNow);
};

export const isDate = (date: unknown): date is Date => isValid(date);
