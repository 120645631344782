import React from "react";

import { Typography } from "@material-ui/core";
import { Check, InfoRounded, PriorityHigh } from "@material-ui/icons";
import { Alert, Color } from "@material-ui/lab";
import { TypeOptions } from "react-toastify";

import { ToastContentProps } from "shared/interfaces/container/toast/toast";
import { applicationPalettes } from "shared/theme/defaultTheme";
import { useAlertStyles } from "uiKit/container/toast/styles";

const getAlertSeverityByToastType = (options?: TypeOptions): Color => {
    switch (options) {
        case "info":
            return "info";
        case "warning":
            return "warning";
        case "error":
            return "error";
        case "success":
            return "success";
        default:
            return "info";
    }
};

const getAlertIconByToastType = (options?: TypeOptions): React.ReactNode => {
    switch (options) {
        case "info":
            return <InfoRounded />;
        case "warning":
            return <PriorityHigh />;
        case "error":
            return <PriorityHigh />;
        case "success":
            return <Check />;
        default:
            return "info";
    }
};

const getAlertIconBackgroundByToastType = (options?: TypeOptions): string => {
    switch (options) {
        case "info":
            return applicationPalettes.primary[500]!;
        case "warning":
            return applicationPalettes.orange[500]!;
        case "error":
            return applicationPalettes.red[500]!;
        case "success":
            return applicationPalettes.green[500]!;
        default:
            return "info";
    }
};

const ToastContent = ({ title, message, toastProps, closeToast, showCloseIcon }: ToastContentProps): JSX.Element => {
    const hasOnlyMessage = !title;
    const hasOnlyTitle = !message;
    const { titleTypography, messageTypography, ...alertClasses } = useAlertStyles({
        background: getAlertIconBackgroundByToastType(toastProps.type),
        hasOnlyMessage,
        hasOnlyTitle,
        showCloseIcon,
    });

    return (
        <Alert
            variant="outlined"
            severity={getAlertSeverityByToastType(toastProps.type)}
            icon={getAlertIconByToastType(toastProps.type)}
            onClose={showCloseIcon ? closeToast : undefined}
            classes={alertClasses}
        >
            <Typography className={titleTypography} variant="subtitle2">
                {title}
            </Typography>
            {message && (
                <Typography className={messageTypography} variant="body2">
                    {message}
                </Typography>
            )}
        </Alert>
    );
};

export default ToastContent;
