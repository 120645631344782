import routeNames from "shared/routes/constants/routeNames";
import { isAndroid } from "shared/utils/userAgent.utils";

export function openUrlInNewTab(url: string): Window | null {
    return window.open(url, "");
}

export async function openPdfInNewTab(getDownloadUrl: () => Promise<string | undefined>): Promise<void> {
    // It seems all android browsers do not support previewing a PDF in a new tab
    if (isAndroid()) {
        const response = await getDownloadUrl();
        if (response) {
            window.location.assign(response);
        }
        return;
    }

    const newTab = openUrlInNewTab(routeNames.REDIRECTS.FILE_LOADING.ROOT);
    if (!newTab) {
        return;
    }

    try {
        const response = await getDownloadUrl();
        if (response) {
            newTab.location.replace(response);
        }
    } catch {
        newTab.close();
    }
}
