/**
 * Summary: Get tenancy summary details.
 *
 * Tags: Tenancies
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_TENANCY_SUMMARY_URL = (tenancyId: number): string => `api/v1/tenancies/${tenancyId}/summary`;

export const apiGetTenancySummary = async (
    tenancyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetTenancySummaryResponse> => {
    const result = await httpGet<ApiGetTenancySummaryResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_TENANCY_SUMMARY_URL(tenancyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetTenancySummaryResponse = TenancySummaryModel;

interface TenancySummaryModelDepositDetails {
    depositId: number; // Deposit Id.
    depositNumber?: string; // Deposit number. Example: DR123456789
    depositStatusId: number; // Deposit status Id. Example: 101
    depositSchemeId: number; // Deposit scheme Id. Example: 317
    protectionStartDate?: Date; // Protection start date.
    protectedAmount: number; // Protected amount. Example: 10.23
}

interface TenancySummaryModel {
    tenancyId: number; // Tenancy Id. Example: 42
    tenancyName?: string; // Tenancy name. Example: TC12345678
    tenancyStatusId: number; // Tenancy status Id. Example: 71
    tenancyStartDate: Date; // Start date.
    tenancyEndDate?: Date; // End date.
    isTenancyPeriodic: boolean; // Is the tenancy periodic.
    deposit?: TenancySummaryModelDepositDetails; // Short deposit details.
    endOfTenancyId?: number; // End of tenancy id. Example: 567
    claimId?: number; // Claim Id. Example: 417
    canManageTenancy: boolean; // Can current user manage tenancy. Example: true
    canCreateDeposit: boolean; // Can current user create deposit. Example: true
    canManageDeposit: boolean; // Can current user manage deposit. Example: true
    canTransferDeposit: boolean; // Can current user transfer deposit.
    canCreateEndOfTenancy: 1 | 2 | 3; // Can current user create end of tenancy. Example: true
    canChangePaymentVariant: boolean; // Can current user change payment variant. Example: true
    canCreateClaim: boolean; // Can current user create claim. Example: true
    tenantsChangeRequestId?: number; // Tenancy tenants change request id. Example: 3
}
