import { applicationPalettes } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

interface IntercomStylesProps {
    isOpen: boolean;
}

export const useIntercomStyles = makeSharedStyles(theme => ({
    launcher: {
        zIndex: theme.zIndex.intercomLauncher,
        color: "white",
        position: "absolute",
        right: 20,
        bottom: 30,
        width: 40,
        height: 40,
        borderRadius: "50%",
        backgroundColor: ({ isOpen }: IntercomStylesProps) =>
            isOpen ? applicationPalettes.primary[700] : applicationPalettes.primary[500],
        cursor: "pointer",
    },
    iconWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    iconDown: {
        width: 18,
        height: 14,
    },
    iconUp: {
        width: 20,
        height: 28,
    },
}));
