import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Location } from "history";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router-dom";

import { useHistory } from "shared/hooks/useHistory";

interface LeavePageState {
    ignoreLeaveConfirmationDialog?: boolean;
}

export interface LeavePageHandler {
    isDirty: () => boolean;
    showModal: () => void;
    confirmedNavigation: boolean;
}

interface LeavePageInterceptorContextValue {
    addHandler: (handler: LeavePageHandler) => void;
    removeHandler: (handler: LeavePageHandler) => void;
}

const leavePageContextValue: LeavePageInterceptorContextValue = {
    addHandler: () => void 0,
    removeHandler: () => void 0,
};

export const LeavePageInterceptorContext = React.createContext<LeavePageInterceptorContextValue>(leavePageContextValue);

export const useLeavePageInterceptorContext = (): LeavePageInterceptorContextValue =>
    useContext<LeavePageInterceptorContextValue>(LeavePageInterceptorContext);

const LeavePageInterceptor = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const { historyPush, historyLocation } = useHistory<LeavePageState>();

    const [handlers, setHandlers] = useState<LeavePageHandler[]>([]);
    const addHandler = useCallback((leavePageHandler: LeavePageHandler) => {
        setHandlers(prev => {
            return [leavePageHandler, ...prev];
        });
    }, []);
    const removeHandler = useCallback((leavePageHandler: LeavePageHandler) => {
        setHandlers(prev => prev.filter(handler => handler !== leavePageHandler));
    }, []);

    const showConfirmation = useMemo(() => handlers.length > 0, [handlers.length]);

    const checkForDirtyValues = useCallback(
        (): boolean => showConfirmation && handlers.some(({ isDirty }) => isDirty()),
        [handlers, showConfirmation]
    );

    const findDirtyHandler = useCallback(
        (): LeavePageHandler | undefined => (showConfirmation ? handlers.find(({ isDirty }) => isDirty()) : undefined),
        [handlers, showConfirmation]
    );

    const isConfirmedHandlerExists = useCallback(
        (): boolean => handlers.some(({ confirmedNavigation }) => confirmedNavigation),
        [handlers]
    );

    const [lastLocation, setLastLocation] = useState<Location<LeavePageState> | null>(null);
    const handleBlockedNavigation = (nextLocation: Location): string | boolean => {
        const { state, pathname } = nextLocation as Location<LeavePageState>;
        const { ignoreLeaveConfirmationDialog, ...restState } = state ?? {};
        nextLocation.state = restState;

        if (ignoreLeaveConfirmationDialog) {
            return true;
        }

        const dirtyHandler = findDirtyHandler();
        if (!dirtyHandler) {
            return true;
        }

        const { confirmedNavigation, showModal } = dirtyHandler;

        if (!confirmedNavigation && historyLocation.pathname !== pathname) {
            showModal();
            setLastLocation(nextLocation as Location<LeavePageState>);
            return false;
        }
        return true;
    };

    useEffect(() => {
        const isConfirmed = isConfirmedHandlerExists();
        if (isConfirmed && lastLocation) {
            // Navigate to the previous blocked location
            historyPush(lastLocation);
        }
    }, [isConfirmedHandlerExists, lastLocation, historyPush]);

    useBeforeunload(event => {
        if (checkForDirtyValues()) {
            event.preventDefault();
        }
    });

    return (
        <LeavePageInterceptorContext.Provider value={{ addHandler, removeHandler }}>
            <Prompt when={showConfirmation} message={handleBlockedNavigation} />
            {children}
        </LeavePageInterceptorContext.Provider>
    );
};

export default LeavePageInterceptor;
