import { action, computed, makeObservable, observable, runInAction } from "mobx";

import {
    ApiGetSettingsStripeKeysResponse,
    apiGetSettingsStripeKeys,
} from "shared/api/swagger/paymentService/settings/apiGetSettingsStripeKeys";
import { DepositSchemes } from "shared/constants/enums";

export type StripeKeysEntity = Unpacked<ApiGetSettingsStripeKeysResponse["keys"]>;

export class StripePublicKeyStore {
    @observable private stripePublicKeys?: StripeKeysEntity[];
    @observable private schemeId?: DepositSchemes;

    constructor() {
        makeObservable(this);
    }

    @computed
    get depositSchemeId(): DepositSchemes | undefined {
        return this.schemeId;
    }

    @computed
    get publicKeys(): StripeKeysEntity[] | undefined {
        return this.stripePublicKeys;
    }

    @computed
    get stripePublicKey(): string | undefined {
        return this.stripePublicKeys?.find(key => key.schemeId === this.depositSchemeId)?.publicKey;
    }

    @action.bound
    public async fetchStripePublicKeys(): Promise<void> {
        if (this.stripePublicKeys) {
            return;
        }
        const response: ApiGetSettingsStripeKeysResponse = await apiGetSettingsStripeKeys();

        runInAction(() => {
            this.stripePublicKeys = response.keys;
        });
    }

    @action.bound
    public setStripeDepositSchemeId(depositSchemeId: DepositSchemes): void {
        this.schemeId = depositSchemeId;
    }
}
