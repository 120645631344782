export function nameOf<T>(key: keyof T): keyof T {
    return key;
}

export function stringNameOf<T>(name: keyof T): string {
    return String(name);
}

export function isUndefined(value: unknown): value is undefined {
    return value === undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<T, X extends {} = {}, Y extends PropertyKey = string>(
    obj: X,
    prop: Y
): obj is X & Record<Y, T> {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function getOwnProperty<T>(obj: unknown, prop: string): T | undefined {
    return typeof obj === "object" && obj !== null && hasOwnProperty<T>(obj, prop) ? obj[prop] : undefined;
}
