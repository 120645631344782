import { tabletBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const usePublicStyles = makeSharedStyles(theme => ({
    root: {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            "& .content .left-panel": {
                display: "none",
            },
        },
    },
}));
