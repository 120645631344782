import { TxtColor, applicationPalettes, mobileBreakpoint } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { AvatarStyleProps } from "uiKit/avatar/interface";

export const useAvatarStyles = makeSharedStyles(theme => ({
    avatar: {
        flexDirection: "column",
        position: "relative",
        width: ({ avatarSize }: AvatarStyleProps) => avatarSize.width,
        height: ({ avatarSize }: AvatarStyleProps) => avatarSize.height,
        marginRight: ({ noMargin }: AvatarStyleProps) => (noMargin ? 0 : theme.spacing(2)),
        border: `1px solid ${applicationPalettes.default[50]}`,
        backgroundColor: applicationPalettes.default["1"],
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginRight: 0,
        },
        "& .input-file": {
            width: "100%",
        },
        "& img": {
            height: "100%",
            width: "100%",
            position: "absolute",
            objectFit: "contain",
        },
    },
    imageContainer: {
        border: "none",
        marginRight: theme.spacing(0),
    },
    avatarEditBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
    imageEditBox: {
        maxWidth: "168px",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    avatarEdit: {
        width: "100%",
        height: "56px",
        position: "absolute",
        left: 0,
        bottom: 0,
        backgroundColor: `${applicationPalettes.primary[800]}66`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            height: "40px",
        },
    },
    avatarEditImage: {
        width: ({ avatarSize }: AvatarStyleProps) => avatarSize.width,
        height: ({ avatarSize }: AvatarStyleProps) => avatarSize.height,
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    avatarDescription: {
        textAlign: "center",
        width: "190px",
        margin: theme.spacing(2, 0, 2, -1.75),
        display: "flex",
        flexDirection: "column",
        "& .MuiTypography-root": {
            color: TxtColor.Txt3,
        },
        [theme.breakpoints.down(mobileBreakpoint)]: {
            margin: theme.spacing(1, 0, 0, 0),
        },
    },
    roundedImageDescription: {
        margin: theme.spacing(2, 0, 0),
    },
    avatarDeleteButton: {
        color: TxtColor.Txt4,
        [theme.breakpoints.down(mobileBreakpoint)]: {
            margin: theme.spacing(2, 0),
        },
    },
    avatarText: {
        color: applicationPalettes.primary[500],
    },
    descriptionBox: {
        textAlign: "center",
    },
    descriptionColumnDirection: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        display: "flex",
        "& div": {
            textAlign: "left",
        },
        "& button": {
            margin: theme.spacing(2, 0),
        },
    },
}));
