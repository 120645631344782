import { Theme } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";

import { TxtColor } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

interface Props {
    inner: boolean;
}

const useFullscreenLoaderStyles = makeSharedStyles<Theme, Props>({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        minHeight: ({ inner }) => (inner ? "50vh" : "100vh"),
        backgroundColor: ({ inner }) => (inner ? alpha(TxtColor.Txt8, 0.6) : "unset"),
    },
});

export default useFullscreenLoaderStyles;
