import { last } from "lodash";

const getValidationFieldName = (fieldName: string): string => {
    const levels = fieldName.split(".").map(prop => prop.charAt(0).toLowerCase() + prop.substring(1));

    if (!levels) {
        return "";
    }

    return last(levels) as string;
};

export default getValidationFieldName;
