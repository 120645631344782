import React, { useEffect, useMemo } from "react";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";

import { FullscreenLoader } from "components/fullscreenLoader";
import { findRouteByName } from "shared/routes/routes.utils";

import BasicProtectedLayout from "app/layout/protected/BasicProtectedLayout";
import BasicPublicLayout from "app/layout/public/BasicPublicLayout";
import { LayoutType } from "app/layout/store";
import { useRootStore } from "app/useRootStore";

export interface LayoutProps {
    children: NonNullable<React.ReactNode>;
}

const Layout = ({ children }: LayoutProps) => {
    const {
        rootStore: {
            layoutStore: { currentLayout, changeLayoutType },
            authStore: { isAuthenticated, requiresMissingDetails },
            menuStore: { requestUserProfile, fetchMenuItems, menuItems, profileData },
        },
    } = useRootStore();
    const location = useLocation();

    const selectedLayout = useMemo(() => currentLayout, [currentLayout]);

    useEffect(() => {
        const route = findRouteByName(location.pathname);

        if (!route) {
            return changeLayoutType(LayoutType.BasicLayout);
        }

        changeLayoutType(route.layout || LayoutType.BasicLayout);
    }, [changeLayoutType, location]);

    useEffect(() => {
        if (isAuthenticated && !requiresMissingDetails) {
            Promise.all([requestUserProfile(), fetchMenuItems()]);
        }
    }, [fetchMenuItems, isAuthenticated, requestUserProfile, requiresMissingDetails]);

    if (selectedLayout === LayoutType.FullWidthLayout || !isAuthenticated) {
        return <BasicPublicLayout>{children}</BasicPublicLayout>;
    }

    if (!requiresMissingDetails && isAuthenticated && (!profileData || !menuItems.length)) {
        return <FullscreenLoader />;
    }

    return <BasicProtectedLayout>{children}</BasicProtectedLayout>;
};

export default observer(Layout);
