import {
    AuthorizationServer,
    Client,
    calculatePKCECodeChallenge,
    generateRandomCodeVerifier,
    isOAuth2Error,
    validateAuthResponse,
} from "oauth4webapi";

import {
    AuthorizeEndpointResponse,
    ClientSettings,
    GetAuthorizeUrlParams,
    GetTokenFromCodeRedirectParams,
    OAuth2Token,
} from "shared/api/oauth/interfaces";
import { postConnectToken } from "shared/api/swagger/oauth/auth/postConnectToken";
import { OAuthEndpoints } from "shared/constants/authConstants";

export class OAuth2Client {
    private readonly settings: ClientSettings;
    private readonly client: Client;
    private readonly as: AuthorizationServer;

    constructor(settings: ClientSettings) {
        this.settings = settings;
        this.client = {
            client_id: settings.clientId,
        };
        this.as = {
            issuer: this.settings.server,
            authorization_endpoint: this.settings.authorizationEndpoint,
        };
    }

    public async getAuthorizeUri({
        redirectUri,
        extraParams,
    }: GetAuthorizeUrlParams): Promise<AuthorizeEndpointResponse> {
        const authorizationEndpoint = new URL(this.settings.authorizationEndpoint, this.settings.server);

        const state = crypto.randomUUID();
        const codeVerifier = generateRandomCodeVerifier();
        const codeChallenge = await calculatePKCECodeChallenge(codeVerifier);
        const codeChallengeMethod = "S256";

        const authorizationUrl = new URL(authorizationEndpoint);
        authorizationUrl.searchParams.set("client_id", this.settings.clientId);
        authorizationUrl.searchParams.set("code_challenge", codeChallenge);
        authorizationUrl.searchParams.set("code_challenge_method", codeChallengeMethod);
        authorizationUrl.searchParams.set("redirect_uri", redirectUri);
        authorizationUrl.searchParams.set("response_type", "code");
        authorizationUrl.searchParams.set("state", state);

        if (extraParams) {
            for (const [key, value] of Object.entries(extraParams)) {
                authorizationUrl.searchParams.set(key, value);
            }
        }

        return {
            state,
            codeVerifier,
            url: authorizationUrl.toString(),
        };
    }

    public async getTokenFromCodeRedirect({
        url,
        state,
        codeVerifier,
        redirectUri,
    }: GetTokenFromCodeRedirectParams): Promise<OAuth2Token> {
        const params = validateAuthResponse(this.as, this.client, new URL(url), state);
        if (isOAuth2Error(params)) {
            console.log("error", params);
            throw new Error("Invalid url");
        }

        const code = this.getURLSearchParameter(params, "code");
        if (!code) {
            throw new Error("no authorization code in url");
        }

        const connectResponse = await postConnectToken(
            {
                grant_type: "authorization_code",
                client_id: this.client.client_id,
                code,
                code_verifier: codeVerifier,
                redirect_uri: redirectUri,
            },
            { withCredentials: true }
        );

        return {
            idToken: connectResponse.id_token,
            accessToken: connectResponse.access_token,
            refreshToken: connectResponse.refresh_token,
        };
    }

    public async exchangeToken(token: string, tokenType: string): Promise<OAuth2Token> {
        const connectResponse = await postConnectToken(
            {
                grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
                client_id: this.client.client_id,
                subject_token: token,
                subject_token_type: tokenType,
            },
            { withCredentials: true }
        );

        return {
            idToken: connectResponse.id_token,
            accessToken: connectResponse.access_token,
            refreshToken: connectResponse.refresh_token,
        };
    }

    public async refreshToken({ refreshToken }: OAuth2Token): Promise<OAuth2Token> {
        const connectResponse = await postConnectToken(
            {
                grant_type: "refresh_token",
                client_id: this.client.client_id,
                refresh_token: refreshToken,
            },
            { withCredentials: true }
        );

        return {
            idToken: connectResponse.id_token,
            accessToken: connectResponse.access_token,
            refreshToken: connectResponse.refresh_token,
        };
    }

    public getEndSessionUri(oauthToken: OAuth2Token): string {
        const url = new URL(OAuthEndpoints.EndSession, this.settings.server);
        url.searchParams.append("id_token_hint", oauthToken.idToken);
        return url.toString();
    }

    private getURLSearchParameter(parameters: URLSearchParams, name: string): string | undefined {
        const { 0: value, length } = parameters.getAll(name);
        if (length > 1) {
            throw new Error(`"${name}" parameter must be provided only once`);
        }
        return value;
    }
}
