import { Action, History, Location } from "history";
import { action, makeObservable, observable } from "mobx";

const CHECK_LOCATION_INTERVAL = 1000;

export class RouterStore {
    @observable public historyIndex;
    @observable private currentLocationKey?: string;

    private history: History;

    constructor(history: History) {
        makeObservable(this);
        this.history = history;

        if (this.history.action !== "POP") {
            this.addLocation(this.history.location, "PUSH");
        }

        this.historyIndex = this.history.length > 1 ? this.history.length - 1 : 0;
    }

    @action.bound
    push(path: string): void {
        this.history.push(path);
    }

    @action.bound
    goBack(): void {
        const key = this.currentLocationKey;

        setTimeout(() => {
            if (key === this.currentLocationKey) {
                this.setHistoryIndex(0);
            }
        }, CHECK_LOCATION_INTERVAL);

        this.history.goBack();
    }

    @action.bound
    setHistoryIndex(index: number): void {
        this.historyIndex = index < 0 ? 0 : index;
    }

    @action.bound
    addLocation(location: Location, historyAction: Action): void {
        // Please don't remove, we need it for back-button rerender
        this.currentLocationKey = location.key;
        if (historyAction === "POP") {
            this.setHistoryIndex(this.historyIndex - 1);
        } else if (historyAction === "PUSH") {
            this.setHistoryIndex(this.historyIndex + 1);
        }
    }
}
