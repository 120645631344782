export const AVENGERS_TOKEN_TYPE = "avengers_auth_token";
export const LOCK_NAME = "refreshTokenLock";

export enum OAuthEndpoints {
    Token = "/connect/token",
    Authorize = "/connect/authorize",
    EndSession = "/connect/endsession",
}

export enum AcrValues {
    Token = "avengers_auth_token",
    AccountId = "account_id",
}

export const VIEW_USER_PROFILE_CACHE_LIFETIME_SECONDS = window.APP_SETTINGS.viewUserProfileCacheLifetimeSeconds * 1000;
