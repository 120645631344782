import React, { useCallback } from "react";

import includes from "lodash/includes";
import { observer } from "mobx-react-lite";
import { Redirect, Route, RouteProps, match, useParams } from "react-router";

import routeNames from "shared/routes/constants/routeNames";
import RouteValue, { RouteComponent } from "shared/routes/models/routesValue";
import { findRouteByName, isRoutePermitted } from "shared/routes/routes.utils";

import { useRootStore } from "app/useRootStore";

interface PrivateRouteProps extends RouteProps {
    route?: RouteValue;
}

const DEFAULT_PARAM_AS_KEY = "id";

interface RouteWithKeyProps {
    paramAsKey: string | undefined;
    component: PrivateRouteProps["component"];
}

const RouteWithKey = ({ paramAsKey, component }: RouteWithKeyProps) => {
    const params = useParams<Record<string, string>>();
    const paramName = paramAsKey ?? DEFAULT_PARAM_AS_KEY;
    const key = String(params[paramName]);
    return React.createElement(component!, { key });
};

export const PrivateRoute = observer(function PrivateRoute<T extends PrivateRouteProps = PrivateRouteProps>({
    component,
    route: definedRoute,
    ...rest
}: T) {
    const {
        rootStore: {
            authStore: {
                isAuthenticated,
                isLoggedOutByAnotherTab,
                isLoggedOutBySwitch,
                accountTypeId,
                userPermissions,
                requiresMissingDetails,
            },
        },
    } = useRootStore();

    const processRouteComponent = useCallback(
        (location: Location, routeComponent?: RouteComponent, paramAsKey?: string) => {
            if (routeComponent && !isRoutePermitted(routeComponent, userPermissions)) {
                return <Redirect to={{ pathname: routeNames.ERRORS.FORBIDDEN, state: { from: location } }} />;
            }
            return <RouteWithKey component={routeComponent?.component || component} paramAsKey={paramAsKey} />;
        },
        [component, userPermissions]
    );

    const privateRouteElement = useCallback(
        ({ location, match: { path } }: { location: Location; match: match }) => {
            if (!isAuthenticated && path !== routeNames.LOGOUT.ROOT && path !== routeNames.LOGGED_OUT) {
                if (isLoggedOutBySwitch) {
                    return;
                }
                return isLoggedOutByAnotherTab ? (
                    <Redirect to={routeNames.LOGGED_OUT} />
                ) : (
                    <Redirect to={routeNames.LOGIN.ROOT} />
                );
            }

            if (requiresMissingDetails && path !== routeNames.MISSING_DETAILS && path !== routeNames.LOGOUT.ROOT) {
                return (
                    <Redirect
                        to={{ pathname: routeNames.MISSING_DETAILS, search: `?returnUrl=${location.pathname}` }}
                    />
                );
            }

            const route = definedRoute || findRouteByName(location.pathname);

            if (route && accountTypeId && route.routeByAccountType && route.routeByAccountType[accountTypeId]) {
                return processRouteComponent(location, route.routeByAccountType[accountTypeId], route?.paramAsKey);
            }
            if (route && route.onlyForAccountTypes && !includes(route.onlyForAccountTypes, accountTypeId)) {
                return <Redirect to={{ pathname: routeNames.ERRORS.NOT_FOUND, state: { from: location } }} />;
            }
            return processRouteComponent(location, route, route?.paramAsKey);
        },
        [
            isLoggedOutBySwitch,
            isAuthenticated,
            requiresMissingDetails,
            definedRoute,
            accountTypeId,
            processRouteComponent,
            isLoggedOutByAnotherTab,
        ]
    );

    return <Route {...rest} render={privateRouteElement} />;
});
