/**
 * Summary: Remove all the notifications for the authorized user.
 *
 * Tags: Notifications
 */
import { AxiosRequestConfig } from "axios";

import { httpDelete } from "shared/api/apiHandler";

export const API_DELETE_NOTIFICATIONS_URL = "api/v1/notifications";

export const apiDeleteNotifications = async (config?: AxiosRequestConfig): Promise<ApiDeleteNotificationsResponse> => {
    const result = await httpDelete<ApiDeleteNotificationsResponse>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_DELETE_NOTIFICATIONS_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiDeleteNotificationsResponse = void;
