import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { useIntercom } from "react-use-intercom";

import IntercomLauncher from "components/bootstrapIntercom/launcher";
import { IntercomStore } from "components/bootstrapIntercom/store";
import { INTERCOM_SETTINGS } from "shared/constants/appConstants";
import { useStore } from "shared/hooks/useStore";
import { useUpdateEffect } from "shared/hooks/useUpdateEffect";

import { useRootStore } from "app/useRootStore";

const BootstrapIntercom = observer((): JSX.Element | null => {
    const {
        rootStore: {
            isAdmin,
            authStore: { isAuthenticated },
        },
    } = useRootStore();
    const {
        store: { isOpen, isChatOnAir, setChatOnAir, isChatShouldBeBooted },
    } = useStore(IntercomStore);
    const { boot, shutdown, hide } = useIntercom();

    useUpdateEffect(() => {
        if (!isOpen) {
            hide();
        }
    }, [isOpen, hide]);

    useEffect(() => {
        if (!INTERCOM_SETTINGS.isIntercomIdExist) {
            return;
        }

        if (!isAuthenticated) {
            if (isChatOnAir) {
                shutdown();
                setChatOnAir(false);
            }
            return;
        }

        if (!isChatOnAir) {
            if (isChatShouldBeBooted) {
                boot({
                    hideDefaultLauncher: true,
                    customLauncherSelector: "#intercom_launcher",
                    horizontalPadding: 20,
                    verticalPadding: 100,
                });
            }

            setChatOnAir(true);
        }
    }, [isAdmin, isChatOnAir, setChatOnAir, isAuthenticated, shutdown, boot, isChatShouldBeBooted]);

    useEffect(() => {
        if (!INTERCOM_SETTINGS.isIntercomIdExist) {
            return;
        }

        return () => {
            shutdown();
        };
    }, [shutdown]);

    return isChatShouldBeBooted ? <IntercomLauncher /> : null;
});

const IntercomClientIdGuard = (): JSX.Element | null => {
    if (!window.APP_SETTINGS.intercomClientId) {
        return null;
    }

    return <BootstrapIntercom />;
};

export default IntercomClientIdGuard;
