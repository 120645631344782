import { usePublicStyles } from "./basicPublicLayout.styles";

interface BasicPublicLayoutProps {
    children: React.ReactNode;
}

const BasicPublicLayout: React.FC<BasicPublicLayoutProps> = ({ children }: BasicPublicLayoutProps): JSX.Element => {
    const publicStyles = usePublicStyles();
    return (
        <div className={publicStyles.root} data-testid="basic-public-layout">
            {children}
        </div>
    );
};

export default BasicPublicLayout;
