import { NOTIFICATIONS_LIST_MAX_HEIGHT_PX } from "shared/constants/appConstants";
import {
    TxtColor,
    andLandscapeOrientation,
    andPortraitOrientation,
    applicationShadowPalettes,
    mediumBreakpoint,
    tabletBreakpoint,
} from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useNotificationsButtonStyles = makeSharedStyles(theme => ({
    root: {
        boxShadow: applicationShadowPalettes.primary[24],
        borderRadius: theme.spacing(),
        border: `1px solid ${TxtColor.Txt7}`,
        overflow: "hidden",
    },
}));

export const useNotificationsListStyles = makeSharedStyles(theme => ({
    notificationsList: ({ hasNotification }: { hasNotification: boolean }) => ({
        display: hasNotification ? "flex" : "initial",
        flex: "1 1 auto",
        overflow: "hidden",

        [`${theme.breakpoints.up(tabletBreakpoint)} ${andLandscapeOrientation}`]: {
            maxHeight: "calc(100vh - 100px)",
        },
        [`${theme.breakpoints.up(tabletBreakpoint)} ${andPortraitOrientation}`]: {
            maxHeight: NOTIFICATIONS_LIST_MAX_HEIGHT_PX,
        },
        [theme.breakpoints.up(mediumBreakpoint)]: {
            maxHeight: NOTIFICATIONS_LIST_MAX_HEIGHT_PX,
        },
    }),
}));

export const useBadgeStyles = makeSharedStyles({
    badge: {
        fontSize: ".75rem",
        lineHeight: "1rem",
        top: 6,
        right: 6,
    },
});

export const usePopoverClasses = makeSharedStyles({
    paper: {
        overflowY: "hidden",
    },
});

export const useFullScreenPopoverClasses = makeSharedStyles({
    paper: {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        height: "100%",
        top: `0px !important`,
        left: `0px !important`,
    },
});
