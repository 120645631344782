import { useEffect, useMemo, useRef, useState } from "react";

type ClickHandler = ((e: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>) | undefined;

export const useDoubleClickPreventer = (onClick: ClickHandler): [boolean, ClickHandler] => {
    const [isClickProcessing, setClickProcessing] = useState(false);
    const didMountRef = useRef(false);

    useEffect(() => {
        didMountRef.current = true;
        return () => {
            didMountRef.current = false;
        };
    }, []);

    const clickHandler = useMemo((): ClickHandler => {
        if (!onClick) {
            return undefined;
        }
        return async e => {
            if (isClickProcessing) {
                return;
            }
            try {
                setClickProcessing(true);
                await onClick(e);
            } finally {
                if (didMountRef.current) {
                    setClickProcessing(false);
                }
            }
        };
    }, [isClickProcessing, onClick]);

    return [isClickProcessing, clickHandler];
};
