import axios from "axios";

import { CHECK_LOCALIZATION_VERSION_URL, LOCALIZATION_URL_PREFIX } from "localization/constants";
import { LocalizedComponentName } from "localization/enums";
import { LocalizationMessages } from "shared/models/staticCms/localizationMessages";
import { LocalizationVersion } from "shared/models/staticCms/localizationVersion";

const staticCmsApi = axios.create({
    baseURL: window.APP_SETTINGS.staticCmsApiBaseUri,
});

export const getLocalizationMessages = async (componentName: LocalizedComponentName): Promise<LocalizationMessages> => {
    const response = await staticCmsApi.get<LocalizationMessages>(`${LOCALIZATION_URL_PREFIX}${componentName}`, {
        params: {
            "pagination[limit]": -1,
        },
    });

    return response.data;
};

export const getLocalizationVersion = async (): Promise<LocalizationVersion> => {
    const response = await staticCmsApi.get<LocalizationVersion>(CHECK_LOCALIZATION_VERSION_URL);

    return response.data;
};
