export const formatTimer = (time: Duration) => {
    if (time.minutes) {
        return `${time.minutes * 60 + (time.seconds || 0)}`;
    }
    if (!time.seconds) {
        return "00";
    } else if (time.seconds < 10) {
        return `0${time.seconds}`;
    } else {
        return `${time.seconds}`;
    }
};
