import { countBy, orderBy, some } from "lodash";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { matchPath } from "react-router";

import { formatProfileAccountType } from "components/userProfile/helpers";
import { ProfileInfo, ProfileShortAccountDetails } from "components/userProfile/interfaces";
import { apiGetMenu } from "shared/api/swagger/spaceStone/menu/apiGetMenu";
import { apiGetProfile } from "shared/api/swagger/spaceStone/profile/apiGetProfile";
import { apiGetProfileAccounts } from "shared/api/swagger/spaceStone/profile/apiGetProfileAccounts";
import { NO_VALUE_PLACEHOLDER } from "shared/constants/appConstants";
import { AccountType, AccountUserStatus } from "shared/constants/enums";
import { formatUserNameWithoutDash } from "shared/helpers/formatUserName";
import routeNames from "shared/routes/constants/routeNames";

import { MenuItem } from "app/layout/interfaces";
import { RootStore } from "app/rootStore";

export class MenuStore {
    @observable public profileData?: ProfileInfo;
    @observable public linkedAccounts: ProfileShortAccountDetails[] = [];

    @observable public menuItems: MenuItem[] = [];

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this);
    }

    @computed
    get avatarUrl(): string | undefined {
        return this.profileData?.photo?.downloadUrl;
    }

    @computed
    get firstName(): string {
        return this.profileData?.firstName || NO_VALUE_PLACEHOLDER;
    }

    @computed
    get lastName(): string {
        return this.profileData?.lastName || NO_VALUE_PLACEHOLDER;
    }

    @computed
    get fullName(): string {
        const { firstName, lastName } = this.profileData ?? {};
        return formatUserNameWithoutDash(this.rootStore.localizationStore.localize, firstName, lastName);
    }

    @computed
    get hasMultipleAccounts(): boolean {
        return this.linkedAccounts.length > 1;
    }

    @computed
    get hasMultipleAccountsOfSameType(): boolean {
        return some(
            countBy(this.linkedAccounts, it => it.accountType.id),
            it => it > 1
        );
    }

    @computed
    get activeLinkedAccounts(): ProfileShortAccountDetails[] {
        return this.linkedAccounts.filter(it => it.accountUserStatus.id === AccountUserStatus.Active);
    }

    @computed
    get userAccountTypesIds(): AccountType[] {
        const accountTypesIdsSet = new Set<AccountType>(this.linkedAccounts.map(it => it.accountType.id));

        const accountTypeOrder = [
            AccountType.Agent,
            AccountType.PrivateLandlord,
            AccountType.CorporateLandlord,
            AccountType.Tenant,
        ];

        return accountTypeOrder.filter(type => accountTypesIdsSet.has(type));
    }

    @computed
    public get currentAccountTypeLabel(): string {
        const currentAccount = this.linkedAccounts.find(
            account => account.accountId === this.rootStore.authStore.accountId
        );
        const hasMultipleAccountsOfSameType =
            this.linkedAccounts.filter(account => account.accountType.id === this.rootStore.authStore.accountTypeId)
                .length > 1;
        if (!currentAccount) {
            return "";
        }

        return formatProfileAccountType(
            currentAccount,
            hasMultipleAccountsOfSameType,
            this.rootStore.localizationStore.localize
        );
    }

    @action.bound
    public async requestUserProfile(): Promise<void> {
        const [profileData, profileAccounts] = await Promise.all([apiGetProfile(), apiGetProfileAccounts()]);
        const linkedAccounts = profileAccounts.accounts || [];

        runInAction(() => {
            this.profileData = profileData;
            this.linkedAccounts = linkedAccounts;
        });
    }

    @action.bound
    public setProfileData(profileData: ProfileInfo): void {
        this.profileData = profileData;
    }

    @action.bound
    public setLinkedAccounts(linkedAccounts: ProfileShortAccountDetails[]): void {
        this.linkedAccounts = linkedAccounts;
    }

    @action.bound
    public async fetchMenuItems(): Promise<void> {
        const response = await apiGetMenu();
        this.setMenuItems(response);
    }

    @action.bound
    public isSelectedMenuItem(menuItem: MenuItem, pathname: string): boolean {
        if (!menuItem.route) {
            return false;
        }

        const routes = [menuItem.route];

        // Custom mapping for Tenant routes for the menu item highlighting
        if (this.rootStore.isTenant) {
            const isChangeTenants = matchPath(pathname, { path: routeNames.PROPERTY.TENANCY.CHANGE });
            if (menuItem.route === routeNames.TENANT_HOME.ROOT && isChangeTenants) {
                return true;
            }

            if (menuItem.route === routeNames.DEPOSITS.ROOT) {
                if (isChangeTenants) {
                    return false;
                }
                routes.push(routeNames.PROPERTIES.ROOT);
            }
        }

        return routes.some(route => pathname.startsWith(route) || pathname.startsWith(`/${route}`));
    }

    @action.bound
    private setMenuItems(menuItems: MenuItem[]): void {
        this.menuItems = orderBy(menuItems, menuItem => menuItem.sortOrder);
    }
}
