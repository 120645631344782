import React from "react";

import { escapeRegExp } from "lodash";

import { CompanyDetails, FormCompanyDetails } from "components/userDetails/interfaces/companyDetails";
import { BadRequestError } from "shared/api/errors";
import { ExpiredLinkErrors } from "shared/constants/errorsCodes";
import ButtonProgressSizeControl from "shared/interfaces/buttons/buttonProgress/buttonProgress";
import { DialogProps, DialogSizeControl } from "shared/interfaces/container/dialog/dialog";
import ElementPaddingControl from "shared/interfaces/elementPaddingControl";
import ElementWidthControl from "shared/interfaces/elementWidthControl";
import { PhoneNumberWidthControl } from "shared/interfaces/inputs/phoneNumber/widthControl";
import routeNames from "shared/routes/constants/routeNames";
import theme from "shared/theme/defaultTheme";

export const getTextFieldWidthsBySize = (size: string): ElementWidthControl => {
    if (size === "auto") {
        return { width: "100%", maxWidth: "360px", minWidth: "168px" };
    }
    if (size === "small") {
        return { width: "168px", maxWidth: "168px", minWidth: "168px" };
    }
    if (size === "extra-small") {
        return { width: "100%", maxWidth: "168px", minWidth: "98px" };
    }
    if (size === "tiny") {
        return { width: "100%", maxWidth: "auto", minWidth: "auto" };
    }
    if (size === "full") {
        return { width: "100%", maxWidth: "auto", minWidth: "auto" };
    }
    return { width: "360px", maxWidth: "360px", minWidth: "360px" };
};

export const getTextFieldPaddingBySize = (size: string): ElementPaddingControl => {
    if (size === "extra-small") {
        return { padding: theme.spacing(1, 1.25), height: "16px" };
    }
    if (size === "tiny") {
        return { padding: theme.spacing(0.5, 1), height: "24px" };
    }
    return { padding: theme.spacing(1, 2), height: "32px" };
};

export const getContainerWidthsBySize = (size: string): ElementWidthControl => {
    if (size === "full") {
        return { width: "100%", maxWidth: "100%", minWidth: "100%" };
    }
    if (size === "auto") {
        return { width: "100%", maxWidth: "752px", minWidth: "168px" };
    }
    if (size === "small") {
        return { width: "168px", maxWidth: "168px", minWidth: "168px" };
    }
    if (size === "medium") {
        return { width: "460px", maxWidth: "460px", minWidth: "460px" };
    }
    if (size === "big") {
        return { width: "752px", maxWidth: "752px", minWidth: "752px" };
    }
    return { width: "460px", maxWidth: "460px", minWidth: "460px" };
};

export const getPhoneNumberWidthsBySize = (size: string): PhoneNumberWidthControl => {
    if (size === "auto") {
        return { width: "100%", maxWidth: "360px", minWidth: "168px" };
    }
    return { width: "360px", maxWidth: "360px", minWidth: "360px" };
};

export const getPointLineWidthsBySize = (size: string): ElementWidthControl => {
    if (size === "auto") {
        return { width: "100%", maxWidth: "100%", minWidth: "168px" };
    }
    if (size === "small") {
        return { width: "168px", maxWidth: "168px", minWidth: "168px" };
    }
    return { width: "300px", maxWidth: "300px", minWidth: "300px" };
};

export const getButtonSpinnerDimensionsBySize = (size: string): ButtonProgressSizeControl => {
    if (size === "l") {
        return { width: "2rem", height: "2rem", margin: "-1rem 0 0 -1rem" };
    }
    if (size === "m") {
        return { width: "1.75rem", height: "1.75rem", margin: "-0.875rem 0 0 -0.875rem" };
    }
    if (size === "s") {
        return { width: "1.5rem", height: "1.5rem", margin: "-0.725rem 0 0 -0.725rem" };
    }

    return { width: "1.75rem", height: "1.75rem", margin: "-0.875rem 0 0 -0.875rem" };
};

export const getDialogWidthBySize = (size: DialogProps["size"]): DialogSizeControl => {
    switch (size) {
        case "wide":
            return { maxWidth: "100%" };
        case "large":
            return { maxWidth: "800px" };
        case "medium":
            return { maxWidth: "450px" };
        case "small":
            return { maxWidth: "424px" };
        default:
            return { maxWidth: "680px" };
    }
};

export const getExpiredOrErrorPageRoute = (error: BadRequestError, expiredRoute: string, token: string): string => {
    if (error.errors.some(it => it.code === ExpiredLinkErrors.ChangeEmailRequestIsExpired)) {
        return `${expiredRoute}?token=${token}`;
    }

    return routeNames.ERRORS.NOT_FOUND;
};

export const getBoldHighlighted = (str: string | undefined, searchStr: string | undefined): React.ReactNode => {
    if (!str || !searchStr) {
        return str;
    }

    const parsedSearchString = escapeRegExp(searchStr);
    const parts = str.split(new RegExp(`(${parsedSearchString})`, "gi"));
    if (parts.length === 1) {
        return str;
    }

    return (
        <>
            {parts.map((part, index) =>
                part && part.toLowerCase() === parsedSearchString.toLowerCase() ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <b key={`${part}-${index}`}>{part}</b>
                ) : (
                    part
                )
            )}
        </>
    );
};

export const getAdaptedCompanyData = (data: FormCompanyDetails): CompanyDetails => ({
    ...data,
    companyRegistrationNumber: data.companyRegistrationNumber
        ? data.companyRegistrationNumber.companyRegistrationNumber
        : "",
});

export const getFileExtension = (fileName: string): string => {
    const fileNameParts = fileName.split(".");

    return fileNameParts.length ? fileNameParts.pop()! : "";
};

export const addFileExtension = (fileName: string, extension: string): string => {
    const fileNameParts = fileName.split(".");

    if (fileNameParts.length == 2) {
        fileNameParts.pop();
    }

    return `${fileName}.${extension}`;
};
