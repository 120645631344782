import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default (): void => {
    if (!window.SYSTEM_SETTINGS || !window.SYSTEM_SETTINGS.appInsightsConnectionString) {
        // eslint-disable-next-line no-console
        console.warn("Application Insights connection string is not provided.");
        return;
    }

    const appInsights = new ApplicationInsights({
        config: {
            disableFlushOnBeforeUnload: true,
            connectionString: window.SYSTEM_SETTINGS.appInsightsConnectionString,
        },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
};
