/**
 * Summary: Get basket items count.
 *
 * Tags: Basket
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_BASKET_ITEMS_COUNT_URL = "api/v1/basket/items/count";

export const apiGetBasketItemsCount = async (config?: AxiosRequestConfig): Promise<ApiGetBasketItemsCountResponse> => {
    const result = await httpGet<ApiGetBasketItemsCountResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_BASKET_ITEMS_COUNT_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetBasketItemsCountResponse = GetBasketItemsCountResponse;

interface GetBasketItemsCountResponse {
    totalItems: number; // Basket items count. Example: 15
}
