import {
    Color as MuiColor,
    createTheme as createMuiTheme,
    unstable_createMuiStrictModeTheme as createStrictModeTheme,
} from "@material-ui/core";
import createBreakpoints, { keys } from "@material-ui/core/styles/createBreakpoints";
import shadows, { Shadows } from "@material-ui/core/styles/shadows";
import { CSSProperties } from "@material-ui/styles/withStyles";

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        desktop: 1528,
        xl: 1920,
    },
});
keys.push(keys.splice(keys.length - 1, 1, "desktop").pop()!);

export interface Color extends Omit<MuiColor, "A100" | "A200" | "A400" | "A700"> {
    1: string;
    2: string;
    6: string;
}
export type ApplicationColorPalette =
    | "primary"
    | "red"
    | "orange"
    | "lightBlue"
    | "green"
    | "default"
    | "indigo"
    | "blueViolet"
    | "cyan"
    | "pink"
    | "yellow"
    | "lime";
export type ShadowsPaletteKey = 1 | 2 | 4 | 8 | 16 | 24;
export type ApplicationShadowBackground = "bg1" | "bg2";
export type ShadowBackgroundKey = 8 | 24;
export type ShadowsPalette = { [key in ShadowsPaletteKey]: string };

export const mobileBreakpoint = "xs";
export const tabletBreakpoint = "sm";
export const mediumBreakpoint = "md";
export const largeBreakpoint = "lg";
export const desktopBreakpoint = "desktop";
export const extraLargeBreakpoint = "xl";

export const portraitOrientation = "@media (orientation: portrait)";
export const andPortraitOrientation = "and (orientation: portrait)";
export const landscapeOrientation = "@media (orientation: landscape)";
export const andLandscapeOrientation = "and (orientation: landscape)";

export const elementWidthSize = {
    default: "360px",
    small: "168px",
    medium: "460px",
    big: "752px",
};

export const applicationShadowPalettes: { [key in ApplicationColorPalette]: ShadowsPalette } = {
    primary: {
        "1": "0px 0px 1px rgba(27, 78, 163, 0.16), 0px 2px 2px rgba(41, 121, 255, 0.16)",
        "2": "0px 1px 2px rgba(27, 78, 163, 0.2), 0px 2px 4px rgba(41, 121, 255, 0.2)",
        "4": "0px 2px 4px rgba(27, 78, 163, 0.12), 0px 4px 8px rgba(41, 121, 255, 0.12)",
        "8": "0px 4px 8px rgba(27, 78, 163, 0.1), 0px 8px 16px rgba(41, 121, 255, 0.1)",
        "16": "0px 8px 16px rgba(27, 78, 163, 0.1), 0px 16px 32px rgba(41, 121, 255, 0.1)",
        "24": "0px 12px 24px rgba(27, 78, 163, 0.08), 0px 24px 48px rgba(41, 121, 255, 0.08)",
    },
    red: {
        "1": "0px 0px 1px rgba(156, 43, 35, 0.16), 0px 2px 2px rgba(244, 67, 54, 0.16)",
        "2": "0px 1px 2px rgba(156, 43, 35, 0.2), 0px 2px 4px rgba(244, 67, 54, 0.2)",
        "4": "0px 2px 4px rgba(156, 43, 35, 0.12), 0px 4px 8px rgba(244, 67, 54, 0.12)",
        "8": "0px 4px 8px rgba(156, 43, 35, 0.1), 0px 8px 16px rgba(244, 67, 54, 0.1)",
        "16": "0px 8px 16px rgba(156, 43, 35, 0.1), 0px 16px 32px rgba(244, 67, 54, 0.1)",
        "24": "0px 12px 24px rgba(156, 43, 35, 0.08), 0px 24px 48px rgba(244, 67, 54, 0.08)",
    },
    orange: {
        "1": "0px 0px 1px rgba(160, 83, 0, 0.16), 0px 2px 2px rgba(251, 130, 0, 0.16)",
        "2": "0px 1px 2px rgba(160, 83, 0, 0.2), 0px 2px 4px rgba(251, 130, 0, 0.2)",
        "4": "0px 2px 4px rgba(160, 83, 0, 0.12), 0px 4px 8px rgba(251, 130, 0, 0.12)",
        "8": "0px 4px 8px rgba(160, 83, 0, 0.1), 0px 8px 16px rgba(251, 130, 0, 0.1)",
        "16": "0px 8px 16px rgba(160, 83, 0, 0.1), 0px 16px 32px rgba(251, 130, 0, 0.1)",
        "24": "0px 12px 24px rgba(160, 83, 0, 0.08), 0px 24px 48px rgba(251, 130, 0, 0.08)",
    },
    lightBlue: {
        "1": "0px 0px 1px rgba(2, 136, 209, 0.16), 0px 2px 2px rgba(3, 169, 244, 0.16)",
        "2": "0px 1px 2px rgba(2, 136, 209, 0.2), 0px 2px 4px rgba(3, 169, 244, 0.2)",
        "4": "0px 2px 4px rgba(2, 136, 209, 0.12), 0px 4px 8px rgba(3, 169, 244, 0.12)",
        "8": "0px 4px 8px rgba(2, 136, 209, 0.1), 0px 8px 16px rgba(3, 169, 244, 0.1)",
        "16": "0px 8px 16px rgba(2, 136, 209, 0.1), 0px 16px 32px rgba(3, 169, 244, 0.1)",
        "24": "0px 12px 24px rgba(2, 136, 209, 0.08), 0px 24px 48px rgba(3, 169, 244, 0.08)",
    },
    green: {
        "1": "0px 0px 1px rgba(43, 102, 46, 0.16), 0px 2px 2px rgba(67, 160, 71, 0.16)",
        "2": "0px 1px 2px rgba(43, 102, 46, 0.2), 0px 2px 4px rgba(67, 160, 71, 0.2)",
        "4": "0px 2px 4px rgba(43, 102, 46, 0.12), 0px 4px 8px rgba(67, 160, 71, 0.12)",
        "8": "0px 4px 8px rgba(43, 102, 46, 0.1), 0px 8px 16px rgba(67, 160, 71, 0.1)",
        "16": "0px 8px 16px rgba(43, 102, 46, 0.1), 0px 16px 32px rgba(67, 160, 71, 0.1)",
        "24": "0px 12px 24px rgba(43, 102, 46, 0.08), 0px 24px 48px rgba(67, 160, 71, 0.08)",
    },
    default: {
        "1": "0px 0px 1px rgba(58, 58, 68, 0.16), 0px 2px 2px rgba(90, 91, 106, 0.16)",
        "2": "0px 1px 2px rgba(58, 58, 68, 0.2), 0px 2px 4px rgba(90, 91, 106, 0.2)",
        "4": "0px 2px 4px rgba(58, 58, 68, 0.12), 0px 4px 8px rgba(90, 91, 106, 0.12)",
        "8": "0px 4px 8px rgba(58, 58, 68, 0.1), 0px 8px 16px rgba(90, 91, 106, 0.1)",
        "16": "0px 8px 16px rgba(58, 58, 68, 0.1), 0px 16px 32px rgba(90, 91, 106, 0.1)",
        "24": "0px 12px 24px rgba(58, 58, 68, 0.08), 0px 24px 48px rgba(90, 91, 106, 0.08)",
    },
    indigo: {
        "1": "0px 0px 1px rgba(67, 80, 175, 0.16), 0px 2px 2px rgba(51, 63, 153, 0.16)",
        "2": "0px 1px 2px rgba(67, 80, 175, 0.2), 0px 2px 4px rgba(51, 63, 153, 0.2)",
        "4": "0px 2px 4px rgba(51, 63, 153, 0.12), 0px 4px 8px rgba(67, 80, 175, 0.12)",
        "8": "0px 4px 8px rgba(51, 63, 153, 0.1), 0px 8px 16px rgba(67, 80, 175, 0.1)",
        "16": "0px 8px 16px rgba(51, 63, 153, 0.1), 0px 16px 32px rgba(67, 80, 175, 0.1)",
        "24": "0px 12px 24px rgba(51, 63, 153, 0.08), 0px 24px 48px rgba(67, 80, 175, 0.08)",
    },
    blueViolet: {
        "1": "0px 0px 1px rgba(101, 43, 189, 0.16), 0px 2px 2px rgba(126, 54, 236, 0.16)",
        "2": "0px 1px 2px rgba(101, 43, 189, 0.2), 0px 2px 4px rgba(126, 54, 236, 0.2)",
        "4": "0px 2px 4px rgba(101, 43, 189, 0.12), 0px 4px 8px rgba(126, 54, 236, 0.12)",
        "8": "0px 4px 8px rgba(101, 43, 189, 0.1), 0px 8px 16px rgba(126, 54, 236, 0.1)",
        "16": "0px 8px 16px rgba(101, 43, 189, 0.1), 0px 16px 32px rgba(126, 54, 236, 0.1)",
        "24": "0px 12px 24px rgba(101, 43, 189, 0.08), 0px 24px 48px rgba(126, 54, 236, 0.08)",
    },
    cyan: {
        "1": "0px 0px 1px rgba(0, 109, 117, 0.16), 0px 2px 2px rgba(19, 194, 194, 0.16)",
        "2": "0px 1px 2px rgba(0, 109, 117, 0.2), 0px 2px 4px rgba(19, 194, 194, 0.2)",
        "4": "0px 2px 4px rgba(0, 109, 117, 0.12), 0px 4px 8px rgba(19, 194, 194, 0.12)",
        "8": "0px 4px 8px rgba(0, 109, 117, 0.1), 0px 8px 16px rgba(19, 194, 194, 0.1)",
        "16": "0px 8px 16px rgba(0, 109, 117, 0.1), 0px 16px 32px rgba(19, 194, 194, 0.1)",
        "24": "0px 12px 24px rgba(0, 109, 117, 0.08), 0px 24px 48px rgba(19, 194, 194, 0.08)",
    },
    pink: {
        "1": "0px 0px 1px rgba(107, 21, 90, 0.16), 0px 2px 2px rgba(204, 29, 146, 0.16)",
        "2": "0px 1px 2px rgba(107, 21, 90, 0.2), 0px 2px 4px rgba(204, 29, 146, 0.2)",
        "4": "0px 2px 4px rgba(107, 21, 90, 0.12), 0px 4px 8px rgba(204, 29, 146, 0.12)",
        "8": "0px 4px 8px rgba(107, 21, 90, 0.1), 0px 8px 16px rgba(204, 29, 146, 0.1)",
        "16": "0px 8px 16px rgba(107, 21, 90, 0.1), 0px 16px 32px rgba(204, 29, 146, 0.1)",
        "24": "0px 12px 24px rgba(107, 21, 90, 0.08), 0px 24px 48px rgba(204, 29, 146, 0.08)",
    },
    yellow: {
        "1": "0px 0px 1px rgba(249, 214, 20, 0.16), 0px 2px 2px rgba(163, 128, 0, 0.16)",
        "2": "0px 1px 2px rgba(249, 214, 20, 0.2), 0px 2px 4px rgba(163, 128, 0, 0.2)",
        "4": "0px 2px 4px rgba(163, 128, 0, 0.12), 0px 4px 8px rgba(249, 214, 20, 0.12)",
        "8": "0px 4px 8px rgba(163, 128, 0, 0.1), 0px 8px 16px rgba(249, 214, 20, 0.1)",
        "16": "0px 8px 16px rgba(163, 128, 0, 0.1), 0px 16px 32px rgba(249, 214, 20, 0.1)",
        "24": "0px 12px 24px rgba(163, 128, 0, 0.08), 0px 24px 48px rgba(249, 214, 20, 0.08)",
    },
    lime: {
        "1": "",
        "2": "",
        "4": "",
        "8": "0px 4px 8px rgba(176, 180, 71, 0.1), 0px 8px 16px rgba(208, 220, 89, 0.1)",
        "16": "0px 8px 16px rgba(176, 180, 71, 0.1), 0px 16px 32px rgba(208, 220, 89, 0.1)",
        "24": "0px 12px 24px rgba(176, 180, 71, 0.08), 0px 24px 48px rgba(208, 220, 89, 0.08)",
    },
};

type AdditionalShadow = {
    [key in ShadowBackgroundKey]: string;
};

export const appAdditionalShadow: {
    [key in ApplicationShadowBackground]: AdditionalShadow;
} = {
    bg1: {
        "8": "0px 4px 8px rgba(27, 78, 163, 0.04), 0px 8px 16px rgba(41, 121, 255, 0.04)",
        "24": "none",
    },
    bg2: {
        "8": "none",
        "24": "0px 12px 24px rgba(27, 78, 163, 0.04), 0px 24px 48px rgba(41, 121, 255, 0.04)",
    },
};

export const applicationPalettes: {
    [key in ApplicationColorPalette]: Color;
} = {
    primary: {
        "1": "#F5F8FF",
        "2": "#EBF2FF",
        "6": "#5272A8",
        "50": "#D8E6FF",
        "100": "#C4DAFF",
        "200": "#9DC2FF",
        "300": "#76A9FF",
        "400": "#4F91FF",
        "500": "#2979FF",
        "600": "#2264D1",
        "700": "#1B4EA3",
        "800": "#133774",
        "900": "#0C2146",
    },
    red: {
        "1": "#FFF6F6",
        "2": "#FEEDEC",
        "6": "#AB645F",
        "50": "#FDDCDA",
        "100": "#FCCBC8",
        "200": "#FAA9A3",
        "300": "#F8877F",
        "400": "#F6655A",
        "500": "#F44336",
        "600": "#C8372D",
        "700": "#9C2B23",
        "800": "#6F1F19",
        "900": "#43130F",
    },
    orange: {
        "1": "#FFF8EC",
        "2": "#FFEFD1",
        "6": "#A17340",
        "50": "#FEE7CD",
        "100": "#FDDCB9",
        "200": "#FDC68B",
        "300": "#FCAF5C",
        "400": "#FB982E",
        "500": "#FB8200",
        "600": "#CE6B00",
        "700": "#A05300",
        "800": "#733C00",
        "900": "#452400",
    },
    lightBlue: {
        "1": "#F9FDFF",
        "2": "#F0FAFF",
        "6": "#437B96",
        "50": "#E1F5FE",
        "100": "#B3E5FC",
        "200": "#81D4FA",
        "300": "#4FC3F7",
        "400": "#29B6F6",
        "500": "#03A9F4",
        "600": "#039BE5",
        "700": "#0288D1",
        "800": "#0277BD",
        "900": "#01579B",
    },
    green: {
        "1": "#F5FAF5",
        "2": "#ECF7ED",
        "6": "#70805C",
        "50": "#DCEDDD",
        "100": "#CBE5CC",
        "200": "#A9D3AB",
        "300": "#87C289",
        "400": "#65B168",
        "500": "#43A047",
        "600": "#37833B",
        "700": "#2B662E",
        "800": "#1F4921",
        "900": "#132C14",
    },
    default: {
        "1": "#F7F7FA",
        "2": "#EDEDF0",
        "6": "#626262",
        "50": "#E1E1E3",
        "100": "#D2D2D6",
        "200": "#B4B4BB",
        "300": "#9696A0",
        "400": "#787885",
        "500": "#5A5B6A",
        "600": "#4A4B57",
        "700": "#3A3A44",
        "800": "#292A31",
        "900": "#19191D",
    },
    indigo: {
        "1": "#F8F9FC",
        "2": "#F1F2F9",
        "6": "#F1F2F9",
        "50": "#E8EAF5",
        "100": "#C6CAE6",
        "200": "#A1A8D6",
        "300": "#7B86C6",
        "400": "#5F6BBA",
        "500": "#4350AF",
        "600": "#3C49A5",
        "700": "#333F99",
        "800": "#2B358E",
        "900": "#1C2379",
    },
    blueViolet: {
        "1": "#F8F5FE",
        "2": "#F3EBFD",
        "6": "#88698A",
        "50": "#ECE1FC",
        "100": "#D8C3F9",
        "200": "#BF9AF6",
        "300": "#AB7CF3",
        "400": "#985EF0",
        "500": "#7E36EC",
        "600": "#7131D4",
        "700": "#652BBD",
        "800": "#5826A5",
        "900": "#4C208E",
    },
    cyan: {
        "1": "#E6FFFB",
        "2": "#E6FFFB",
        "6": "#E6FFFB",
        "50": "#E6FFFB",
        "100": "#B5F5EC",
        "200": "#87E8DE",
        "300": "#5CDBD3",
        "400": "#36CFC9",
        "500": "#13C2C2",
        "600": "#08979C",
        "700": "#006D75",
        "800": "#00474F",
        "900": "#002329",
    },
    pink: {
        "1": "#FCF0F8",
        "2": "#FCF0F8",
        "6": "#FCF0F8",
        "50": "#FCF0F8",
        "100": "#F7DAED",
        "200": "#F7B7E2",
        "300": "#FA87D4",
        "400": "#ED4CB7",
        "500": "#CC1D92",
        "600": "#961574",
        "700": "#6B155A",
        "800": "#47153F",
        "900": "#241020",
    },
    yellow: {
        "1": "#FFFFF7",
        "2": "#FEFFEE",
        "6": "#FEFFEE",
        "50": "#FEFFE2",
        "100": "#FFFFAF",
        "200": "#FFFA84",
        "300": "#FFF35B",
        "400": "#FFE935",
        "500": "#F9D614",
        "600": "#CEA80A",
        "700": "#A38000",
        "800": "#7C5D00",
        "900": "#563E00",
    },
    lime: {
        "1": "",
        "2": "",
        "6": "",
        "50": "#F9FBE9",
        "100": "",
        "200": "",
        "300": "",
        "400": "",
        "500": "",
        "600": "",
        "700": "#B0B447",
        "800": "",
        "900": "#80772C",
    },
};

export const primaryApplicationFontFamily = "Roboto";
export const montserratFontFamily = "Montserrat";

export const textMainColor = "#0C2146";
export const textSecondColor = "#41495E";
export const whiteColor = "#FFFFFF";
export const bgGrayColor = "#FCFDFF"; // TODO: Move to theme palette

// We should use these colors only for Create and Edit icons
export enum ProtectionTypeIconColors {
    Replacement = "#9CB280",
    Custodial = "#B68CB8",
}

export enum Gradients {
    Gr1 = "linear-gradient(272.95deg, #F5F8FF 0%, #FAFBFF 100%)",
    Gr2 = "linear-gradient(272.95deg, #EBF2FF 0%, #F5F8FF 100%)",
    Gr3 = "linear-gradient(270deg, #EBF2FF 30.61%, rgba(235, 242, 255, 0.00) 60.85%)",
}

export enum TxtColor {
    Txt1 = "#071B3F",
    Txt2 = "#384864",
    Txt3 = "#687388",
    Txt4 = "#818A9C",
    Txt5 = "#A5AEBE",
    Txt6 = "#BAC3D2",
    Txt7 = "#E6ECF5",
    Txt8 = "#FFFFFF",
}

const commonHeadingStyles: CSSProperties = {
    fontFamily: montserratFontFamily,
    color: TxtColor.Txt1,
};

const commonSubtitleStyles: CSSProperties = { fontFamily: montserratFontFamily, color: TxtColor.Txt1 };

const commonBodyStyles: CSSProperties = {
    fontFamily: primaryApplicationFontFamily,
    color: TxtColor.Txt1,
    fontWeight: 400,
};

const commonLargeHeadingStyles: CSSProperties = { ...commonHeadingStyles };
const commonSmallHeadingStyles: CSSProperties = {
    ...commonHeadingStyles,
    letterSpacing: "0.15px",
};

const captionStyles: CSSProperties = {
    fontSize: "0.75rem",
    letterSpacing: "0.4px",
    fontFamily: primaryApplicationFontFamily,
    fontWeight: 400,
    lineHeight: "1rem",
};

const customShadows: Shadows = [...shadows];
customShadows[1] = "0px 0px 1px rgba(58, 58, 68, 0.16), 0px 2px 2px rgba(90, 91, 106, 0.16)";
customShadows[2] = "0px 1px 2px rgba(58, 58, 68, 0.2), 0px 2px 4px rgba(90, 91, 106, 0.2)";
customShadows[3] = "0px -12px 24px rgba(27, 78, 163, 0.04), 0px -24px 48px rgba(41, 121, 255, 0.04)";
customShadows[4] = "0px 2px 4px rgba(58, 58, 68, 0.12), 0px 4px 8px rgba(90, 91, 106, 0.12)";
customShadows[8] = "0px 4px 8px rgba(58, 58, 68, 0.1), 0px 8px 16px rgba(90, 91, 106, 0.1)";
customShadows[16] = "0px 4px 8px rgba(58, 58, 68, 0.1), 0px 8px 16px rgba(90, 91, 106, 0.1)";
customShadows[24] = "0px 12px 24px rgba(58, 58, 68, 0.08), 0px 24px 48px rgba(90, 91, 106, 0.08)";

const createTheme =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? createStrictModeTheme : createMuiTheme;

const theme = createTheme({
    breakpoints,
    shadows: customShadows,
    zIndex: {
        basketControlPaper: 1,
        basketSummaryPanel: 2,
        appBar: 10,
        drawer: 10,
        tabHeader: 100,
        backButton: 101,
        gallery: 2147483005,
        intercomLauncher: 2147482999,
        helplineLauncher: 2147482999,
        // Should be lowered to allow click on chat launcher
        helplineOverlay: 2147482998,
        // Intercom chat has built in zIndex 2147483000, so, general TS chat should have lower
        chat: 2147482998,
        snackbar: 110,
        toast: 2147483006,
        modal: 2147483005,
        tooltip: 2147483007,
    },
    palette: {
        grey: applicationPalettes.default,
        primary: applicationPalettes.primary,
        success: applicationPalettes.green,
        warning: applicationPalettes.orange,
        error: applicationPalettes.red,
    },
    typography: {
        fontFamily: [
            primaryApplicationFontFamily,
            montserratFontFamily,
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        button: {
            textTransform: "none",
        },
        fontSize: 16,
        fontWeightBold: 700,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        h1: {
            ...commonLargeHeadingStyles,
            fontSize: "2.5rem",
            lineHeight: "3.5rem",
            fontWeight: 700,
            letterSpacing: "0px",
            [breakpoints.down(mobileBreakpoint)]: {
                fontSize: "2.125rem",
                lineHeight: "3.125rem",
            },
        },
        h2: {
            ...commonLargeHeadingStyles,
            fontSize: "2rem",
            lineHeight: "3rem",
            fontWeight: 600,
            letterSpacing: "0.25px",
        },
        h3: {
            ...commonLargeHeadingStyles,
            fontSize: "1.5rem",
            lineHeight: "2.5rem",
            fontWeight: 600,
            letterSpacing: "0px",
        },
        h4: {
            ...commonSmallHeadingStyles,
            fontSize: "1.25rem",
            lineHeight: "2rem",
            fontWeight: 700,
        },
        h5: {
            ...commonSmallHeadingStyles,
            fontSize: "1.125rem",
            lineHeight: "2rem",
            fontWeight: 600,
        },
        h6: {
            ...commonSmallHeadingStyles,
            fontSize: "1rem",
            lineHeight: "2rem",
            fontWeight: 700,
        },
        caption: {
            ...captionStyles,
            color: TxtColor.Txt1,
        },
        subtitle1: {
            ...commonSubtitleStyles,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontWeight: 400,
            letterSpacing: "0.15px",
        },
        subtitle2: {
            ...commonSubtitleStyles,
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            fontWeight: 500,
            letterSpacing: "0.1px",
        },
        overline: {
            color: TxtColor.Txt1,
            fontFamily: primaryApplicationFontFamily,
            fontSize: "0.625rem",
            lineHeight: "1rem",
            fontWeight: 400,
            letterSpacing: "1.5px",
        },
        body1: { ...commonBodyStyles, fontSize: "1rem", lineHeight: "2rem", letterSpacing: "0.3px" },
        body2: { ...commonBodyStyles, fontSize: "0.875rem", lineHeight: "1.5rem", letterSpacing: "0.25px" },
        body3: {
            ...commonBodyStyles,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            letterSpacing: "0.3px",
            fontWeight: 500,
        },
        body4: {
            ...commonBodyStyles,
            fontSize: "1rem",
            lineHeight: "2rem",
            letterSpacing: "0.3px",
            fontWeight: 700,
        },
        body5: {
            ...commonBodyStyles,
            fontSize: "0.875rem",
            letterSpacing: "0.25px",
            fontWeight: 500,
        },
        button1: {
            ...commonBodyStyles,
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            letterSpacing: "1.25px",
            fontWeight: 500,
            textTransform: "uppercase",
        },
        button2: {
            ...commonBodyStyles,
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
            letterSpacing: "0.25px",
            fontWeight: 500,
        },
        list1: {
            ...commonBodyStyles,
            fontSize: "1rem",
            lineHeight: "2rem",
        },
    },
    props: {
        MuiUseMediaQuery: { noSsr: true },
    },
    overrides: {
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: "20px",
            },
        },
        MuiLink: {
            root: { fontFamily: primaryApplicationFontFamily, fontSize: "1rem", lineHeight: "2rem" },
        },
        MuiDivider: {
            root: {
                backgroundColor: applicationPalettes.default[50],
            },
        },
        MuiTypography: {
            root: {
                wordBreak: "break-word",
            },
            paragraph: {
                fontFamily: primaryApplicationFontFamily,
                fontSize: "1rem",
                lineHeight: "2rem",
                color: textSecondColor,
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: applicationPalettes.primary["900"],
                ...captionStyles,
                color: whiteColor,
            },
        },
        MuiCssBaseline: {
            "@global": {
                body: {
                    whiteSpace: "pre-line",
                    backgroundColor: whiteColor,
                },
            },
        },
        MuiPickersBasePicker: {
            container: {
                width: "328px",
            },
        },
        MuiPickersYearSelection: {
            container: {
                padding: "24px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                "@supports not selector(::-webkit-scrollbar)": {
                    scrollbarWidth: "thin",
                },
                "@supports (overflow-y: overlay)": {
                    "overflow-y": "overlay",
                },
                "&::-webkit-scrollbar": {
                    width: "12px",
                },
                "&::-webkit-scrollbar-thumb": {
                    border: "4px solid transparent",
                    backgroundClip: "padding-box",
                    borderRadius: "8px",
                    backgroundColor: TxtColor.Txt6,
                },
            },
        },
        MuiPickersYear: {
            root: {
                fontSize: `14px !important`,
                letterSpacing: `0.25px !important`,
                fontFamily: `${primaryApplicationFontFamily} !important`,
                margin: `10px 8px !important`,
                width: "72px",
                height: "36px",
                borderRadius: "30px",
            },
            yearSelected: {
                fontSize: `14px !important`,
                backgroundColor: applicationPalettes.primary["500"],
                color: `${TxtColor.Txt8} !important`,
                fontWeight: `400 !important`,
                margin: `10px 8px !important`,
            },
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: `${whiteColor} !important`,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                [breakpoints.down(tabletBreakpoint)]: {
                    margin: `0 2px !important`,
                },
            },
        },
        MuiTableRow: {
            root: {
                "&$hover:hover": {
                    backgroundColor: applicationPalettes.primary[1],
                    cursor: "default !important",
                },
            },
            hover: {},
        },
        MuiAlert: {
            standardError: {
                backgroundColor: applicationPalettes.red[50],
                "&$icon": {
                    color: applicationPalettes.red[500],
                },
                "& .MuiTypography-subtitle2": {
                    color: applicationPalettes.red[900],
                },
            },
            standardWarning: {
                backgroundColor: applicationPalettes.orange[50],
                "&$icon": {
                    color: applicationPalettes.orange[500],
                },
                "& .MuiTypography-subtitle2": {
                    color: applicationPalettes.orange[900],
                },
            },
            standardInfo: {
                backgroundColor: applicationPalettes.indigo[50],
                "&$icon": {
                    color: applicationPalettes.indigo[500],
                },
                "& .MuiTypography-subtitle2": {
                    color: applicationPalettes.indigo[900],
                },
            },
        },
    },
});

// Probably will be necessary when fixing PWA issues.
// theme = responsiveFontSizes(theme);

export default theme;
