import axios, { AxiosError, AxiosPromise } from "axios";

import { LocalizationId } from "shared/api/interceptors/localization";
import { setAuthHeader } from "shared/api/utils";
import apiConstants from "shared/constants/apiConstants";
import { HistoryLocation, HistoryReplace } from "shared/hooks/useHistory";
import { LocalizeFunction } from "shared/hooks/useLocalize";
import routeNames from "shared/routes/constants/routeNames";
import { getOwnProperty } from "shared/utils/types.utils";
import { showErrorToast } from "uiKit/container/toast/helpers";

import { RootStore } from "app/rootStore";

let refreshTokenPromise: Promise<void> | undefined;

const shouldRedirectAfterSignIn = (historyLocation: HistoryLocation) => {
    const shouldRedirect = getOwnProperty<boolean>(historyLocation.state, "shouldRedirectAfterSignIn") ?? false;
    const timestamp = getOwnProperty<number>(historyLocation.state, "expirationTimestamp") ?? 0;
    return shouldRedirect && timestamp < new Date().getTime();
};

export const handleErrorApiResponse = (
    error: AxiosError,
    historyReplace: HistoryReplace,
    historyLocation: HistoryLocation,
    localize: LocalizeFunction,
    rootStore: RootStore
): AxiosPromise => {
    if (!error.isAxiosError) {
        return Promise.reject(error);
    }

    if (!error.response) {
        if (!error.config.hideToastOnNoInternetConnection) {
            showErrorToast({
                title: localize(LocalizationId.InternalServerErrorToastTitle),
                message: localize(LocalizationId.InternalServerErrorToastBody),
            });
        }
        return Promise.reject(error);
    }

    if (error.response.status === 401 && !error.config.isRetry) {
        if (refreshTokenPromise == null) {
            refreshTokenPromise = new Promise((resolve, reject) => {
                setAuthHeader(undefined);

                rootStore.authStore
                    .refreshAccessToken()
                    .then(() => {
                        refreshTokenPromise = undefined;
                        resolve();
                    })
                    .catch(refreshTokenError => {
                        if (!rootStore.authStore.isAuthenticated) {
                            const queryParams = shouldRedirectAfterSignIn(historyLocation)
                                ? `?returnUrl=${historyLocation.pathname}`
                                : "";
                            historyReplace(routeNames.LOGIN.ROOT + queryParams);
                        }
                        refreshTokenPromise = undefined;
                        reject(refreshTokenError);
                    });
            });
        }

        return refreshTokenPromise.then(() => {
            if (rootStore.authStore.requiresMissingDetails) {
                return Promise.reject(error);
            }

            const originalRequest = error.config;
            originalRequest.isRetry = true;
            if (originalRequest.headers) {
                delete originalRequest.headers[apiConstants.HEADERS.AUTHORIZATION];
            }
            return axios(originalRequest).catch(requestError => {
                const requestConfig = requestError.config;
                requestConfig.isRetry = false;
                throw requestError;
            });
        });
    }

    if (error.response.status === apiConstants.STATUS_CODES.FORBIDDEN) {
        if (error.config.on403Error) {
            error.config.on403Error({ error, localize });
        } else {
            historyReplace(routeNames.ERRORS.FORBIDDEN, { ignoreLeaveConfirmationDialog: true });
        }
    }

    if (error.response.status === apiConstants.STATUS_CODES.NOT_FOUND) {
        if (error.config.on404Error) {
            error.config.on404Error({ error, localize });
        } else {
            historyReplace(routeNames.ERRORS.NOT_FOUND, { ignoreLeaveConfirmationDialog: true });
        }
    }

    if (
        error.response.status === apiConstants.STATUS_CODES.INTERNAL_ERROR ||
        error.response.status === apiConstants.STATUS_CODES.SERVICE_UNAVAILABLE
    ) {
        if (error.config.on500Error) {
            error.config.on500Error({ error, localize });
        } else {
            showErrorToast({
                title: localize(LocalizationId.InternalServerErrorToastTitle),
                message: localize(LocalizationId.InternalServerErrorToastBody),
            });
        }
    }

    return Promise.reject(error);
};
