import { Theme } from "@material-ui/core";

import { scrollStyles } from "shared/styles";
import {
    TxtColor,
    applicationPalettes,
    applicationShadowPalettes,
    mediumBreakpoint,
    mobileBreakpoint,
    tabletBreakpoint,
} from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const useMenuProfileButtonStyles = makeSharedStyles<Theme>(theme => ({
    roleOrAccountTypeTypography: {
        color: TxtColor.Txt2,
    },

    profileContainer: {
        width: "auto",
        [theme.breakpoints.down(mobileBreakpoint)]: {
            marginLeft: theme.spacing(2),
        },
    },

    subMenuIconContainer: {
        position: "relative",
    },

    toggleMobilePopperIcon: {
        marginLeft: theme.spacing(2),
        alignSelf: "flex-start",
        marginTop: theme.spacing(),
        [theme.breakpoints.up(mediumBreakpoint)]: {
            marginTop: 0,
            marginLeft: 0,
        },
    },

    subMenuIcon: {
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        "&:not(:hover)": {
            color: TxtColor.Txt4,
        },
        [theme.breakpoints.up(mediumBreakpoint)]: {
            marginLeft: 0,
        },
        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: 0,
            marginLeft: 0,
        },
    },
}));

interface Props {
    hasMultipleAccounts: boolean;
}

export const usePopupStyles = makeSharedStyles<Theme, Props>(theme => ({
    list: {
        zIndex: theme.zIndex.modal,

        border: `1px solid ${applicationPalettes.default[50]}`,
        borderRadius: theme.spacing(2),
        background: applicationPalettes.default[1],
        boxShadow: applicationShadowPalettes.default[16],
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        width: "308px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
        padding: theme.spacing(),
    },
    paper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: theme.spacing(1.5),
        background: TxtColor.Txt8,
        boxShadow: "none",
    },
    profileInfoSection: {
        borderRadius: ({ hasMultipleAccounts }) =>
            hasMultipleAccounts ? theme.spacing(1.5, 1.5, 0.25, 0.25) : theme.spacing(1.5),
        padding: theme.spacing(1, 1.5, 1.5),
    },
    accountsList: {
        maxHeight: "320px",
        marginTop: theme.spacing(0.25),
        padding: theme.spacing(1, 0),
        borderRadius: theme.spacing(0.25, 0.25, 1.5, 1.5),
    },
    myProfileButton: {
        width: "100% !important",
    },
    logoutButton: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "flex-start",
    },
    logoutContainer: {
        marginTop: theme.spacing(),
        padding: theme.spacing(1.5, 2),
    },
    listContainer: {
        ...scrollStyles,
        width: "100%",
        overflow: "hidden",
        "&.MuiList-padding": {
            padding: 0,
        },
    },
    note: { marginTop: theme.spacing(2) },
    noteText: {
        color: TxtColor.Txt3,
    },
}));
