import { makeStyles } from "@material-ui/core";
import { Theme as DefaultTheme } from "@material-ui/core/styles";
import { ClassNameMap, Styles } from "@material-ui/styles/withStyles";

// custom wrapper around makeStyles from material ui to add index: 1 to correct styles handling on webpack prod build
export function makeSharedStyles<
    Theme = DefaultTheme,
    // eslint-disable-next-line @typescript-eslint/ban-types
    Props extends object = {},
    ClassKey extends string = string,
>(
    styles: Styles<Theme, Props, ClassKey>
): keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
    : (props: Props) => ClassNameMap<ClassKey> {
    return makeStyles(styles, { index: 1 });
}
