/**
 * Summary: Get all offices count.
 *
 * Tags: CompanyOffices
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_COMPANY_OFFICES_COUNT_URL = "api/v1/company/offices/count";

export const apiGetCompanyOfficesCount = async (
    config?: AxiosRequestConfig
): Promise<ApiGetCompanyOfficesCountResponse> => {
    const result = await httpGet<ApiGetCompanyOfficesCountResponse>(
        window.APP_SETTINGS.spaceStoneApiBaseUri,
        API_GET_COMPANY_OFFICES_COUNT_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetCompanyOfficesCountResponse = number;
