import isEmpty from "lodash/isEmpty";
import { action, makeObservable, observable, runInAction } from "mobx";

import { LocalizedComponentName } from "localization/enums";
import { getLocalizationMessages, getLocalizationVersion } from "shared/api/staticCms/staticCmsApi";
import loadIntlPolyfill from "shared/helpers/loadIntlPolyfill";
import { LocalizationMessages } from "shared/models/staticCms/localizationMessages";
import { LocalizationVersion } from "shared/models/staticCms/localizationVersion";
import { retry } from "shared/utils/promise.utils";

import { CACHE_DURATION_MS, EN_LANG } from "./constants";
import {
    ComponentLocalization,
    LocalizationCache,
    getLocalizationCache,
    saveLocalizationCache,
} from "./localizationCache";

const LOAD_RETRY_DELAY = 1000;
const LOAD_RETRY_COUNT = 10;

export class LocalizationStore {
    @observable public locale = EN_LANG;
    @observable.shallow public messages: LocalizationMessages = {};
    @observable public version: LocalizationVersion = { version: "", updatedAt: null };
    private versionCheckerTimestamp?: number;
    private versionCheckerInterval?: NodeJS.Timeout;
    private localizationMessagesPromises: Map<LocalizedComponentName, Promise<void>> = new Map();

    constructor() {
        makeObservable(this);
        loadIntlPolyfill(this.locale);
    }

    @action.bound
    public async fetchLocalization(componentName: LocalizedComponentName): Promise<void> {
        if (!this.versionCheckerInterval) {
            this.versionCheckerInterval = setInterval(async () => {
                await this.fetchVersion();
            }, CACHE_DURATION_MS);
            await this.fetchVersion();
        }

        let cache = getLocalizationCache(this.locale);
        if (!cache.version || cache.version.version === undefined) {
            cache = new LocalizationCache({}, this.version);
            saveLocalizationCache(this.locale, cache);
        } else if (isEmpty(this.messages)) {
            this.addOrUpdateMessages(cache.getAllMessages());
        }

        const componentLocalization = cache.getComponentLocalization(componentName);
        const isNeedToLoad = !componentLocalization?.messages || isEmpty(componentLocalization.messages);
        if (isNeedToLoad) {
            await retry(() => this.loadComponentLocalization(componentName), LOAD_RETRY_DELAY, LOAD_RETRY_COUNT);
        } else {
            this.addOrUpdateMessages(cache.getAllMessages());
        }
    }

    @action.bound
    public localize(id: string): string {
        return this.messages[id] || "";
    }

    @action.bound
    private async fetchVersion(): Promise<void> {
        if (
            !this.version.version ||
            (this.versionCheckerTimestamp && this.versionCheckerTimestamp + CACHE_DURATION_MS > Date.now())
        ) {
            this.version = await getLocalizationVersion();

            runInAction(() => {
                this.versionCheckerTimestamp = Date.now();
                let cache = getLocalizationCache(this.locale);
                if (!cache.version?.version) {
                    cache = new LocalizationCache(cache.components, this.version);
                    saveLocalizationCache(this.locale, cache);
                } else if (this.version.version !== cache.version?.version) {
                    cache = new LocalizationCache({}, this.version);
                    saveLocalizationCache(this.locale, cache);
                }
            });
        }
    }

    @action.bound
    private loadComponentLocalization(componentName: LocalizedComponentName): Promise<void> {
        const localizationMessagesPromise = this.localizationMessagesPromises.get(componentName);
        if (localizationMessagesPromise) {
            return localizationMessagesPromise;
        }
        const newPromise = new Promise<void>((resolve, reject) => {
            getLocalizationMessages(componentName)
                .then(messages => {
                    try {
                        const updatedComponentLocalization = new ComponentLocalization(componentName, messages);
                        const cache = getLocalizationCache(this.locale);
                        cache.addOrUpdateComponentLocalization(componentName, updatedComponentLocalization);
                        saveLocalizationCache(this.locale, cache);
                        this.addOrUpdateMessages(cache.getAllMessages());
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                })
                .catch(reason => {
                    reject(reason);
                })
                .finally(() => {
                    runInAction(() => {
                        this.localizationMessagesPromises.delete(componentName);
                    });
                });
        });
        runInAction(() => {
            this.localizationMessagesPromises.set(componentName, newPromise);
        });
        return newPromise;
    }

    @action.bound
    private addOrUpdateMessages(messages: LocalizationMessages): void {
        Object.keys(messages).forEach(key => {
            this.messages[key] = messages[key];
        });
    }
}
