/**
 * Summary: Get tenancy property.
 *
 * Tags: Tenancies
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_TENANCY_PROPERTY_URL = (tenancyId: number): string => `api/v1/tenancies/${tenancyId}/property`;

export const apiGetTenancyProperty = async (
    tenancyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetTenancyPropertyResponse> => {
    const result = await httpGet<ApiGetTenancyPropertyResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_TENANCY_PROPERTY_URL(tenancyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetTenancyPropertyResponse = LookUpModel;

interface LookUpModel {
    id: number;
    text: string;
}
