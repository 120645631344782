import React from "react";

import { Typography } from "@material-ui/core";
import clsx from "clsx";

import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import { ButtonTextProps } from "uiKit/buttons/commonButtonsProps";

const useStyles = makeSharedStyles(theme => ({
    buttonTextType: {
        display: "flex",
        "&.body1": {
            ...theme.typography.body1,
            color: "unset",
        },
        "&.body2": {
            ...theme.typography.body2,
            color: "unset",
        },
        "&.body3": {
            ...theme.typography.body3,
            color: "unset",
        },
        "&.body4": {
            ...theme.typography.body4,
            color: "unset",
        },
        "&.body5": {
            ...theme.typography.body5,
            color: "unset",
        },
        "&.button1": {
            ...theme.typography.button1,
            color: "unset",
        },
        "&.button2": {
            ...theme.typography.button2,
            color: "unset",
        },
    },
}));

const ButtonText: React.FC<ButtonTextProps> = ({ label, textVariant = "button2", reference }: ButtonTextProps) => {
    const styles = useStyles();
    return (
        <Typography ref={reference} className={clsx(styles.buttonTextType, textVariant || "button1")}>
            {label}
        </Typography>
    );
};

export default ButtonText;
