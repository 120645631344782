import { action, computed, makeObservable, observable } from "mobx";

import { INTERCOM_SETTINGS } from "shared/constants/appConstants";

import { RootStore } from "app/rootStore";

export class IntercomStore {
    @observable isOpen = false;
    @observable isHidden = false;
    @observable isChatOnAir = false;
    private readonly rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @computed
    get isChatShouldBeBooted(): boolean {
        const {
            authStore: { isAuthenticated },
            isAdmin,
        } = this.rootStore;

        return (
            isAuthenticated &&
            INTERCOM_SETTINGS.isIntercomIdExist &&
            (!isAdmin || (isAdmin && INTERCOM_SETTINGS.isIntercomEnabledForInternalUser))
        );
    }

    @action.bound
    public setOpen(isOpen: boolean): void {
        this.isOpen = isOpen;
    }

    @action.bound
    public setHidden(isHidden: boolean): void {
        this.isHidden = isHidden;
    }

    @action.bound
    public setChatOnAir(onAir: boolean): void {
        this.isChatOnAir = onAir;
    }
}
