import React, { Dispatch, useState } from "react";

export enum PageNotificationTypes {
    Info = "info",
    Error = "error",
    Critical = "critical",
    Warning = "warning",
    Success = "success",
}

interface UsePageNotification {
    isNotificationShown: boolean;
    notificationType: PageNotificationTypes;
    notificationMessage: React.ReactNode;
    setIsNotificationShown: Dispatch<boolean>;
    setNotificationMessage: Dispatch<React.ReactNode>;
    setNotificationType: Dispatch<PageNotificationTypes>;
}

export function usePageNotification(): UsePageNotification {
    const [isNotificationShown, setIsNotificationShown] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<React.ReactNode>("");
    const [notificationType, setNotificationType] = useState<PageNotificationTypes>(PageNotificationTypes.Critical);

    return {
        isNotificationShown,
        notificationType,
        notificationMessage,
        setIsNotificationShown,
        setNotificationMessage,
        setNotificationType,
    };
}

export const PageNotificationContext = React.createContext<UsePageNotification>({
    isNotificationShown: false,
    notificationMessage: "",
    notificationType: PageNotificationTypes.Critical,
    setNotificationMessage: () => void 0,
    setNotificationType: () => void 0,
    setIsNotificationShown: () => void 0,
});

export const PageNotificationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const {
        isNotificationShown,
        notificationMessage,
        notificationType,
        setIsNotificationShown,
        setNotificationMessage,
        setNotificationType,
    } = usePageNotification();

    return (
        <PageNotificationContext.Provider
            value={{
                isNotificationShown,
                notificationMessage,
                notificationType,
                setIsNotificationShown,
                setNotificationMessage,
                setNotificationType,
            }}
        >
            {children}
        </PageNotificationContext.Provider>
    );
};

export const usePageNotificationContext = (): UsePageNotification => React.useContext(PageNotificationContext);
