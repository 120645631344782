import React, { useCallback } from "react";

import { observer } from "mobx-react-lite";
import { Redirect, Route, RouteProps } from "react-router";

import routeNames from "shared/routes/constants/routeNames";
import { findRouteByName } from "shared/routes/routes.utils";

export const PublicRoute = observer(function PublicRoute<T extends RouteProps = RouteProps>({ component, ...rest }: T) {
    const simpleRouteElement = useCallback(
        props => {
            const { location } = props;
            const route = findRouteByName(location.pathname);

            if (route === null) {
                return <Redirect to={{ pathname: routeNames.ERRORS.NOT_FOUND, state: { from: location.pathname } }} />;
            }

            return React.createElement(component!, props);
        },
        [component]
    );

    return <Route {...rest} render={simpleRouteElement} />;
});
