import { countBy, filter, orderBy } from "lodash";

import { formatProfileAccountType } from "components/userProfile/helpers";
import { ProfileShortAccountDetails } from "components/userProfile/interfaces";
import { AccountType } from "shared/constants/enums";
import { LocalizeFunction } from "shared/hooks/useLocalize";

import { MenuAccountModel } from "app/layout/menu/profile/interfaces";

export const sortProfileAccounts = (
    userAccountTypesIds: AccountType[],
    accounts: ProfileShortAccountDetails[],
    localize: LocalizeFunction
): MenuAccountModel[] => {
    const itemPositions = new Map(userAccountTypesIds.map((it, index) => [it, index]));
    const counts = countBy(accounts, it => it.accountType.id);
    const duplicatedAccountTypes = new Set<number>(
        filter(
            accounts.map(it => it.accountType.id),
            it => counts[it] > 1
        )
    );

    const sortedAccounts = orderBy(accounts, [
        account => itemPositions.get(account.accountType.id),
        account => account.accountIndex,
    ]);

    return sortedAccounts.map(it => ({
        accountLogo: it.accountLogo,
        accountName: it.accountName,
        accountId: it.accountId,
        accountTypeId: it.accountType.id,
        accountTypeText: formatProfileAccountType(it, duplicatedAccountTypes.has(it.accountType.id), localize),
    }));
};
