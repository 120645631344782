import { useCallback, useEffect, useState } from "react";

import { LeavePageHandler, useLeavePageInterceptorContext } from "shared/routes/LeavePageInterceptor";

export interface LeavePageProps {
    isDirty: boolean | (() => boolean);
}

interface UseLeavePageValue {
    modalVisible: boolean;
    closeModal: () => void;
    confirmNavigation: () => void;
}

const useLeavePage = ({ isDirty }: LeavePageProps): UseLeavePageValue => {
    const { addHandler, removeHandler } = useLeavePageInterceptorContext();
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const showModal = useCallback(() => setModalVisible(true), []);
    const closeModal = useCallback(() => setModalVisible(false), []);
    const confirmNavigation = useCallback(() => setConfirmedNavigation(true), []);

    useEffect(() => {
        const leavePageHandler: LeavePageHandler = {
            isDirty: typeof isDirty === "function" ? isDirty : () => !!isDirty,
            showModal,
            confirmedNavigation,
        };

        addHandler(leavePageHandler);
        return () => {
            removeHandler(leavePageHandler);
        };
    }, [addHandler, confirmedNavigation, isDirty, removeHandler, showModal]);

    return {
        modalVisible,
        closeModal,
        confirmNavigation,
    };
};

export default useLeavePage;
