import { getMainScrollbarStyles } from "shared/styles";
import {
    Gradients,
    extraLargeBreakpoint,
    mediumBreakpoint,
    mobileBreakpoint,
    tabletBreakpoint,
    whiteColor,
} from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";

export const MOBILE_TOGGLED_STYLE = "mobileToggled";

export const useProtectedLayoutStyles = makeSharedStyles(theme => ({
    root: {
        position: "absolute",
        display: "flex",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        [theme.breakpoints.down(tabletBreakpoint)]: {
            flexDirection: "column",
        },
    },
    navigation: {
        flex: "0 0 284px",
        overflowY: "visible",
        backgroundColor: whiteColor,
        width: "284px",
        [theme.breakpoints.down(tabletBreakpoint)]: {
            width: "100%",
            position: "absolute",
            zIndex: theme.zIndex.modal,
            display: "none",
            [`&.${MOBILE_TOGGLED_STYLE}`]: {
                display: "block",
                top: "80px",
                height: "calc(100% - 80px)",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
            },
        },
    },
    mobileNavHeader: {
        display: "flex",
        backgroundColor: whiteColor,
        borderBottomRightRadius: "20px",
        borderBottomLeftRadius: "20px",
        position: "relative",
        zIndex: theme.zIndex.appBar,
        boxShadow: theme.shadows[16],
        flex: "0 0 auto",
        [`&.${MOBILE_TOGGLED_STYLE}`]: {
            borderRadius: 0,
            zIndex: theme.zIndex.modal,
            boxShadow: "none",
        },
        [theme.breakpoints.up(mediumBreakpoint)]: {
            display: "none",
        },
    },
    contentWrapper: {
        display: "flex",
        flex: "1 1 auto",
        position: "relative",
        flexDirection: "column",
        overflow: "hidden",
        [theme.breakpoints.up(mediumBreakpoint)]: {
            flexDirection: "row",
        },
    },
    contentInnerWrapper: {
        display: "flex",
        flex: "1 1 auto",
        position: "relative",
        background: Gradients.Gr1,
        padding: theme.spacing(4, 4, 0, 4),
        ...getMainScrollbarStyles(theme),

        [theme.breakpoints.down(tabletBreakpoint)]: {
            padding: theme.spacing(3, 3, 0, 3),
        },

        [theme.breakpoints.down(mobileBreakpoint)]: {
            padding: theme.spacing(3, 2, 0, 2),
            background: whiteColor,
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    container: {
        padding: 0,
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up(extraLargeBreakpoint)]: {
            maxWidth: 1572, // Figma styles
        },
    },
}));

export const usePageNotificationContainerStyles = makeSharedStyles(theme => ({
    root: {
        [theme.breakpoints.up(mediumBreakpoint)]: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: theme.zIndex.snackbar,
            height: 40,
        },
    },
}));
