import React, { FC } from "react";

import { LocalizationId } from "components/footer/localization";
import { LocalizedComponentName } from "localization/enums";
import { withLocalization } from "localization/withLocalization";
import { useLocalize } from "shared/hooks/useLocalize";
import { TxtColor, whiteColor } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import Link from "uiKit/Link";

const useStyles = makeSharedStyles(() => ({
    navigation: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "1.25rem 0",
        backgroundColor: whiteColor,
        "& a": {
            margin: "0.625rem",
            color: TxtColor.Txt3,
        },
    },
}));

const Footer: FC = () => {
    const styles = useStyles();
    const localize = useLocalize();

    return (
        <footer>
            <nav className={styles.navigation}>
                <Link
                    isOpenAtNewPage
                    link={window.SYSTEM_SETTINGS.footerLinks.privacy}
                    typographyVariant="caption"
                    label={localize(LocalizationId.PrivacyPolicyLabel)}
                />
                <Link
                    isOpenAtNewPage
                    link={window.SYSTEM_SETTINGS.footerLinks.terms}
                    typographyVariant="caption"
                    label={localize(LocalizationId.TermsAndConditionsLabel)}
                />
            </nav>
        </footer>
    );
};

export default withLocalization(Footer, LocalizedComponentName.FooterLabelsLinks, true);
