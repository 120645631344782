import { EMPTY_VALUE, NO_VALUE_PLACEHOLDER } from "shared/constants/appConstants";
import { LocalizeFunction } from "shared/hooks/useLocalize";

export const NoNameLabel = "shared-messages.label.no_name";

export const formatUserNameOptional = (
    firstName: string | null = null,
    lastName: string | null = null,
    email?: string
): string | undefined => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }

    return firstName || lastName || email;
};

export const formatUserName = (
    firstName: string | null = null,
    lastName: string | null = null,
    email?: string
): string => {
    return formatUserNameOptional(firstName, lastName, email) || NO_VALUE_PLACEHOLDER;
};

export const formatUserNameWithoutDash = (
    localize: LocalizeFunction,
    firstName: string | null = null,
    lastName: string | null = null
): string => {
    return formatUserNameOptional(firstName, lastName) || localize(NoNameLabel);
};

export const formatUserNameForAvatar = (firstName: string | null = null, lastName: string | null = null): string => {
    if (firstName === EMPTY_VALUE && lastName === EMPTY_VALUE) {
        return EMPTY_VALUE;
    }

    let abbreviation = "";
    if (firstName) {
        abbreviation = `${firstName[0]}`;
    }

    if (lastName) {
        abbreviation = `${abbreviation}${lastName[0]}`;
    }

    return abbreviation.toUpperCase();
};

export const formatTenantName = (name: string | undefined, email: string | undefined): string =>
    name && name !== email ? name : NO_VALUE_PLACEHOLDER;
