import { RolePermission } from "shared/interfaces/rolePermission";

const hasPermissions = (
    requiredPermissions: RolePermission,
    userPermissions: Set<RolePermission>,
    tolerant = false
): boolean => {
    if (Array.isArray(requiredPermissions)) {
        if (requiredPermissions.length < 1) {
            return true;
        }

        if (tolerant) {
            return requiredPermissions.some(permission => userPermissions.has(permission));
        }

        if (!requiredPermissions.every(permission => userPermissions.has(permission))) {
            return false;
        }
    } else if (!userPermissions.has(requiredPermissions)) {
        return false;
    }

    return true;
};

export default hasPermissions;
