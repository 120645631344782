/**
 * Summary: Get property summary.
 *
 * Tags: Properties
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_PROPERTY_SUMMARY_URL = (propertyId: number): string => `api/v1/properties/${propertyId}/summary`;

export const apiGetPropertySummary = async (
    propertyId: number,
    config?: AxiosRequestConfig
): Promise<ApiGetPropertySummaryResponse> => {
    const result = await httpGet<ApiGetPropertySummaryResponse>(
        window.APP_SETTINGS.realityStoneApiBaseUri,
        API_GET_PROPERTY_SUMMARY_URL(propertyId),
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetPropertySummaryResponse = PropertySummaryModel;

interface LookUpModel {
    id: number;
    text: string;
}

interface PropertySummaryModel {
    propertyId: number; // Property Id.
    propertyName?: string; // Property Name. Example: Premiere House
    propertyStatus?: LookUpModel; // Property Status.
    tenancyId?: number; // Tenancy Id. Example: 42
    canManageProperty: boolean; // Can current user manage property. Example: true
    canMoveProperty: boolean; // Can current user move property  to another agency or back to LL. Example: true
}
