import { type HubConnectionState } from "@microsoft/signalr";

import { type ApiGetReleaseRequestChatMessagesResponse } from "shared/api/swagger/timeStone/chat/apiGetReleaseRequestChatMessages";
import { UserNotification } from "shared/interfaces/notifications";

export enum RealTimeHubMessageType {
    // FE
    Disconnected = -4,
    Connected = -3,
    Disconnect = -2,
    Connect = -1,
    // BE
    NotificationCountChanged = 1,
    BasketItemCountChanged = 2,
    ChatMessageAdded = 3,
    ChatMessagesMarkedAsRead = 4,
    NewNotificationAdded = 5,
}

type ChatMessage = Unpacked<ApiGetReleaseRequestChatMessagesResponse["items"]>;

export type RealTimeHubMessage =
    | {
          realTimeMessageType: RealTimeHubMessageType.Disconnect;
      }
    | {
          realTimeMessageType: RealTimeHubMessageType.Connect;
          payload: string;
      }
    | {
          realTimeMessageType: RealTimeHubMessageType.Connected | RealTimeHubMessageType.Disconnected;
          payload: { state: HubConnectionState; connectionId?: string };
      }
    | {
          realTimeMessageType:
              | RealTimeHubMessageType.BasketItemCountChanged
              | RealTimeHubMessageType.NotificationCountChanged;
          payload: { countToChange: number };
      }
    | {
          realTimeMessageType: RealTimeHubMessageType.ChatMessageAdded;
          payload: ChatMessage;
      }
    | {
          realTimeMessageType: RealTimeHubMessageType.ChatMessagesMarkedAsRead;
          payload: { releaseRequestId: number; readAt: Date };
      }
    | {
          realTimeMessageType: RealTimeHubMessageType.NewNotificationAdded;
          payload: UserNotification;
      };
