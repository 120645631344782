import React from "react";

import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

import { getButtonSpinnerDimensionsBySize } from "shared/helpers";
import theme, { ApplicationColorPalette, TxtColor, applicationPalettes } from "shared/theme/defaultTheme";
import { makeSharedStyles } from "shared/theme/makeSharedStyles";
import ButtonText from "uiKit/buttons/ButtonText";
import { useButtonProgressStyles } from "uiKit/buttons/buttonsStyles";
import { ButtonTextVariant } from "uiKit/buttons/commonButtonsProps";
import { Subset } from "uiKit/types";

type TextButtonVariant = Subset<ApplicationColorPalette, "primary" | "default">;

export interface TextButtonProps {
    id?: string;
    disabled?: boolean;
    variant?: TextButtonVariant;
    isLoading?: boolean;
    label?: React.ReactNode;
    helperText?: React.ReactText;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    className?: string;
    helperClassName?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    textReference?: React.Ref<HTMLElement>;
    textVariant?: ButtonTextVariant;
}

const useButtonStyles = makeSharedStyles<Theme, { variant: TextButtonVariant }>({
    root: {
        padding: 0,
        borderRadius: "0.375rem",
        minWidth: "auto",
        alignItems: "flex-start",
    },
    text: {
        color: ({ variant }) => (variant === "primary" ? applicationPalettes[variant]["500"] : TxtColor.Txt3),
        "@media (hover: hover)": {
            "&:hover": {
                backgroundColor: "transparent",
                color: ({ variant }) =>
                    variant === "primary" ? applicationPalettes.primary["700"] : applicationPalettes.primary["500"],
            },
        },
        "&:active": {
            color: ({ variant }) =>
                variant === "primary" ? applicationPalettes.primary["900"] : applicationPalettes.primary["700"],
        },
        "&.Mui-disabled": {
            color: ({ variant }) => (variant === "primary" ? applicationPalettes.primary["200"] : TxtColor.Txt5),
        },
    },
    focusVisible: {
        border: `2px solid ${applicationPalettes.primary["100"]}`,
    },
    startIcon: {
        marginLeft: 0,
    },
    endIcon: {
        marginRight: 0,
    },
    disabled: {},
});

const useHelperTextStyles = makeSharedStyles({
    root: {
        paddingTop: theme.spacing(1),
        color: `${TxtColor.Txt3} !important`,
    },
});

export default function TextButton({
    id,
    label,
    variant,
    helperText,
    className,
    textReference,
    helperClassName,
    isLoading,
    textVariant = "button2",
    ...rest
}: TextButtonProps): JSX.Element {
    const buttonClasses = useButtonStyles({ variant: variant || "default" });
    const helperTextClasses = useHelperTextStyles();
    const progressStyles = useButtonProgressStyles(getButtonSpinnerDimensionsBySize("m"));

    return (
        <>
            <Button id={id} {...rest} variant="text" disableRipple classes={{ ...buttonClasses }} className={className}>
                <ButtonText label={label} textVariant={textVariant} reference={textReference} />
                {isLoading && <CircularProgress size="" className={progressStyles.root} />}
            </Button>
            {helperText && (
                <Typography display="block" variant="caption" classes={helperTextClasses} className={helperClassName}>
                    {helperText}
                </Typography>
            )}
        </>
    );
}
