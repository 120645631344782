import { ApiGetLookupsCountriesResponse } from "shared/api/swagger/paymentService/lookups/apiGetLookupsCountries";

export enum BankAccountType {
    UK = 1,
    Eurozone = 2,
    USA = 3,
    Canada = 4,
    UnitedArabEmirates = 5,
    RestOfTheWorld = 6,
}

export type BankDetails = {
    accountName: string;
    accountNumber: string;
    bankIdentifierCode: string;
    routingNumber?: string;
    countryBankAccountTypeId: number;
    bankAccountTypeId?: number;
    isDefault: boolean;
};

export interface BankDetailsEntity extends BankDetails {
    bankAccountId: number;
}

export type PaymentTypeLookup = Unpacked<ApiGetLookupsCountriesResponse> & {
    bankAccountTypeId: BankAccountType;
};

export type BankDetailsPaymentTypeForm = {
    paymentType?: PaymentTypeLookup;
};

export interface BankDetailsFormValues extends BankDetailsPaymentTypeForm {
    accountName: string;
    accountNumber: string;
    bankIdentifierCode: string;
    routingNumber?: string;
}
