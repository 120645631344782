import React, { useCallback, useMemo, useState } from "react";

import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Dialog as MaterialDialog,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { FieldValues } from "react-hook-form";

import { useRequestContext } from "shared/api/RequestProvider";
import { isAdditionalButtonAction, isPrimaryButtonAction } from "shared/constants/requestActionConstants";
import { getDialogWidthBySize } from "shared/helpers";
import { DialogProps } from "shared/interfaces/container/dialog/dialog";
import { SubmitHandler } from "shared/interfaces/container/form/form";
import IconButton from "uiKit/buttons/IconButton";
import SolidButton from "uiKit/buttons/SolidButton";
import TextButton from "uiKit/buttons/TextButton";
import TranslucentButton from "uiKit/buttons/TranslucentButton";
import {
    useBackdropStyles,
    useDialogActionsStyles,
    useDialogButtonsStyles,
    useDialogContentStyles,
    useDialogStyles,
    useDialogTitleStyles,
    useDialogWideStyles,
    useDividerStyles,
    useTitleStyles,
} from "uiKit/container/dialog/dialog.styles";
import Form from "uiKit/container/form/Form";

export default function DialogComponent<TFormValue extends FieldValues = FieldValues>({
    children,
    dialogTitle,
    isOpened = false,
    showCloseIcon = true,
    variant = "default",
    optionalButtonText,
    optionalButtonIcon,
    primaryButtonText,
    additionalButtonText,
    buttonPosition,
    handleClose,
    handleAdditionalButton,
    handlePrimaryButton,
    handleOptionalButton,
    formOptions,
    size = "default",
    doesAdditionalButtonShouldSubmit = false,
    ignoreLoading = false,
    additionalButtonAsSolid = false,
    isPrimaryButtonDisabled = false,
    isAdditionalButtonDisabled = false,
    thin = false,
    hideBackdrop = false,
}: DialogProps<TFormValue>): JSX.Element {
    const backdropStyles = useBackdropStyles();
    const dialogStyles = useDialogStyles(getDialogWidthBySize(size));
    const dialogWideStyles = useDialogWideStyles({ thin });
    const dividerStyles = useDividerStyles();
    const dialogTitleStyles = useDialogTitleStyles();
    const dialogContentStyles = useDialogContentStyles({ thin });
    const dialogActionsStyles = useDialogActionsStyles();
    const dialogButtonsStyles = useDialogButtonsStyles();
    const titleStyles = useTitleStyles();
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const isXSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const isWideView = size === "wide";
    const isWideXSmallScreen = isWideView && isXSmallScreen;

    const { actionName, isLoading } = useRequestContext();
    const [isExecutingPrimaryAction, setExecutingPrimaryAction] = useState<boolean>(false);
    const [isExecutingAdditionalAction, setExecutingAdditionalAction] = useState<boolean>(false);
    const [isExecutingOptionalAction, setExecutingOptionalAction] = useState<boolean>(false);

    const onPrimaryButtonClickHandler = useCallback(async () => {
        if (isOpened && handlePrimaryButton) {
            try {
                setExecutingPrimaryAction(true);
                await handlePrimaryButton();
            } finally {
                setExecutingPrimaryAction(false);
            }
        }
    }, [handlePrimaryButton, isOpened]);

    const onAdditionalButtonClickHandler = useCallback(async () => {
        if (isOpened && handleAdditionalButton) {
            try {
                setExecutingAdditionalAction(true);
                await handleAdditionalButton();
            } finally {
                setExecutingAdditionalAction(false);
            }
        }
    }, [handleAdditionalButton, isOpened]);

    const onOptionalButtonClickHandler = useCallback(async () => {
        if (isOpened && handleOptionalButton) {
            try {
                setExecutingOptionalAction(true);
                await handleOptionalButton();
            } finally {
                setExecutingOptionalAction(false);
            }
        }
    }, [handleOptionalButton, isOpened]);

    const primaryButton = useMemo(() => {
        const palette = variant === "default" ? "primary" : "red";
        const isButtonLoading = isLoading && isPrimaryButtonAction(actionName) && !ignoreLoading;
        const isButtonDisabled = (isLoading && !ignoreLoading) || isPrimaryButtonDisabled || isExecutingPrimaryAction;
        const type = formOptions ? "submit" : "button";
        return primaryButtonText ? (
            <SolidButton
                data-testid="popup-primary-button"
                palette={palette}
                label={primaryButtonText}
                onClick={onPrimaryButtonClickHandler}
                isLoading={isButtonLoading}
                disabled={isButtonDisabled}
                type={type}
                className={dialogButtonsStyles.primary}
            />
        ) : undefined;
    }, [
        actionName,
        dialogButtonsStyles.primary,
        formOptions,
        ignoreLoading,
        isExecutingPrimaryAction,
        isLoading,
        isPrimaryButtonDisabled,
        onPrimaryButtonClickHandler,
        primaryButtonText,
        variant,
    ]);

    const additionalButton = useMemo(() => {
        const AdditionalButtonComponent = additionalButtonAsSolid ? SolidButton : TranslucentButton;
        const buttonType = doesAdditionalButtonShouldSubmit ? "submit" : "button";
        const isButtonLoading = isLoading && isAdditionalButtonAction(actionName) && !ignoreLoading;
        const isButtonDisabled =
            (isLoading && !ignoreLoading) || isAdditionalButtonDisabled || isExecutingAdditionalAction;
        return additionalButtonText ? (
            <AdditionalButtonComponent
                data-testid="popup-secondary-button"
                palette="primary"
                label={additionalButtonText}
                onClick={onAdditionalButtonClickHandler}
                isLoading={isButtonLoading}
                disabled={isButtonDisabled}
                className={dialogButtonsStyles.additional}
                type={buttonType}
            />
        ) : undefined;
    }, [
        actionName,
        additionalButtonAsSolid,
        additionalButtonText,
        dialogButtonsStyles.additional,
        doesAdditionalButtonShouldSubmit,
        ignoreLoading,
        isAdditionalButtonDisabled,
        isExecutingAdditionalAction,
        isLoading,
        onAdditionalButtonClickHandler,
    ]);

    const optionalButton = useMemo(() => {
        const isButtonDisabled = (isLoading && !ignoreLoading) || isExecutingOptionalAction;

        return optionalButtonText && buttonPosition !== "start" ? (
            <div className={dialogStyles.optionalButtonBox}>
                <TextButton
                    data-testid="popup-optional-button"
                    variant="primary"
                    onClick={onOptionalButtonClickHandler}
                    label={optionalButtonText}
                    startIcon={optionalButtonIcon}
                    disabled={isButtonDisabled}
                    className={dialogButtonsStyles.optional}
                />
            </div>
        ) : undefined;
    }, [
        buttonPosition,
        dialogButtonsStyles.optional,
        dialogStyles.optionalButtonBox,
        ignoreLoading,
        isExecutingOptionalAction,
        isLoading,
        onOptionalButtonClickHandler,
        optionalButtonIcon,
        optionalButtonText,
    ]);

    const groupButtons = useMemo(() => {
        if (isWideXSmallScreen) {
            return null;
        }

        return variant === "default" || isSmallScreen ? (
            <>
                {additionalButton}
                {primaryButton}
            </>
        ) : (
            <>
                {primaryButton}
                {additionalButton}
            </>
        );
    }, [isWideXSmallScreen, variant, isSmallScreen, additionalButton, primaryButton]);

    const onFormSubmitHandler: SubmitHandler<TFormValue> = useCallback(
        (data, e, methods) => {
            if (formOptions && isOpened) {
                formOptions.onHandleSubmit(data, e, methods);
            }
        },
        [formOptions, isOpened]
    );

    const content = useMemo(() => {
        if (!isOpened) {
            return null;
        }
        const dialogContentClasses = isWideView ? { root: dialogWideStyles.dialogContent } : dialogContentStyles;
        const dialogContent = <DialogContent classes={{ ...dialogContentClasses }}>{children}</DialogContent>;

        const dialogActions = !groupButtons ? undefined : (
            <>
                <Divider classes={{ ...dividerStyles }} />
                <DialogActions
                    classes={{ ...dialogActionsStyles }}
                    className={buttonPosition === "start" ? dialogStyles.startButtons : ""}
                >
                    {optionalButton} {groupButtons}
                </DialogActions>
            </>
        );

        if (formOptions) {
            return (
                <Form {...formOptions} mode="onSubmit" onHandleSubmit={onFormSubmitHandler}>
                    {dialogContent}
                    {dialogActions}
                </Form>
            );
        }
        return (
            <>
                {dialogContent}
                {dialogActions}
            </>
        );
    }, [
        buttonPosition,
        children,
        dialogActionsStyles,
        dialogContentStyles,
        dialogStyles.startButtons,
        dialogWideStyles.dialogContent,
        dividerStyles,
        formOptions,
        groupButtons,
        isOpened,
        isWideView,
        onFormSubmitHandler,
        optionalButton,
    ]);

    const onClose = useCallback(
        (_event: object, reason: "backdropClick" | "escapeKeyDown") => {
            if (reason !== "backdropClick" && handleClose) {
                handleClose();
            }
        },
        [handleClose]
    );

    return (
        <MaterialDialog
            onClose={onClose}
            open={isOpened}
            classes={{
                root: isWideView ? dialogWideStyles.root : undefined,
                paper: dialogStyles.paper,
                scrollPaper: dialogStyles.scrollPaper,
                paperScrollPaper: isWideView ? dialogWideStyles.paperScrollPaper : dialogStyles.paperScrollPaper,
            }}
            BackdropProps={{ className: backdropStyles.backdrop }}
            hideBackdrop={hideBackdrop}
        >
            <DialogTitle classes={{ ...dialogTitleStyles }} disableTypography>
                <Typography variant="h4" className={titleStyles.text}>
                    {dialogTitle}
                </Typography>
                {showCloseIcon && (
                    <IconButton
                        data-testid="popup-close-button"
                        className={titleStyles.closeButton}
                        onClick={handleClose}
                        disabled={isLoading && !ignoreLoading}
                        icon={Close}
                        noShadow
                    />
                )}
            </DialogTitle>
            <div className={isWideView ? dialogWideStyles.content : dialogStyles.content}>{content}</div>
        </MaterialDialog>
    );
}
