import { action, computed, makeObservable, observable } from "mobx";

import { RootStore } from "app/rootStore";

export enum LayoutType {
    BasicLayout,
    FullWidthLayout,
}

export class LayoutStore {
    @observable public isMenuToggled = false;

    @observable private layoutType: LayoutType = LayoutType.BasicLayout;

    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @computed
    get currentLayout(): LayoutType {
        return this.layoutType;
    }

    @action.bound
    public changeLayoutType(type: LayoutType): void {
        if (this.layoutType !== type) {
            this.layoutType = type;
        }
    }

    @action.bound
    public openMenu(): void {
        this.isMenuToggled = true;
    }

    @action.bound
    public closeMenu(): void {
        this.isMenuToggled = false;
    }
}
