/**
 * Summary: Get count of unread notifications for the authorized user.
 *
 * Tags: Notifications
 */
import { AxiosRequestConfig } from "axios";

import { httpGet } from "shared/api/apiHandler";

export const API_GET_NOTIFICATIONS_NOT_READ_COUNT_URL = "api/v1/notifications/not-read-count";

export const apiGetNotificationsNotReadCount = async (
    config?: AxiosRequestConfig
): Promise<ApiGetNotificationsNotReadCountResponse> => {
    const result = await httpGet<ApiGetNotificationsNotReadCountResponse>(
        window.APP_SETTINGS.notificationServiceApiBaseUri,
        API_GET_NOTIFICATIONS_NOT_READ_COUNT_URL,
        undefined,
        config
    );
    return result.data;
};

/**
 * Description: Success
 */
export type ApiGetNotificationsNotReadCountResponse = number;
